import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../data/network';

export const QUERY_KEY = 'profile.users';

const usePartnerUsers = () => {
  const req = useQuery([QUERY_KEY], () => api.users.search());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    users: req.data?.results || [],
    ...req,
  };
};

export default usePartnerUsers;
