import React from 'react';

import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialCheckbox from '@material-ui/core/Checkbox';

const Checkbox = ({ error, value, label, mb = 2, ...props }) => {
  return (
    <Box mb={mb}>
      <FormControlLabel
        control={<MaterialCheckbox checked={value} color="primary" {...props} />}
        label={label}
        labelPlacement="end"
      />
    </Box>
  );
};

export default React.memo(Checkbox);
