import { useMutation, useQueryClient } from 'react-query';
import api from '../../data/network';

import { QUERY_KEY } from './useLocations';

const useLocationMutations = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  const createLocation = useMutation(
    async payload => {
      const data = await api.locations.create(payload);

      if (payload.attachments) {
        const files = Array.from(payload.attachments);
        await Promise.all(
          files.map(file => {
            let formData = new FormData();
            formData.append('model', 'lpi.partner.location');
            formData.append('res_id', data.id);
            formData.append('ufile', file);

            api.attachments.create(formData);
          }),
        );
      }
    },
    {
      onSuccess: () => {
        invalidateQuery();
      },
    },
  );

  const updateLocation = useMutation(
    async payload => {
      const data = await api.locations.update(payload.id, payload);
      console.log('data', payload.attachments);
      if (payload.attachments) {
        const files = Array.from(payload.attachments);
        await Promise.all(
          files.map(file => {
            let formData = new FormData();
            formData.append('model', 'lpi.partner.location');
            formData.append('res_id', data.id);
            formData.append('ufile', file);

            api.attachments.create(formData);
          }),
        );
      }
    },
    {
      onSuccess: data => {
        invalidateQuery();
        queryClient.invalidateQueries([QUERY_KEY /* , data.id */]);
      },
    },
  );

  const deleteLocation = useMutation(id => api.locations.remove(id), {
    onSuccess: () => {
      invalidateQuery();
    },
  });

  const publishLocation = useMutation(id => api.locations.publish(id), {
    onSuccess: () => {
      invalidateQuery();
    },
  });

  const draftLocation = useMutation(id => api.locations.draft(id), {
    onSuccess: () => {
      invalidateQuery();
    },
  });

  return {
    createLocation,
    updateLocation,
    deleteLocation,
    publishLocation,
    draftLocation,
  };
};

export default useLocationMutations;
