import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import AssignCouponModal from './AssignCouponModal';

const CouponsActions = ({ item }) => {
  const { t } = useTranslation();
  const [openAssignModal, setOpenAssignModal] = React.useState(false);

  return (
    <React.Fragment>
      {item.partner_id === null && (
        <Button onClick={() => setOpenAssignModal(true)} color="secondary">
          {t('Assign')}
        </Button>
      )}
      <AssignCouponModal item={item} open={openAssignModal} onClose={() => setOpenAssignModal(false)} />
    </React.Fragment>
  );
};

export default React.memo(CouponsActions);
