import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import AuthReducer, { logout } from './reducers/AuthReducer';
import AgreementsReducer from './reducers/AgreementsReducer';
import ContactsReducer from './reducers/ContactsReducer';
import GenericReducer from './reducers/GenericReducer';
import LeadsReducer from './reducers/LeadsReducer';
import ProctorsReducer from './reducers/ProctorsReducer';
import SkillsReducer from './reducers/SkillsReducer';
import SnackReducer from './reducers/SnackReducer';
import StagesReducer from './reducers/StagesReducer';
import StudentsReducer from './reducers/StudentsReducer';
import TeachersReducer from './reducers/TeachersReducer';
import UsersReducer from './reducers/UsersReducer';
import PartnersReducer from './reducers/PartnersReducer';
import JobsReducer from './reducers/JobsReducer';
import TagsReducer from './reducers/TagsReducer';
import VouchersReducer from './reducers/VouchersReducer';
import CouponsReducer from './reducers/CouponsReducer';
import ShopReducer from './reducers/ShopReducer';
import TrainingCentersReducer from './reducers/TrainingCentersReducer';
import PasswordReducer from './reducers/PasswordReducer';
import MaterialReducer from './reducers/MaterialsReducer';

export default history => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    agreements: AgreementsReducer,
    contacts: ContactsReducer,
    generic: GenericReducer,
    leads: LeadsReducer,
    proctors: ProctorsReducer,
    skills: SkillsReducer,
    snack: SnackReducer,
    stages: StagesReducer,
    students: StudentsReducer,
    teachers: TeachersReducer,
    partner: PartnersReducer,
    jobs: JobsReducer,
    users: UsersReducer,
    tags: TagsReducer,
    vouchers: VouchersReducer,
    coupons: CouponsReducer,
    shop: ShopReducer,
    trainingCenters: TrainingCentersReducer,
    password: PasswordReducer,
    materials: MaterialReducer,
  });

  const rootReducer = (state, action) => {
    if (action.type === logout.TRIGGER) {
      state = undefined;
    }

    return appReducer(state, action);
  };

  return rootReducer;
};
