import { useMutation, useQueryClient } from 'react-query';
import api from '../../data/network';

import { QUERY_KEY } from './useTeachers';

const useTeacherMutations = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  const createTeacher = useMutation(payload => api.teachers.create(payload), {
    onSuccess: () => {
      invalidateQuery();
    },
  });

  const updateTeacher = useMutation(payload => api.teachers.update(payload.id, payload), {
    onSuccess: data => {
      invalidateQuery();
      queryClient.invalidateQueries([QUERY_KEY, data.id]);
    },
  });

  const deleteTeacher = useMutation(id => api.teachers.remove(id), {
    onSuccess: () => {
      invalidateQuery();
    },
  });

  return {
    createTeacher,
    updateTeacher,
    deleteTeacher,
  };
};

export default useTeacherMutations;
