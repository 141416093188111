import React from 'react';
import i18n from 'i18next';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isCouponCreationLoading } from '../../store/selectors/coupons';

import Form from '../Generic/Form';
import CouponProgramsInput from './CouponProgramsInput';
import TextInput from '../Form/TextInput';

const validate = values => {
  const errors = {};
  if (!values.program) {
    errors['program'] = i18n.t('Program is required');
  }
  if (
    (values.program.coupon_generation_limit > 0 && values.count > values.program.coupon_generation_limit) ||
    values.count < 1
  ) {
    errors['count'] = 'Number of coupons must be between 1 and ' + values.program.coupon_generation_limit;
  }
  return errors;
};

const CouponsForm = ({ ...props }) => {
  const { t } = useTranslation();
  const isLoading = useSelector(isCouponCreationLoading);

  return (
    <Form
      init={() => ({
        program: '',
        count: 1,
      })}
      validate={validate}
      {...props}
      isLoading={isLoading}
    >
      <CouponProgramsInput label={t('Program')} field="program" xs={12} sm={12} />
      <TextInput
        label={t('Number of coupons')}
        field="count"
        xs={12}
        sm={12}
        type="number"
        InputProps={{
          min: 1,
          max: 100,
        }}
      />
    </Form>
  );
};

export default React.memo(CouponsForm);
