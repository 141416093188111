import _keyBy from 'lodash/keyBy';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

// Action Creators
export const FETCH_PARTNER = 'Partner.Fetch';
export const FETCH_PARTNERS = 'Partners.Fetch';
export const fetchPartner = createRoutine(FETCH_PARTNER);
export const fetchPartners = createRoutine(FETCH_PARTNERS);

// Initial State
const INITIAL_REQUEST_STATE = {
  fetch: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: null,
  partner: null,
  requests: INITIAL_REQUEST_STATE,
};

// Reducer
const partnersReducer = handleActions(
  {
    [fetchPartners.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetch.loading = true;
        draft.requests.fetch.error = null;
      }),

    [fetchPartners.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.list = _keyBy(payload.results, 'id');
      }),

    [fetchPartners.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.requests.fetch.error = payload;
      }),
    [fetchPartner.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.loading = true;
        draft.requests.error = null;
      }),
    [fetchPartner.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.loading = false;
        draft.partner = payload;
      }),
    [fetchPartner.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.loading = false;
        draft.requests.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default partnersReducer;
