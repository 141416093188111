import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

// Action Creators
export const APP_INIT = 'App.Init';
export const AUTH_LOGIN = 'Auth.Login';
export const AUTH_LOGOUT = 'Auth.Logout';
export const AUTH_UPDATE = 'Auth.Update';
export const UPDATE_PUBLIC_PROFILE = 'Profile.Public.Update';
export const PROFILE_STATS = 'Profile.Stats';

export const init = createRoutine(APP_INIT);
export const login = createRoutine(AUTH_LOGIN);
export const logout = createRoutine(AUTH_LOGOUT);
export const update = createRoutine(AUTH_UPDATE);
export const updatePublic = createRoutine(UPDATE_PUBLIC_PROFILE);
export const stats = createRoutine(PROFILE_STATS);

// Initial State
const INITIAL_REQUEST_STATE = {
  login: { loading: false, error: null },
  update: { loading: false, error: null },
  updatePublic: { loading: false, error: null },
};

const INITIAL_STATE = {
  init: false,
  user: null,
  profile: null,
  trainingCenters: null,
  stats: null,
  requests: INITIAL_REQUEST_STATE,
};

// Reducer
const authReducer = handleActions(
  {
    [init.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.user = payload.user;
        draft.profile = payload.profile;
        draft.init = true;
      }),
    [init.FAILURE]: state =>
      produce(state, draft => {
        draft.init = true;
      }),
    [login.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.login.loading = true;
        draft.requests.login.error = null;
      }),
    [login.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.user = payload.user;
        draft.profile = payload.profile;
        draft.trainingCenters = payload.trainingCenters;
        draft.requests.login.loading = false;
      }),
    [login.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.login.loading = false;
        draft.requests.login.error = payload;
      }),

    [stats.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.stats = payload;
      }),

    [logout.TRIGGER]: state =>
      produce(state, draft => {
        draft.init = false;
        draft.user = null;
      }),

    [update.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.update.loading = true;
        draft.requests.update.error = null;
      }),
    [update.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.profile = payload.profile;
        draft.requests.update.loading = false;
      }),
    [update.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.update.loading = false;
        draft.requests.update.error = payload;
      }),
    [updatePublic.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.updatePublic.loading = true;
        draft.requests.updatePublic.error = null;
      }),
    [updatePublic.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.profile = payload.profile;
        draft.requests.updatePublic.loading = false;
      }),
    [updatePublic.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.updatePublic.loading = false;
        draft.requests.updatePublic.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default authReducer;
