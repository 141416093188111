import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { fetchItems } from '../../store/reducers/GenericReducer';
import { getApplications } from '../../store/selectors/offers';
import { CANDIDATES_LIST } from '../../navigation/routes';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Close from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import LabelledValue from '../Common/LabelledValue';
import { getTranslatedJobTypes } from '../../utils/getTranslatedEnum';
import joinComponents from '../../utils/joinComponents';

const useStyles = makeStyles({
  title: {
    textTransform: 'uppercase',
  },
  subheader: {},
  cardActions: {
    borderTop: '1px solid #dedede',
  },
});

const STATE_MAP = {
  draft: 'Draft',
  review: 'Review',
  publish: 'Published',
  refused: 'Refused',
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={3}>{children}</Box>}
    </div>
  );
}

const OffersDetail = ({ item, Actions, ...props }) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const classes = useStyles();
  const [currentTab, setTab] = React.useState(0);
  const [applicationsCount, setApplicationsCount] = React.useState(0);
  const applications = useSelector(getApplications);

  React.useEffect(() => {
    dispatch(fetchItems(null, 'applications'));
  }, [dispatch]);

  React.useEffect(() => {
    item && setApplicationsCount(Object.values(applications).filter(app => app.job_id === item.id).length);
  }, [applications, item]);

  React.useEffect(() => {
    props.onRoute(match.params.id);
  }, [dispatch, props, match.params.id]);

  const chips = values => {
    return (
      <Grid container spacing={1}>
        {values.map(function(item) {
          return (
            <Grid key={item} item>
              <Chip size="small" label={item} />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    item && (
      <Card variant="outlined">
        <CardHeader
          classes={{
            title: classes.title,
            subheader: classes.subheader,
          }}
          title={t('JOB OFFER')}
          subheader={
            <Box display="flex" flexDirection="column">
              <Typography variant="h5" color="textPrimary">
                {item.name}
              </Typography>
              <Typography variant="subtitle1" color="textPrimary">
                {STATE_MAP[item.status]}
              </Typography>
              {applicationsCount > 0 && (
                <Typography variant="subtitle1" color="secondary">
                  <Link color="inherit" component={NavLink} to={CANDIDATES_LIST + '?job_id=' + item.id}>
                    {t('See {{applicationsCount}} applications', {
                      applicationsCount,
                    })}
                  </Link>
                </Typography>
              )}
            </Box>
          }
          titleTypographyProps={{ variant: 'button', gutterBottom: true }}
          subheaderTypographyProps={{
            variant: 'h5',
            color: 'textPrimary',
          }}
          action={
            <>
              <IconButton onClick={props.onCloseClick}>
                <Close />
              </IconButton>
            </>
          }
        />
        <CardContent>
          <Tabs
            value={currentTab}
            onChange={(event, tab) => {
              setTab(tab);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label={t('DESCRIPTION')} />
            <Tab label={t('DETAILs')} />
          </Tabs>
          <TabPanel value={currentTab} index={0}>
            <Grid container spacing={3}>
              {/* DESCRIPTION */}
              <Grid item xs={12}>
                <LabelledValue>
                  {item.description ? (
                    joinComponents(item.description.split('\n'), <br />, true)
                  ) : (
                    <Typography color="textSecondary">
                      <i>{t('No description')}</i>
                    </Typography>
                  )}
                </LabelledValue>
              </Grid>

              {/* CERTIFICATIONS */}
              <Grid item xs={12}>
                <LabelledValue label={t('Required certifications')}>
                  {item.certificates.length ? (
                    chips(item.certificates.map(certificate => certificate.name))
                  ) : (
                    <Typography color="textSecondary">
                      <i>{t('No required certifications')}</i>
                    </Typography>
                  )}
                </LabelledValue>
              </Grid>

              {/* SKILLS */}
              <Grid item xs={12}>
                <LabelledValue label={t('Additional Skills')}>
                  {item.skills.length ? (
                    chips(item.skills.map(skill => skill.name))
                  ) : (
                    <Typography color="textSecondary">
                      <i>{t('No additional skills')}</i>
                    </Typography>
                  )}
                </LabelledValue>
              </Grid>

              {/* LOCATION */}
              <Grid item xs={6}>
                <LabelledValue label={t('Location')}>
                  {(() => {
                    const locationStr = [item.city, item.state?.name, item.country?.name]
                      .filter(chunk => chunk)
                      .join(', ');

                    if (locationStr) {
                      return locationStr;
                    } else {
                      return (
                        <Typography color="textSecondary">
                          <i>{t('No location specified')}</i>
                        </Typography>
                      );
                    }
                  })()}
                </LabelledValue>
              </Grid>

              {/* LANGUAGES */}
              <Grid item xs={6}>
                <LabelledValue label={t('Required languages')}>
                  {item.languages?.length ? (
                    chips(item.languages.map(certificate => certificate.name))
                  ) : (
                    <Typography color="textSecondary">
                      <i>{t('No required languages')}</i>
                    </Typography>
                  )}
                </LabelledValue>
              </Grid>

              {/* CANDIDATES INFO */}
              <Grid item xs={12} sm={6}>
                <LabelledValue label={t('Potential candidates')}>{item.potential_candidates.toString()}</LabelledValue>
              </Grid>

              <Grid item xs={12} sm={6}>
                <LabelledValue label={t('Matches job criteria')}>
                  {item.contactable_candidates.toString()}
                </LabelledValue>
              </Grid>

              <Grid item xs={12} sm={6}>
                <LabelledValue label={t('Matches candidates searches')}>{item.job_searches.toString()}</LabelledValue>
              </Grid>

              <Grid item xs={12} sm={6}>
                <LabelledValue label={t('Applicants total')}>{item.applications_count.toString()}</LabelledValue>
              </Grid>

              <Grid item xs={12} sm={6}>
                <LabelledValue label={t('Link to applications')}>
                  <Link color="inherit" component={NavLink} to={CANDIDATES_LIST + '?job_id=' + item.id}>
                    {t(`See ${item.applications_count.toString()} applications`)}
                  </Link>
                </LabelledValue>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={currentTab} index={1}>
            <Box my={3}>
              <Typography variant="h6">{t('Job details')}</Typography>
            </Box>

            {/* START DATE */}
            <Grid container spacing={3}>
              {!!item.job_start_date && (
                <Grid item xs={12} sm={6}>
                  <LabelledValue label={t('Start Date')}>
                    {new Date(item.job_start_date).toLocaleDateString()}
                  </LabelledValue>
                </Grid>
              )}

              {/* END DATE */}
              {!!item.job_end_date && (
                <Grid item xs={12} sm={6}>
                  <LabelledValue label={t('Expiring Date')}>
                    {new Date(item.job_end_date).toLocaleDateString()}
                  </LabelledValue>
                </Grid>
              )}

              {/* CONTRACT TYPE */}
              {!!item.contract_type && (
                <Grid item xs={12} sm={6}>
                  <LabelledValue label={t('Type')}>{getTranslatedJobTypes(item.contract_type, t)}</LabelledValue>
                </Grid>
              )}

              {/* SALARY */}
              {!!item.salary && (
                <Grid item xs={12} sm={6}>
                  <LabelledValue label={t('Salary')}>{item.salary}</LabelledValue>
                </Grid>
              )}

              {/* REMOTE CHECKBOX */}
              <Grid item xs={12} sm={6}>
                <LabelledValue label={t('Remote?')}>{item.remote ? t('Yes') : t('No')}</LabelledValue>
              </Grid>

              {/* REMOTE INTERNATIONAL CHECKBOX */}
              <Grid item xs={12} sm={6}>
                <LabelledValue label={t('Remote international?')}>
                  {item.remote_international ? t('Yes') : t('No')}
                </LabelledValue>
              </Grid>

              {/* IN OFFICE CHECKBOX */}
              <Grid item xs={12} sm={6} md={3}>
                <LabelledValue label={t('In Place?')}>{item.inplace ? t('Yes') : t('No')}</LabelledValue>
              </Grid>
            </Grid>

            <Box my={3}>
              <Typography variant="h6">{t('Publication details')}</Typography>
            </Box>

            {/* PUBLISH START DATE */}
            <Grid container spacing={3}>
              {!!item.publish_date && (
                <Grid item xs={12} sm={6}>
                  <LabelledValue label={t('Publish start date')}>
                    {new Date(item.publish_date).toLocaleDateString()}
                  </LabelledValue>
                </Grid>
              )}

              {/* PUBLISH END DATE */}
              {!!item.close_date && (
                <Grid item xs={12} sm={6}>
                  <LabelledValue label={t('Publish end date')}>
                    {new Date(item.close_date).toLocaleDateString()}
                  </LabelledValue>
                </Grid>
              )}
            </Grid>

            {/* EXTERNAL LINK */}
            <Grid item xs={12} sm={6}>
              <LabelledValue label={t('URL to the full post')}>
                {item.external_url || (
                  <Typography color="textSecondary">
                    <i>{t('Not defined')}</i>
                  </Typography>
                )}
              </LabelledValue>
            </Grid>

            {/* ORGANIZATION DETAILS */}
            {(!!item.organization ||
              !!item.organization_address ||
              !!item.organization_email ||
              !!item.organization_website) && (
              <Grid item xs={12} sm={6}>
                <LabelledValue label={t('Organization')}>
                  {[item.organization, item.organization_address, item.organization_email, item.organization_website]
                    .filter(chunk => chunk)
                    .map((chunk, idx) => (
                      <React.Fragment key={idx}>
                        {chunk}
                        <br></br>
                      </React.Fragment>
                    ))}
                </LabelledValue>
              </Grid>
            )}
          </TabPanel>
        </CardContent>

        <CardActions
          classes={{
            root: classes.cardActions,
          }}
          disableSpacing
        >
          {Actions && <Actions item={item} />}
        </CardActions>
      </Card>
    )
  );
};

export default React.memo(OffersDetail);
