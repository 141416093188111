import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../data/network';

export const QUERY_KEY = 'shop.vouchers';

/** Get a list of all available vouchers on sale */
const useVoucherProducts = () => {
  const req = useQuery([QUERY_KEY], () => api.voucherProducts.fetch());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    vouchers: req.data?.results || [],
    ...req,
  };
};

export default useVoucherProducts;
