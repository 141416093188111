import Axios from 'axios';
import client, { HOST } from './config';

const TOKEN_KEY = 'auth_token';
const API_ENDPOINT = `${HOST}/api`;
const PARTNER_ENDPOINT = `${API_ENDPOINT}/partners/v1`;
const JOB_ENDPOINT = `${API_ENDPOINT}/job/v1`;
const SHOP_ENDPOINT = `${API_ENDPOINT}/shop/v1`;
const PARTNER_PUBLIC_ENDPOINT = `${PARTNER_ENDPOINT}/public`;
const COMMON_ENDPOINT = `${API_ENDPOINT}/v1`;
const ACCOUNTS_ENDPOINT = `${API_ENDPOINT}/public/v1/accounts`;
const HELPDESK_ENDPOINT = `${API_ENDPOINT}/helpdesk/v1`;
const ATTACHMENT_ENDPOINT = `${API_ENDPOINT}/upload_attachment/v1`;

const Resource = (client, endpoint) => {
  const get = id => client.get(`${endpoint}/${id}/`);
  const search = params => client.get(endpoint, params);
  const create = data => client.post(endpoint, data);
  const update = (id, data) => client.put(`${endpoint}/${id}/`, data);
  const remove = id => client.remove(`${endpoint}/${id}/`);

  return { get, search, create, update, remove };
};

const LocationResource = (client, endpoint) => {
  const res = Resource(client, endpoint);
  const publish = id => client.get(`${endpoint}/publish/${id}`);
  const draft = id => client.get(`${endpoint}/draft/${id}`);

  return { ...res, publish, draft };
};

const JobResource = (client, endpoint) => {
  const res = Resource(client, endpoint);
  const toggleStatus = offerId => client.post(`${endpoint}/${offerId}/publish`);
  const skills = () => client.get(`/skills`);
  const withdraw = offerId => client.post(`${endpoint}/${offerId}/withdraw`);
  // const notify = data => client.post(`${endpoint}/notify`, data);

  return { ...res, toggleStatus, skills, withdraw /* notify */ };
};

const OpportunityResource = (client, endpoint) => {
  const res = Resource(client, endpoint);
  const lost = opportunityId => client.post(`${endpoint}/${opportunityId}/lost`);
  return { ...res, lost };
};

const DocumentResource = (client, endpoint) => {
  const res = Resource(client, endpoint);

  const folders = () => client.get(`${endpoint}/folders`);
  const tags = () => client.get(`${endpoint}/tags`);

  return { ...res, folders, tags };
};

const AttachmentResource = client => {
  const create = data => client.postMultipart('', data);

  return { create };
};

const CartResource = client => {
  const res = Resource(client, '/cart');
  const addItem = data => client.post(`/cart/add_item`, data);
  const updateItem = data => client.post(`/cart/update_item`, data);
  const removeItem = data => client.post(`/cart/remove_item`, data);
  const clear = data => client.post(`/cart/clear`, data);
  const fetch = res.search;
  const askReview = data => client.post(`/cart/ask_review`, data);

  return { ...res, addItem, updateItem, removeItem, clear, fetch, askReview };
};

const PaymentsResource = client => {
  const methods = data => client.get('/payment_methods', data);
  const confirmTransfer = data => client.post('/bank_transfer/confirm', data);
  const stripeCheckoutURL = data => client.post('/stripe/checkout', data);
  const paypalCheckoutURL = data => client.post('/paypal/checkout', data);
  const confirmFreeOrder = data => client.post(`/cart/confirm_free_order`, data);

  return { stripeCheckoutURL, paypalCheckoutURL, confirmTransfer, methods, confirmFreeOrder };
};

const VoucherProductsResource = client => {
  const fetch = params => client.get(`/vouchers/`, params);
  return { fetch };
};

const JobOffersProductsResource = client => {
  const fetch = params => client.get(`/jobs/`, params);
  return { fetch };
};

const PartnerUserResource = client => {
  const search = params => client.get('/users', params);
  const create = data => client.post('/invite', data);
  const update = (id, data) => client.put(`/users/${id}/`, data);
  const remove = id => client.remove(`/users/${id}/`);
  const toggleUserState = data => client.post('/users/toggle_user_state', data);
  const changeRole = data => client.post('/users/change_role', data);
  return { search, create, update, remove, toggleUserState, changeRole };
};

const PartnerTrainingCenter = client => {
  const search = params => client.get('/training', params);
  const create = data => client.post('/training', data);
  const update = (id, data) => client.put(`/training/${id}/`, data);
  const remove = id => client.remove(`/training/${id}/`);
  const courses = () => client.get('/training_course');
  const topics = () => client.get('/training_topic');
  const languages = () => client.get('/lang');
  const countries = () => client.get('/country');
  return {
    search,
    create,
    update,
    remove,
    courses,
    topics,
    languages,
    countries,
  };
};

const PartnerAgreementsResource = client => {
  const partnerTypes = () => client.get('/partner_types');
  const partnerLevels = () => client.get('/partner_levels');
  const search = params => client.get(`/channel_agreements/`, params);
  return { search, partnerTypes, partnerLevels };
};

const TagsResource = client => {
  const search = params => client.get(`/crm_tags/`, params);
  return { search };
};

const VoucherResource = client => {
  const get = id => client.get(`/vouchers/${id}`);
  const search = params => client.get(`/vouchers`, params);
  const orders = params => client.get(`/voucher_orders`, params);
  const assign = data => client.post(`/vouchers`, data);
  const channel_assign = data => client.post(`/vouchers/channel_assign`, data);
  const by_partner = id => client.get(`/partner_vouchers/${id}`);

  return { get, search, orders, assign, channel_assign, by_partner };
};

const VoucherOrderResource = client => {
  const get = id => client.get(`/voucher_orders/${id}`);
  const search = params => client.get(`/voucher_orders`, params);

  return { get, search };
};

const CouponsResource = client => {
  const get = id => client.get(`/coupons/${id}`);
  const search = params => client.get(`/coupons`, params);
  const create = data => client.post(`/coupons`, data);
  const assign = data => client.post(`/coupons/assign`, data);
  const fetchAssignees = data => client.get(`/coupons_assignee`);

  return { get, search, create, assign, fetchAssignees };
};

const CouponProgramsResource = client => {
  const search = params => client.get(`/coupon_programs`, params);
  return { search };
};

const PasswordResource = client => {
  const sendEmail = data => client.post(`/send_email`, data);
  const checkToken = data => client.post(`/check_token`, data);
  const resetPassword = data => client.post(`/reset`, data);

  return { sendEmail, checkToken, resetPassword };
};

const MonitorResource = () => {
  const isServerUp = async () => {
    try {
      // Use a new axios instance to bypass custom interceptors
      const res = await Axios.get(`${HOST}/api/system/v1/monitor/liveness`, {
        timeout: 4 * 1000,
      });
      return res.data.status === 'OK';
    } catch (err) {
      return false;
    }
  };

  return { isServerUp };
};

const BackendClient = (http, baseEndpoint = PARTNER_ENDPOINT) => {
  const headers = () => {
    const headers = {};
    const token = localStorage.getItem(TOKEN_KEY);
    headers['Content-type'] = 'application/json';
    if (token) {
      headers['Authorization'] = 'Token ' + token;
    }
    return headers;
  };

  const userInfo = () => {
    return http.get(`${COMMON_ENDPOINT}/user`, { headers: headers() }).then(response => {
      return response.data;
    });
  };

  const login = (login, password) => {
    return http
      .post(`${PARTNER_PUBLIC_ENDPOINT}/auth_token`, { login, password }, { headers: headers() })
      .then(response => {
        localStorage.setItem(TOKEN_KEY, response.data.auth_token);
        return userInfo();
      });
  };

  const getProfile = () => {
    return http.get(`${PARTNER_ENDPOINT}/profile`, { headers: headers() }).then(response => {
      return response.data;
    });
  };

  const fetchStats = () => {
    return http.post(`${PARTNER_ENDPOINT}/profile/stats`, {}, { headers: headers() }).then(response => response.data);
  };

  const updateProfile = data => {
    return http.post(`${PARTNER_ENDPOINT}/profile`, data, { headers: headers() }).then(response => response.data);
  };

  const updatePublicProfile = data => {
    return http
      .post(`${PARTNER_ENDPOINT}/profile/public`, data, { headers: headers() })
      .then(response => response.data);
  };

  const convertLead = ids => {
    return http
      .post(`${PARTNER_ENDPOINT}/leads_conversion`, { lead_id: ids }, { headers: headers() })
      .then(response => {
        return response.data;
      });
  };

  const resendInvite = id => {
    return http
      .post(`${PARTNER_ENDPOINT}/invitation/resend`, { invite_id: id }, { headers: headers() })
      .then(response => response.data);
  };

  const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
  };

  const get = (path, params = {}) => {
    return new Promise((resolve, reject) => {
      http({
        url: baseEndpoint + path,
        method: 'get',
        params: params,
        headers: headers(),
      })
        .then(response => {
          if (response.data?.error) return reject('Not Authenticated');
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const post = (path, data) => {
    return new Promise((resolve, reject) => {
      http({
        url: baseEndpoint + path,
        method: 'post',
        headers: headers(),
        data: data,
      })
        .then(response => {
          if (response.data?.error) return reject('Not Authenticated');
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const postMultipart = (path, data) => {
    return new Promise((resolve, reject) => {
      http({
        url: baseEndpoint + path,
        method: 'post',
        headers: {
          ...headers(),
          'Content-Type': 'multipart/form-data',
        },
        data: data,
      })
        .then(response => {
          if (response.data?.error) return reject('Not Authenticated');
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const put = (path, data) => {
    return new Promise((resolve, reject) => {
      http({
        url: baseEndpoint + path,
        method: 'put',
        headers: headers(),
        data: data,
      })
        .then(response => {
          if (response.data?.error) return reject('Not Authenticated');
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const remove = path => {
    return new Promise((resolve, reject) => {
      http({
        url: baseEndpoint + path,
        method: 'delete',
        headers: headers(),
      })
        .then(response => {
          if (response.data?.error) return reject('Not Authenticated');
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  return {
    login,
    userInfo,
    getProfile,
    updateProfile,
    updatePublicProfile,
    fetchStats,
    convertLead,
    resendInvite,
    logout,
    get,
    post,
    postMultipart,
    put,
    remove,
  };
};

const HelpdeskResource = (client, endpoint) => {
  const get = id => client.get(`${endpoint}/${id}/`);
  const search = params => client.get(endpoint, params);
  const create = data => client.post(endpoint, data);
  const sendMessage = (id, data) => client.post(`${endpoint}/${id}/send_message`, data);

  return { get, search, create, sendMessage };
};

const Backend = http => {
  const client = BackendClient(http);
  const apiClient = BackendClient(http, API_ENDPOINT);
  const jobClient = BackendClient(http, JOB_ENDPOINT);
  const shopClient = BackendClient(http, SHOP_ENDPOINT);
  const accountsClient = BackendClient(http, ACCOUNTS_ENDPOINT);
  const helpdeskClient = BackendClient(http, HELPDESK_ENDPOINT);
  const attachmentClient = BackendClient(http, ATTACHMENT_ENDPOINT);
  const monitor = MonitorResource();

  const login = client.login;
  const userInfo = client.userInfo;
  const getProfile = client.getProfile;
  const updateProfile = client.updateProfile;
  const updatePublicProfile = client.updatePublicProfile;
  const logout = client.logout;
  const fetchStats = client.fetchStats;

  const contacts = Resource(client, '/contacts');
  const convertLead = client.convertLead;
  const resendInvite = client.resendInvite;

  const teachers = Resource(client, '/teachers');
  const proctors = Resource(client, '/proctors');
  const students = Resource(client, '/students');
  const siteAdmins = Resource(client, '/site_admins');
  const importStudents = Resource(client, '/import_students');
  const agreements = Resource(client, '/agreements');
  const locations = LocationResource(client, '/locations');
  const leads = Resource(client, '/leads');
  const opportunities = OpportunityResource(client, '/opportunities');
  const partners = Resource(client, '/partners');
  const news = Resource(client, '/news');
  const jobs = JobResource(jobClient, '/jobs');
  const people = Resource(client, '/people');
  const applications = Resource(jobClient, '/applications');
  const documents = DocumentResource(client, '/documents');

  const certificates = Resource(apiClient, '/v1/certificates');
  const places = Resource(apiClient, '/v1/places');
  const topics = Resource(client, '/topics');
  const languages = Resource(apiClient, '/public/v1/languages');

  const users = PartnerUserResource(client);
  const trainingCenter = PartnerTrainingCenter(client);
  const partnerAgreements = PartnerAgreementsResource(client);
  const tags = TagsResource(client);
  const vouchers = VoucherResource(client);
  const voucherOrders = VoucherOrderResource(client);
  const coupons = CouponsResource(client);
  const couponPrograms = CouponProgramsResource(client);
  const cart = CartResource(shopClient);
  const payments = PaymentsResource(shopClient);
  const voucherProducts = VoucherProductsResource(shopClient);
  const jobOffersProducts = JobOffersProductsResource(shopClient);
  const attachments = AttachmentResource(attachmentClient);
  const saleOrders = Resource(shopClient, '/saleorder');
  const invoices = Resource(shopClient, '/invoice');
  const tickets = HelpdeskResource(helpdeskClient, '/tickets');
  const partnerTags = Resource(client, '/tags');
  const materialConfig = Resource(client, '/learning_material_config');
  const materialRequest = Resource(client, '/learning_material_request');
  const supportTopics = Resource(client, '/support_topics');

  const stages = Resource(client, '/crm_stages');
  const password = PasswordResource(accountsClient);

  return {
    monitor,
    login,
    userInfo,
    getProfile,
    updateProfile,
    updatePublicProfile,
    fetchStats,
    logout,
    contacts,
    convertLead,
    supportTopics,
    resendInvite,
    agreements,
    locations,
    teachers,
    proctors,
    topics,
    students,
    siteAdmins,
    importStudents,
    users,
    trainingCenter,
    leads,
    opportunities,
    partnerAgreements,
    documents,
    tags,
    vouchers,
    voucherOrders,
    coupons,
    couponPrograms,
    partners,
    news,
    jobs,
    people,
    applications,
    cart,
    payments,
    stages,
    voucherProducts,
    jobOffersProducts,
    saleOrders,
    invoices,
    tickets,
    attachments,
    password,
    certificates,
    places,
    languages,
    partnerTags,
    materialConfig,
    materialRequest,
  };
};

const api = Backend(client);

export default api;
