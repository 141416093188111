import { useMutation, useQueryClient } from 'react-query';
import api from '../../data/network';

import { QUERY_KEY } from './useProctors';

const useProctorMutations = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  const createProctor = useMutation(payload => api.proctors.create(payload), {
    onSuccess: () => {
      invalidateQuery();
    },
  });

  const updateProctor = useMutation(payload => api.proctors.update(payload.id, payload), {
    onSuccess: data => {
      invalidateQuery();
      queryClient.invalidateQueries([QUERY_KEY, data.id]);
    },
  });

  const deleteProctor = useMutation(id => api.proctors.remove(id), {
    onSuccess: () => {
      invalidateQuery();
    },
  });

  return {
    createProctor,
    updateProctor,
    deleteProctor,
  };
};

export default useProctorMutations;
