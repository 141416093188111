import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffcd04',
      dark: '#ffb500',
    },
    secondary: {
      main: '#37474F',
      dark: '#263238',
    },
    background: {
      default: '#eceff1',
    },
  },
  typography: {
    h1: {
      color: '#37474F',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
    },
    h2: {
      color: '#000000DE',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
    },
    h3: {
      color: '#263238',
      fontSize: '1rem',
      lineHeight: '1rem',
      fontWeight: 500,
    },
    body: {
      color: '#263238',
      fontSize: '0.875rem',
      lineHeight: '1rem',
      fontWeight: 400,
    },
    body2: {
      color: '#757575',
      fontSize: '0.875rem',
      lineHeight: '1rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '0.8rem',
      [defaultTheme.breakpoints.up['md']]: {
        fontSize: '0.9rem',
      },
      [defaultTheme.breakpoints.up['lg']]: {
        fontSize: '1rem',
      },
    },
  },

  overrides: {
    MuiButton: {
      root: {
        boxShadow: 'none',
        borderRadius: 0,

        '&:hover': {
          boxShadow: 'none',
          borderRadius: 0,
        },
      },

      contained: {
        boxShadow: 'none',
        borderRadius: 0,

        '&:hover': {
          boxShadow: 'none',
          borderRadius: 0,
        },
      },
    },
  },
});

export default theme;
