import _get from 'lodash/get';
import { createSelector } from 'reselect';

export const getEndpoint = createSelector(
  state => state.generic.ep,
  ep => ep,
);

export const getItems = createSelector(
  state => _get(state.generic, [state.generic.ep, 'items'], {}),
  items => items,
);

export const selectIsLoading = createSelector(
  state => state.generic.loading,
  loading => loading,
);
