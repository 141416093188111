import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';

import logo from '../../assets/images/logo.png';
import logoInverse from '../../assets/images/logo-inverse.png';
import logoWhite from '../../assets/images/logo-white.png';

const useStyles = makeStyles({
  logo: {
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
});

const Logo = ({ size, inverse, white, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box height={60} {...rest}>
      <img
        src={inverse ? logoInverse : white ? logoWhite : logo}
        className={classes.logo}
        alt={t('Linux Professional Institute')}
      />
    </Box>
  );
};

Logo.propTypes = {
  size: PropTypes.string,
  inverse: PropTypes.bool,
  white: PropTypes.bool,
};

export default React.memo(Logo);
