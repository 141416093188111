import React from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

const RoleFilter = ({ currentFilters, setFilter }) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth variant="outlined">
      <FormLabel component="legend">{t('Role')}</FormLabel>
      <FormControlLabel
        control={
          <Checkbox
            checked={currentFilters.role === 'user'}
            onChange={event => {
              setFilter('role', event.target.checked ? 'user' : '');
            }}
            name="role"
            color="primary"
          />
        }
        label={t('User')}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={currentFilters.role === 'admin'}
            onChange={event => {
              setFilter('role', event.target.checked ? 'admin' : '');
            }}
            name="role"
            color="primary"
          />
        }
        label={t('Admin')}
      />
    </FormControl>
  );
};

export default React.memo(RoleFilter);
