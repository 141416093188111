import _values from 'lodash/values';
import { createSelector } from 'reselect';

export const getPartnersList = createSelector(
  state => state.partner.list,
  partners => _values(partners),
);

export const selectPartner = createSelector(
  state => state.partner.partner,
  partner => partner,
);
