import React from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

const StatusFilter = ({ currentFilters, setFilter }) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth variant="outlined">
      <FormLabel component="legend">{t('Status')}</FormLabel>
      <FormControlLabel
        control={
          <Checkbox
            checked={currentFilters.status === 'active'}
            onChange={event => {
              setFilter('status', event.target.checked ? 'active' : '');
            }}
            name="status"
            color="primary"
          />
        }
        label={t('Active')}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={currentFilters.status === 'canceled'}
            onChange={event => {
              setFilter('status', event.target.checked ? 'canceled' : '');
            }}
            name="status"
            color="primary"
          />
        }
        label={t('Canceled')}
      />
    </FormControl>
  );
};

export default React.memo(StatusFilter);
