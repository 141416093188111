import { takeLatest, put, all, call } from 'redux-saga/effects';
import {
  fetchCart,
  cartAddItem,
  cartUpdateItem,
  cartRemoveItem,
  cartAskReview,
  initCheckout,
  confirmPayment,
  fetchVoucherProducts,
  fetchJobOffersProducts,
  fetchCheckoutConfig,
  fetchSaleOrders,
  openCart,
} from '../reducers/ShopReducer';
// import { setMessage } from "../reducers/SnackReducer"

import i18n from '../../locales';
import api from '../../data/network';

/*
function* fetchCartSaga() {
  try {
    const cart = yield call([api.cart, api.cart.fetch]);
    yield put(fetchCart.success(cart));
  } catch (e) {
    console.error(e);
    yield put(fetchCart.failure(i18n.t('Unable to fetch students')));
  }
}
function* addItemSaga({ payload }) {
  try {
    const cart = yield call([api.cart, api.cart.addItem], payload);
    yield put(cartAddItem.success(cart));
    yield put(openCart());
  } catch (e) {
    console.error(e);
    yield put(cartAddItem.failure(i18n.t('Unable to fetch students')));
  }
}

function* updateItemSaga({ payload }) {
  try {
    const cart = yield call([api.cart, api.cart.updateItem], payload);
    yield put(cartUpdateItem.success(cart));
  } catch (e) {
    console.error(e);
    yield put(cartUpdateItem.failure(i18n.t('Unable to fetch students')));
  }
}

function* removeItemSaga({ payload }) {
  try {
    const cart = yield call([api.cart, api.cart.removeItem], payload);
    yield put(cartRemoveItem.success(cart));
  } catch (e) {
    console.error(e);
    yield put(cartRemoveItem.failure(i18n.t('Unable to fetch students')));
  }
}
function* fetchCheckoutConfigSaga() {
  try {
    const config = yield call([api.stripe, api.stripe.config]);
    yield put(fetchCheckoutConfig.success(config));
  } catch (e) {
    console.error(e);
    yield put(fetchCheckoutConfig.failure(i18n.t('Unable to fetch students')));
  }
}
 */

function* initCheckoutSaga({ payload }) {
  try {
    const cart = yield call([api.stripe, api.stripe.init], payload);
    yield put(initCheckout.success(cart));
  } catch (e) {
    console.error(e);
    yield put(initCheckout.failure(i18n.t('Unable to fetch students')));
  }
}

function* confirmPaymentSaga({ payload }) {
  try {
    const confirm = yield call([api.stripe, api.stripe.confirm], payload);
    yield put(initCheckout.success(confirm));
  } catch (e) {
    console.error(e);
    yield put(initCheckout.failure(i18n.t('Unable to fetch students')));
  }
}

function* fetchVoucherProductsSaga() {
  try {
    const products = yield call([api.voucherProducts, api.voucherProducts.fetch]);
    yield put(fetchVoucherProducts.success(products.results));
  } catch (e) {
    console.error(e);
    yield put(fetchVoucherProducts.failure(i18n.t('Unable to fetch products')));
  }
}

function* fetchJobOffersProductsSaga() {
  try {
    const products = yield call([api.jobOffersProducts, api.jobOffersProducts.fetch]);
    yield put(fetchJobOffersProducts.success(products.results));
  } catch (e) {
    console.error(e);
    yield put(fetchJobOffersProducts.failure(i18n.t('Unable to fetch products')));
  }
}

function* fetchSaleOrdersSaga() {
  try {
    const saleOrders = yield call([api.saleOrders, api.saleOrders.search]);
    yield put(fetchSaleOrders.success(saleOrders.results));
  } catch (e) {
    console.error(e);
    yield put(fetchSaleOrders.failure(i18n.t('Unable to fetch sale orders')));
  }
}

function* askReviewSaga() {
  try {
    const cart = yield call([api.cart, api.cart.askReview], {});
    yield put(cartAskReview.success(cart));
    yield put(openCart());
  } catch (e) {
    console.error(e);
    yield put(cartAskReview.failure(i18n.t('Unable to fetch students')));
  }
}

export default function* root() {
  yield all([
    // yield takeLatest([fetchCart.TRIGGER], fetchCartSaga),
    // yield takeLatest([cartAddItem.TRIGGER], addItemSaga),
    // yield takeLatest([cartUpdateItem.TRIGGER], updateItemSaga),
    // yield takeLatest([cartRemoveItem.TRIGGER], removeItemSaga),
    yield takeLatest([cartAskReview.TRIGGER], askReviewSaga),
    yield takeLatest([initCheckout.TRIGGER], initCheckoutSaga),
    yield takeLatest([confirmPayment.TRIGGER], confirmPaymentSaga),
    yield takeLatest([fetchVoucherProducts.TRIGGER], fetchVoucherProductsSaga),
    // yield takeLatest([fetchCheckoutConfig.TRIGGER], fetchCheckoutConfigSaga),
    yield takeLatest([fetchJobOffersProducts.TRIGGER], fetchJobOffersProductsSaga),
    yield takeLatest([fetchSaleOrders.TRIGGER], fetchSaleOrdersSaga),
  ]);
}
