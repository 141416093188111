import _keyBy from 'lodash/keyBy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { Link as RouteLink } from 'react-router-dom';
import { useVouchers } from '../../store/hooks/vouchers';
import { useFilters } from '../../components/Generic/UseFilters';
import { VOUCHER_PRODUCT_LIST } from '../../navigation/routes';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import ActionButton from '../../components/Common/ActionButton';
import Layout from '../../components/App/Layout';
import VouchersTable from '../../components/Vouchers/VouchersTable';
import VouchersDetail from '../../components/Vouchers/VouchersDetail';
import VouchersFilters from '../../components/Vouchers/VouchersFilters';
import VouchersActions from '../../components/Vouchers/VouchersActions';
import Snackbar from '../../components/Common/Snackbar';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

const TABS = { UNASSIGNED: 0, ASSIGNED: 1 };

const useStyles = makeStyles(theme => ({
  customCardRoot: {
    padding: 0,
  },
  tabs: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  table: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  expButton: {
    fontSize: '14px',
    cursor: 'pointer',
    padding: '6px 16px',
    zIndex: 10,
    marginRight: 5,
  },
}));

const VouchersPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { path } = useRouteMatch();
  let history = useHistory();

  const detail_route_match = useRouteMatch({
    path: path + '/:id/',
  });

  const [activeTab, setActiveTab] = React.useState(0);
  const [id, setId] = React.useState();

  const vouchers = useVouchers();
  const filters = useFilters();

  const [shownItems, setShownItems] = React.useState([]);

  const item = _keyBy(vouchers.all, 'id')[id];

  const handleRowClick = React.useCallback(
    id => {
      history.push(path + '/' + id);
    },
    [history, path],
  );

  const handleDownload = () => {
    const csv = shownItems.map(item => {
      return {
        'Voucher Code': item.code,
        Name: item.product_template_id_name,
        State: item.state,
        'Assigned to': item.assigned_to_name,
        'Collected by user at': item.redeemed_date,
        'Created at': item.sale_order_date,
      };
    });

    const replacer = (key, value) => (value === null ? '' : value);
    const header = Object.keys(csv[0]);
    let csvData = csv.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csvData.unshift(header.join(','));
    csvData = csvData.join('\r\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'vouchers.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCloseDetail = React.useCallback(() => {
    setId(null);
    history.push(path);
  }, [history, path]);

  React.useEffect(() => {
    let shown =
      activeTab === TABS.ASSIGNED
        ? vouchers.all.filter(({ assigned_to_id }) => !!assigned_to_id)
        : vouchers.all.filter(({ assigned_to_id }) => !assigned_to_id);

    if ('product_template_id' in filters.current) {
      shown = shown.filter(item => item.product_template_id === parseInt(filters.current.product_template_id));
    }
    if ('assigned_to_id' in filters.current) {
      shown = shown.filter(item => item.assigned_to_id === parseInt(filters.current.assigned_to_id));
    }
    if (filters.current.state) {
      shown = shown.filter(item => item.state === filters.current.state);
    }

    setShownItems(shown);
  }, [activeTab, vouchers, filters]);

  return (
    <React.Fragment>
      <Layout title={t('Vouchers')} back={!!detail_route_match} backPath={path}>
        <Tabs
          className={classes.tabs}
          centered
          value={activeTab}
          onChange={(_e, newTabIndex) => {
            filters.clear();
            setActiveTab(newTabIndex);
          }}
        >
          <Tab label={t('Unassigned')} index={TABS.UNASSIGNED} />
          <Tab label={t('Assigned')} index={TABS.ASSIGNED} />
        </Tabs>

        <Box display="flex" justifyContent="space-between" pl={2} m={2} flexDirection="row">
          <Typography variant="subtitle1">{t('List of vouchers owned')}</Typography>
          <Box>
            <Button
              component={RouteLink}
              className={classes.expButton}
              onClick={handleDownload}
              startIcon={<GetAppIcon />}
            >
              {t('DOWNLOAD')}
            </Button>
            <ActionButton component={RouteLink} to={VOUCHER_PRODUCT_LIST}>
              {t('BUY VOUCHERS')}
            </ActionButton>
          </Box>
        </Box>

        <VouchersFilters controller={filters} />

        <Grid classes={{ root: classes.table }} container spacing={3} display="flex">
          <Hidden mdDown={!!detail_route_match}>
            <Grid item xs={12} lg>
              <Card variant="outlined">
                <CardContent classes={{ root: classes.customCardRoot }}>
                  <VouchersTable rows={shownItems} onRowClick={handleRowClick} />
                </CardContent>
              </Card>
            </Grid>
          </Hidden>
          <Switch>
            <Route path={path + '/:id'}>
              <Grid item xs={12} lg={4}>
                <VouchersDetail
                  item={item}
                  onRoute={setId}
                  Actions={VouchersActions}
                  onCloseClick={handleCloseDetail}
                />
              </Grid>
            </Route>
          </Switch>
        </Grid>
      </Layout>
      <Snackbar />
    </React.Fragment>
  );
};

export default React.memo(VouchersPage);
