import React from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../Generic/Table';
import Column from '../Generic/Column';

import dayjs from 'dayjs';

function formatDate(dateString) {
  return dayjs(dateString).format('MMM DD, YYYY');
}

const OpportunitiesTable = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Table {...props} defaultOrderBy="creation_date" defaultOrderDir="desc">
      <Column label={t('Creation Date')} field="creation_date" render={value => formatDate(value)} />
      <Column label={t('Opportunity Name')} field="name" />
      <Column label={t('Company/Account')} field="partner_name" />
      <Column label={t('Close Date')} field="date_deadline" />
      <Column label={t('Stage')} field="stage_name" />
    </Table>
  );
};

export default React.memo(OpportunitiesTable);
