import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';

const VoucherOrdersActions = ({ item }) => {
  const { t } = useTranslation();

  const handleDownloadClick = ev => {
    ev.preventDefault();
    const newWindow = window.open(item.download_url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
    return null;
  };

  return (
    <>
      {item.download_url && (
        <Button color="secondary" onClick={handleDownloadClick}>
          {t('Download Voucher Order')}
        </Button>
      )}
    </>
  );
};

export default React.memo(VoucherOrdersActions);
