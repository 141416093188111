import _keyBy from 'lodash/keyBy';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

// Action Creators
export const PROCTOR_FETCH = 'Proctors.Fetch';
export const PROCTOR_CREATE = 'Proctors.Create';

export const fetchProctors = createRoutine(PROCTOR_FETCH);
export const createProctor = createRoutine(PROCTOR_CREATE);

// Initial State
const INITIAL_REQUEST_STATE = {
  fetch: { loading: false, error: null },
  create: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: {},
  offset: 0,
  limit: 25,
  requests: INITIAL_REQUEST_STATE,
};

// Reducer
const proctorsReducer = handleActions(
  {
    [fetchProctors.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetch.loading = true;
        draft.requests.fetch.error = null;
      }),

    [fetchProctors.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.list = _keyBy(payload.results, 'id');
      }),

    [fetchProctors.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.requests.fetch.error = payload;
      }),

    [createProctor.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.create.loading = true;
        draft.requests.create.error = null;
      }),

    [createProctor.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.create.loading = false;
        draft.list[payload.id] = payload;
      }),

    [createProctor.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.create.loading = false;
        draft.requests.create.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default proctorsReducer;
