import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

// Action Creators
export const PUBLISH_JOB = 'Jobs.Publish';
export const NOTIFY_JOB = 'Jobs.Notify';
export const publishJob = createRoutine(PUBLISH_JOB);
export const notifyJob = createRoutine(NOTIFY_JOB);

// Initial State
const INITIAL_REQUEST_STATE = {
  publish: { loading: false, error: null },
  notify: { loading: false, error: null },
};

const INITIAL_STATE = {
  requests: INITIAL_REQUEST_STATE,
};

// Reducer
const jobsReducer = handleActions(
  {
    [publishJob.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.publish.loading = true;
        draft.requests.publish.error = null;
      }),
    [publishJob.SUCCESS]: state =>
      produce(state, draft => {
        draft.requests.publish.loading = false;
      }),
    [publishJob.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.publish.loading = false;
        draft.requests.publish.error = payload;
      }),

    [notifyJob.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.notify.loading = true;
        draft.requests.notify.error = null;
      }),
    [notifyJob.SUCCESS]: state =>
      produce(state, draft => {
        draft.requests.notify.loading = false;
      }),
    [notifyJob.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.notify.loading = false;
        draft.requests.notify.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default jobsReducer;
