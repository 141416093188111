import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { assignVoucher } from '../../store/reducers/VouchersReducer';
import { useAvailableTrainingPartners } from '../../store/hooks/students';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AutoCompleteFilter from '../Common/Filters/AutoCompleteFilter';
import { CircularProgress, Typography } from '@material-ui/core';

const AssignModal = ({ item, open, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector(state => state.vouchers.requests.assignVoucher.loading);
  const isError = useSelector(state => state.vouchers.requests.assignVoucher.error);
  const [selectedPartner, setSelectedPartner] = React.useState();
  const { partners } = useAvailableTrainingPartners();

  const handleAssign = React.useCallback(() => {
    let partner = selectedPartner ? partners.find(x => x.partner_id === parseInt(selectedPartner)) : null;
    partner && dispatch(assignVoucher({ voucher: item, partner }));
  }, [dispatch, item, onClose, selectedPartner, partners]);

  useEffect(() => {
    if (!isError && !isLoading) {
      onClose();
    }
  }, [isError, isLoading]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>{t('Assign voucher')}</DialogTitle>
      <DialogContent>
        {isError ? (
          <Typography color="red">{t('Error')}</Typography>
        ) : (
          <AutoCompleteFilter
            groupBy={option => option._partner_type}
            name="assigned_to_id"
            label={t('Assignee')}
            value={selectedPartner ? partners.find(x => x.partner_id === parseInt(selectedPartner)) : null}
            options={partners}
            optionLabel={option => (option.partner_name ? `[${option.lpi_id}] ${option.partner_name}` : '')}
            onChange={(event, value) => {
              setSelectedPartner(value ? value.partner_id : null);
            }}
          />
        )}
        <DialogActions>
          <Button onClick={handleAssign} color="secondary" disabled={!selectedPartner || isLoading}>
            {isLoading ? <CircularProgress size={24} /> : t('Confirm')}
          </Button>
          <Button onClick={onClose} color="secondary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(AssignModal);
