import React from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../Generic/Table';
import Column from '../Generic/Column';

const LeadsTable = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Table {...props}>
      <Column label={t('Lead Name')} field="name" />
      <Column label={t('Company/Account')} field="partner_name" />
      <Column label={t('Contact name')} field="contact_name" />
      <Column label={t('Role')} field="role" />
      <Column label={t('Creation date')} field="creation_date" />
    </Table>
  );
};

export default React.memo(LeadsTable);
