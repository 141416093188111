import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/selectors/auth';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const ProductVariant = ({ options, onChange }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState(null);
  const [init, setInit] = React.useState(false);
  const user = useSelector(getUser);

  const countries = options
    .map(option => {
      return option.countries.map(country => {
        return {
          ...country,
          is_available: option.is_available,
          variant: option,
        };
      });
    })
    .flat();

  React.useEffect(() => {
    if (countries && selected === null && !init) {
      if (user && user.country_id) {
        const country = countries.filter(c => c.id === user.country_id);
        if (country.length > 0) {
          setSelected(country[0]);
          onChange(country[0]?.variant);
        }
      } else {
        if (countries && (selected === '' || selected === null)) {
          setSelected(countries.find(country => country.is_available));
          onChange(countries.find(country => country.is_available));
        }
      }

      setInit(true);
    }
  }, [selected, countries, setSelected, user, onChange]);

  React.useEffect(() => {
    if (selected) {
      if(selected.variant) {
        onChange(options.find((variant) => variant.id === selected.variant.id))
      } else {
        onChange(options.find((variant) => variant.id === selected.id))
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, options])

  return countries.length > 0 ? (
    <Autocomplete
      autoHighlight
      options={countries}
      getOptionLabel={option =>
        option.label ? (option.is_available ? option.label : `${option.label} (${t('Unavailable')})`) : ''
      }
      groupBy={option => option.variant.name}
      getOptionSelected={(option, value) => value.id && option.id === value.id}
      onChange={(event, value) => {
        console.log(value);
        setSelected(value);
      }}
      renderInput={params => <TextField {...params} margin="normal" variant="outlined" />}
      getOptionDisabled={option => !option.is_available}
      value={selected}
    />
  ) : (
    <Autocomplete
      autoHighlight
      options={options}
      getOptionLabel={option => (option.is_available ? option.name : `${option.name} (${t('Unavailable')})`)}
      // getOptionSelected={(option, value) => value.id && option.id === value.id}
      onChange={(event, value) => {
        setSelected(value);
        value && onChange(value);
      }}
      renderInput={params => <TextField {...params} margin="normal" variant="outlined" />}
      getOptionDisabled={option => !option.is_available}
      value={selected}
    />
  );
};

export default React.memo(ProductVariant);
