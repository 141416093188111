import Axios from 'axios';
import { SERVER_UNDER_MAINTENANCE } from '../../navigation/routes';
import api from './index';

let backendHost;

const hostname = window && window.location && window.location.hostname;

const backends = {
  'partner.lpi.org': 'https://odoo.lpi.org',
  'partners.lpi.org': 'https://odoo.lpi.org',
  'partners.preview.lpi.org': 'https://odoo.preview.lpi.org',
  'lpi-partners.metaserver.it': 'https://lpi.metaserver.it',
  'partners.i18n.preview.lpi.org': 'https://odoo.lpi.org',
};

if (hostname in backends) {
  backendHost = backends[hostname];
} else {
  backendHost = process.env.REACT_APP_BACKEND_HOST || 'https://lpi.metaserver.it';
}

if (window.odooBackendUrl && window.odooBackendUrl !== '' && window.odooBackendUrl !== '$ODOO_EXTERNAL_API_URL') {
  backendHost = window.odooBackendUrl;
}

export const HOST = backendHost;

const client = Axios.create({
  timeout: 10 * 1000,
});

client.interceptors.response.use(
  response => response,
  async error => {
    // If client is online but Odoo server is unreachable, redirect to the maintenance page
    if (navigator.onLine && !(await api.monitor.isServerUp())) {
      window.location.pathname = SERVER_UNDER_MAINTENANCE;
    }

    console.error(error);
    throw new Error(error);
  },
);

export default client;
