import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import api from '../../data/network';

export const QUERY_KEY = 'proctors';

/**
 * Get all available proctors
 */
const useProctors = () => {
  const req = useQuery([QUERY_KEY], () => api.proctors.search());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    proctors: req.data?.results || [],
    ...req,
  };
};

export default useProctors;
