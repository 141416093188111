import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Layout from '../../components/App/Layout';
import TabActions from '../../components/App/TabActions';
import Panel from '../../components/Common/Panel';
import Snackbar from '../../components/Common/Snackbar';
import RequestMaterialsForm from '../../components/Training/RequestMaterialForm/RequestMaterialForm';
import UploadStatusDialog from '../../components/Training/UploadStatusDialog/UploadStatusDialog';
import { createMaterial } from '../../store/reducers/MaterialsReducer';
import { setMessage } from '../../store/reducers/SnackReducer';
import { useUser } from '../../store/hooks/company';
import api from '../../data/network';
import { Box } from '@material-ui/core';
import TrainingMaterials from '../../components/Profile/TrainingMaterials';

const PAGES = ['Request materials', 'Settings'];

const useStyles = makeStyles(theme => ({
  tabs: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
}));

const TabPanel = ({ value, current, children }) => {
  return value === current && children;
};

const LearningMaterialsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  // const [openDialog, setOpenDialog] = useState(false);
  const profile = useUser();

  const handlePageChange = useCallback((_e, value) => setPage(value), [page]);
  // const handleCloseDialog = useCallback(() => setOpenDialog(false), [openDialog]);

  const handleRequestMaterialSubmit = useCallback(
    (data, actions) => {
      if (!data.topic) return dispatch(setMessage(t('Exam topic must be selected')));
      if (!data.language) return dispatch(setMessage(t('Language must be selected')));
      if (!data.image) return dispatch(setMessage(t('Image must be selected')));

      api.materialRequest
        .create({
          exam: data.topic,
          ...data,
        })
        .then(() => {
          dispatch(setMessage(t('Request submitted successfully')));
        })
        .catch(() => {
          dispatch(setMessage(t('An error occurred during the request')));
        })
        .finally(() => {
          actions.resetForm();
          // setOpenDialog(true);
        });
    },
    [dispatch, t, profile],
  );

  return (
    <>
      <Layout title={t('Learning materials')}>
        <TabActions value={page} options={PAGES} onChange={handlePageChange} />

        <TabPanel className={classes.table} value={0} current={page}>
          <Grid container spacing={3} justify="center">
            <Grid item xs={12} sm={8}>
              <Panel title={t('Request Co-Branded Materials')} subtitle={t('The following data is public')}>
                <RequestMaterialsForm onSubmit={handleRequestMaterialSubmit} />
              </Panel>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel className={classes.table} value={1} p current={page}>
          <Grid container spacing={3} justify="center">
            <Grid item xs={12} sm={8}>
              <Box>
                <Panel
                  title={t('Training materials')}
                  subtitle={t('Brandied materials for your teachers and students.')}
                >
                  <TrainingMaterials />
                </Panel>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
      </Layout>

      {/* <UploadStatusDialog open={openDialog} handleClose={handleCloseDialog} /> */}

      <Snackbar />
    </>
  );
};

export default React.memo(LearningMaterialsPage);
