import { useMutation, useQueryClient } from 'react-query';
import api from '../../data/network';
import { QUERY_KEY } from './useStudents';

const useStudentsMutations = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  /* Define mutation hooks */
  const importStudents = useMutation(payload => api.importStudents.create(payload), {
    onSuccess: () => {
      invalidateQuery();
    },
  });

  const createStudent = useMutation(payload => api.students.create(payload), {
    onSuccess: () => {
      invalidateQuery();
    },
  });

  const updateStudent = useMutation(payload => api.students.update(payload.id, payload), {
    onSuccess: data => {
      invalidateQuery();
      queryClient.invalidateQueries([QUERY_KEY, data.id]);
    },
  });

  const deleteStudent = useMutation(id => api.students.remove(id), {
    onSuccess: () => {
      invalidateQuery();
    },
  });

  const resendInvite = useMutation(id => api.resendInvite(id));

  return {
    resendInvite,
    importStudents,
    createStudent,
    updateStudent,
    deleteStudent,
  };
};

export default useStudentsMutations;
