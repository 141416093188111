import React from 'react';
import { useTranslation } from 'react-i18next';

import StatusChip from '../Common/StatusChip';

import Table from '../Generic/Table';
import Column from '../Generic/Column';

const CouponsTable = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Table {...props}>
      <Column
        label={t('Status')}
        field="state"
        render={value => {
          return <StatusChip status={value.toUpperCase()} />;
        }}
      />
      <Column label={t('Program')} field="program_name" />
      <Column label={t('Partner')} field="lpi_partner_name" />
      <Column label={t('Assigned To')} field="partner_name" />
      <Column label={t('Code')} field="code" />
      <Column label={t('Expiring Date')} field="expiration_date" />
    </Table>
  );
};

export default React.memo(CouponsTable);
