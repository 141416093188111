import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import usePlaces from '../../hooks/usePlaces';
import { Typography } from '@material-ui/core';

const CountryInput = ({ error, value, valueID, name, label, required, onChange, disabled, ...props }) => {
  const { places, status } = usePlaces();

  if (valueID && Array.isArray(places)) {
    value = places.find(country => country.id == valueID);
  }

  return (
    <Box mb={2} {...props}>
      <Autocomplete
        value={value || ''}
        name={name}
        options={places}
        loading={status === 'loading'}
        getOptionLabel={option => option?.name || ''}
        getOptionSelected={(option, value) => option.id === value.id}
        disabled={disabled}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            error={error}
            fullWidth
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            required={required}
          />
        )}
        onChange={onChange}
      />
      {error && <Typography color="error">{error}</Typography>}
    </Box>
  );
};

export default React.memo(CountryInput);
