import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { convertLead } from '../../store/reducers/LeadsReducer';

import Button from '@material-ui/core/Button';

const LeadsActions = ({ item }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleConvert = React.useCallback(
    item => {
      dispatch(convertLead(item));
    },
    [dispatch],
  );

  return (
    <>
      <Button onClick={() => handleConvert(item)} color="secondary">
        {t('Convert')}
      </Button>
    </>
  );
};

export default React.memo(LeadsActions);
