import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../data/network';

import { QUERY_KEY } from './useProctors';

/** Get a single proctor */
const useProctor = id => {
  const req = useQuery([QUERY_KEY, id], () => api.proctors.get(id));

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    proctor: req.data || {},
    ...req,
  };
};

export default useProctor;
