import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import api from '../data/network';

export const QUERY_KEY = 'languages.all';

/**
 * Get all available languages
 */
const useLanguages = () => {
  const req = useQuery([QUERY_KEY], () => api.languages.search());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    languages: req.data?.results || [],
    ...req,
  };
};

export default useLanguages;
