import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { Grid, Box } from '@material-ui/core';

import ImgPlaceholder from '../../../assets/images/img-placeholder.png';
import MaterialPreview from '../../../assets/images/material-preview.jpg';

const useStyles = makeStyles({
  image: {
    maxHeight: 120,
    maxWidth: 150,
  },
});

function Attachment({ value, onChange }) {
  const classes = useStyles();
  const inputFile = useRef();
  const { t } = useTranslation();
  const [src, setSrc] = useState();

  useEffect(() => {
    if (!value) {
      setSrc(ImgPlaceholder);
    } else {
      const reader = new FileReader();
      reader.onloadend = event => setSrc(event.target.result);
      reader.readAsDataURL(value);
    }
  }, [value]);

  const handleImageChange = () => {
    const reader = new FileReader();

    if (inputFile) {
      const file = inputFile?.current?.files?.[0];
      if (file?.size > 5 * 1000 * 1024) {
        alert(t('This file is too large max 5mb'));
      } else {
        onChange(inputFile.current.files[0]);
        reader.onloadend = event => setSrc(event.target.result);
        reader.readAsDataURL(file);
      }
    }
  };

  const handleChooseImage = () => inputFile?.current?.click();

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} sm={6} lg={4} justify="center" alignItems="center">
        <Grid item>
          <img src={src} alt={'Upload logo'} className={classes.image} />
        </Grid>
      </Grid>

      <Grid item container spacing={2} display="flex" direction="column" justify="space-around" xs={12} sm={6} lg={4}>
        <Grid item>
          <Button variant="contained" size="small" onClick={handleChooseImage} data-testid="image-button">
            {t('Choose a file')}
          </Button>
        </Grid>

        <Grid item>
          <Typography variant="caption">
            {t('Submit your logo to be placed in the material for Co-Branding')}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="caption">{t('Only accept pngs')}</Typography>
        </Grid>

        <Grid item>
          <Typography variant="caption">{t('Max file size 5mb')}</Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12} lg={4} justify="center" alignItems="center">
        <Box height={280} style={{ position: 'relative' }}>
          {inputFile?.current?.files?.[0] && (
            <>
              <img
                src={src}
                alt="logo preview"
                style={{ position: 'absolute', top: 25, left: 17, maxHeight: 48, maxWidth: 90, zIndex: 10 }}
              />
              <Box
                style={{
                  position: 'absolute',
                  height: 53,
                  width: 90,
                  background: 'white',
                  top: 21,
                  left: 17,
                  zIndex: 9,
                }}
              />
            </>
          )}
          <img
            src={MaterialPreview}
            alt="preview"
            style={{ height: 280, border: '1px solid #BDBDBD', borderRadius: 6, zIndex: 8 }}
          />
        </Box>
      </Grid>

      <input
        type="file"
        accept="image/png"
        ref={inputFile}
        onChange={handleImageChange}
        onClick={event => (event.target.value = '') /* reselect same file */}
        style={{ display: 'none' }}
      />
    </Grid>
  );
}

Attachment.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default Attachment;
