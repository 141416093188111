import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';

const AgreementsActions = ({ item }) => {
  const { t } = useTranslation();

  const handleDownloadClick = ev => {
    window.location.href = item.signatures;
    ev.preventDefault();
    return null;
  };

  const handleDownloadCertClick = ev => {
    window.location.href = item.certification;
    ev.preventDefault();
    return null;
  };

  return (
    <>
      {item.signatures && (
        <Button color="secondary" onClick={handleDownloadClick}>
          {t('Download Agreements')}
        </Button>
      )}
      {item.certification && (
        <Button color="secondary" onClick={handleDownloadCertClick}>
          {t('Download Certificate')}
        </Button>
      )}
    </>
  );
};

export default React.memo(AgreementsActions);
