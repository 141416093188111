import React from 'react';
import { useTranslation } from 'react-i18next';

import StatusChip from '../Common/StatusChip';

import Table from '../Generic/Table';
import Column from '../Generic/Column';
import { Box, Tooltip } from '@material-ui/core';
import dayjs from 'dayjs';

function formatDate(dateString) {
  if (!dateString) return '';
  return dayjs(dateString).format('MMM DD, YYYY');
}

const VouchersTable = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Table {...props}>
      <Column
        label={t('Status')}
        field="state"
        render={value => {
          return <StatusChip status={value?.toUpperCase()} />;
        }}
      />
      <Column label={t('Name')} field="product_id_name" />
      <Column
        label={t('Variant')}
        renderDepends={item =>
          item.countries && item.countries.length > 0 ? (
            <Tooltip title={item.countries.join(' - ')} aria-label="add">
              <Box>{item.variant_name}</Box>
            </Tooltip>
          ) : (
            <Box>{item.variant_name}</Box>
          )
        }
      />
      <Column label={t('Assigned To')} field="assigned_to_name" />
      <Column label={t('Code')} field="code" />
      <Column label={t('Expiring Date')} field="expiration_date" />
      <Column label={t('Order')} field="sale_order_id_name" />
      <Column label={t('Order Date')} field="sale_order_date" render={value => formatDate(value)} />
    </Table>
  );
};

export default React.memo(VouchersTable);
