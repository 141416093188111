import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItems } from '../../store/reducers/GenericReducer';
import { getOffers } from '../../store/selectors/offers';

import Select from '@material-ui/core/Select';
import Filters from '../Generic/Filters';

const CandidatesFilters = ({ ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const offers = useSelector(getOffers);

  React.useEffect(() => {
    dispatch(fetchItems(null, 'jobs'));
  }, [dispatch]);

  const options = {
    job_id: {
      label: t('Job Offer'),
      value: value => (value !== '' ? parseInt(value) : ''),
      chip: value => (offers[value] ? offers[value].name : ''),
    },
  };

  return (
    <Filters options={options} {...rest}>
      <Select native name="job_id" onChange={event => event.target.value}>
        <option value="" />
        {Object.values(offers).map(offer => (
          <option key={offer.id} value={offer.id}>
            {offer.name}
          </option>
        ))}
      </Select>
    </Filters>
  );
};

export default React.memo(CandidatesFilters);
