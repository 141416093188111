import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const CREATE_MATERIAL = 'Materials.Create';
export const FETCH_MATERIAL = 'Materials.Fetch';

export const createMaterial = createRoutine(CREATE_MATERIAL);
// export const fetchMaterials = createRoutine(FETCH_MATERIAL)

const INITIAL_REQUEST_STATE = {
  createMaterial: { loading: false, error: null },
  // fetchMaterials: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: [],
  requests: INITIAL_REQUEST_STATE,
};

const MaterialReducer = handleActions(
  {
    // createMaterial
    [createMaterial.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.createMaterial.loading = true;
        draft.requests.createMaterial.error = null;
      }),
    [createMaterial.SUCCESS]: state =>
      produce(state, draft => {
        draft.requests.createMaterial.loading = false;
      }),
    [createMaterial.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.createMaterial.loading = false;
        draft.requests.createMaterial.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default MaterialReducer;
