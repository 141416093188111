import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProctors, createProctor } from '../reducers/ProctorsReducer';
import { fetchStudents, createStudent, resendInvite } from '../reducers/StudentsReducer';
import { fetchTeachers, createTeacher } from '../reducers/TeachersReducer';
import { getProctorsList } from '../selectors/proctors';
import { getStudentsList } from '../selectors/students';
import { getTeachersList } from '../selectors/teachers';

const useStudents = () => {
  const dispatch = useDispatch();
  const students = useSelector(getStudentsList);
  const requests = useSelector(state => state.students.requests);

  React.useEffect(() => {
    dispatch(fetchStudents());
  }, [dispatch]);

  return {
    students,
    createStudent: values => dispatch(createStudent(values)),
    resendInvite: item => dispatch(resendInvite(item)),
    requests,
  };
};

const useAvailableStudents = () => {
  const dispatch = useDispatch();
  const students = useSelector(getStudentsList);

  const availableStudents = React.useMemo(() => students.filter(student => student.state !== 'pending'), [students]);

  React.useEffect(() => {
    dispatch(fetchStudents());
  }, [dispatch]);

  return {
    students: availableStudents,
  };
};

const useTeachers = () => {
  const dispatch = useDispatch();
  const teachers = useSelector(getTeachersList);
  const requests = useSelector(state => state.teachers.requests);

  React.useEffect(() => {
    dispatch(fetchTeachers());
  }, [dispatch]);

  return {
    teachers,
    createTeacher: values => dispatch(createTeacher(values)),
    // resendInvite: (item) => dispatch(resendInvite(item)),
    requests,
  };
};

const useProctors = () => {
  const dispatch = useDispatch();
  const proctors = useSelector(getProctorsList);
  const requests = useSelector(state => state.proctors.requests);

  React.useEffect(() => {
    dispatch(fetchProctors());
  }, [dispatch]);

  return {
    proctors,
    createProctor: values => dispatch(createProctor(values)),
    // resendInvite: (item) => dispatch(resendInvite(item)),
    requests,
  };
};

const useTrainingPartners = () => {
  const dispatch = useDispatch();

  const proctors = useSelector(getProctorsList);
  const students = useSelector(getStudentsList);
  const teachers = useSelector(getTeachersList);

  const [partners, setPartners] = React.useState([]);

  React.useEffect(() => {
    dispatch(fetchProctors());
    dispatch(fetchStudents());
    dispatch(fetchTeachers());
  }, [dispatch]);

  React.useEffect(() => {
    const proctorsList = proctors.map(p => ({
      ...p,
      _partner_type: 'Proctors',
    }));

    const teachersList = teachers.map(t => ({
      ...t,
      _partner_type: 'Teachers',
    }));

    const studentsList = students.map(s => ({
      ...s,
      _partner_type: 'Students',
    }));
    setPartners(proctorsList.concat(studentsList, teachersList));
  }, [proctors, students, teachers]);

  return { partners };
};

const useAvailableTrainingPartners = () => {
  const dispatch = useDispatch();

  const proctors = useSelector(getProctorsList);
  const students = useSelector(getStudentsList);
  const teachers = useSelector(getTeachersList);

  const [partners, setPartners] = React.useState([]);

  // const availableStudents = React.useMemo(() => students.filter(student => student.state !== 'pending'), [students]);

  React.useEffect(() => {
    dispatch(fetchProctors());
    dispatch(fetchStudents());
    dispatch(fetchTeachers());
  }, [dispatch]);

  React.useEffect(() => {
    const proctorsList = proctors.map(p => ({
      ...p,
      _partner_type: 'Proctors',
    }));

    const teachersList = teachers.map(t => ({
      ...t,
      _partner_type: 'Teachers',
    }));

    const studentsList = students.map(s => ({
      ...s,
      _partner_type: 'Students',
    }));
    setPartners(proctorsList.concat(studentsList, teachersList));
  }, [proctors, students, teachers]);

  return { partners };
};

export {
  useStudents,
  useTeachers,
  useProctors,
  useTrainingPartners,
  useAvailableStudents,
  useAvailableTrainingPartners,
};
