import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../data/network';

export const QUERY_KEY = 'jobs.skills.all';

/**
 * Get all available skills
 */
const useSkills = () => {
  const req = useQuery([QUERY_KEY], () => api.jobs.skills());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    skills: req.data || [],
    ...req,
  };
};

export default useSkills;
