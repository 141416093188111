import React from 'react';
import TextInput from './TextInput';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const LinkInput = ({ ...props }) => {
  const handleClick = event => {
    window.open(props.value, '_blank');
    event.preventDefault();
    return null;
  };

  return (
    <Box mb={2}>
      <TextInput
        {...props}
        InputProps={{
          ...props.InputProps,
          endAdornment: props.value && (
            <InputAdornment position="end">
              <IconButton onClick={handleClick} onMouseDown={event => event.preventDefault()}>
                <OpenInNewIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default React.memo(LinkInput);
