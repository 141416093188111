import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/selectors/auth';
import * as routes from '../../navigation/routes';

const PrivateRoute = props => {
  const user = useSelector(getUser);
  return user === null ? <Redirect to={`${routes.LOGIN}?next=${props.path}`} /> : <Route {...props} />;
};

export default React.memo(PrivateRoute);
