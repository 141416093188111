import { useMutation, useQueryClient } from 'react-query';
import api from '../../data/network';

import { QUERY_KEY } from './usePartnerOffers';

const usePartnerOffersMutations = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  /* Define mutation hooks */
  const createOffer = useMutation(({ payload }) => api.jobs.create(payload), {
    onSuccess: () => {
      invalidateQuery();
    },
  });
  const updateOffer = useMutation(({ id, payload }) => api.jobs.update(id, payload), {
    onSuccess: () => {
      invalidateQuery();
    },
  });
  const deleteOffer = useMutation(({ id }) => api.jobs.remove(id), {
    onSuccess: invalidateQuery,
  });
  const withdrawOffer = useMutation(({ id }) => api.jobs.withdraw(id), {
    onSuccess: invalidateQuery,
  });
  const toggleOfferStatus = useMutation(({ id }) => api.jobs.toggleStatus(id), {
    onSuccess: invalidateQuery,
  });

  return {
    createOffer,
    updateOffer,
    deleteOffer,
    withdrawOffer,
    toggleOfferStatus,
  };
};

export default usePartnerOffersMutations;
