import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Field from '../Common/Fields';
import Detail from '../Generic/Detail';

const CandidatesDetail = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Detail readonly typeName={t('candidate')} titleField="name" {...props}>
      <Field.Text label={t('Job Offer')} field="job_name" />
      <Field.Paragraph label={t('About')} field="about_applicant" />
      <Field.Text label={t('Email')} field="email" />
      <Field.Text label={t('Phone')} field="phone" />
      <Field.Link label={t('Resume Link')} field="resume_link" linkLabel="Website" target="_blank" />
      <Field.Link label={t('Resume')} field="link_download_resume" linkLabel="Download resume" />
    </Detail>
  );
};

export default React.memo(CandidatesDetail);
