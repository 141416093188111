import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../data/network';

export const QUERY_KEY = 'jobs.offers.single';

const usePartnerOffer = id => {
  const req = useQuery([QUERY_KEY, id], () => api.jobs.get(id), { enabled: !!id });

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    offer: req.data || null,
    ...req,
  };
};

export default usePartnerOffer;
