import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useJobOffersProducts } from '../../../store/hooks/shop';
import { JOB_PRODUCT_LIST } from '../../../navigation/routes';

import Layout from '../../../components/App/Layout';
import ProductDetail from '../../../components/Shop/ProductDetail';

const JobProductPage = () => {
  const { t } = useTranslation();
  const { productId } = useParams();

  const [product, setProduct] = React.useState();
  const products = useJobOffersProducts();

  React.useEffect(() => {
    setProduct(products[productId]);
  }, [products, productId]);

  return (
    <Layout title={product ? product.name : t('Product Detail')} back={JOB_PRODUCT_LIST} backPath={JOB_PRODUCT_LIST}>
      <ProductDetail product={product} loading={false} />
    </Layout>
  );
};

export default React.memo(JobProductPage);
