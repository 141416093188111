import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

// Action Creators
export const LEAD_CONVERT = 'Leads.Convert';
export const convertLead = createRoutine(LEAD_CONVERT);

// Initial State
const INITIAL_REQUEST_STATE = {
  convert: { loading: false, error: null },
};

const INITIAL_STATE = {
  requests: INITIAL_REQUEST_STATE,
};

// Reducer
const leadsReducer = handleActions(
  {
    [convertLead.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.loading = true;
        draft.requests.error = null;
      }),
    [convertLead.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.loading = false;
      }),
    [convertLead.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.loading = false;
        draft.requests.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default leadsReducer;
