import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchStudents, createStudent, importStudents, resendInvite } from '../reducers/StudentsReducer';
import { fetchItems } from '../reducers/GenericReducer';
import { createCoupon } from '../reducers/CouponsReducer';
import { setMessage } from '../reducers/SnackReducer';
import i18n from '../../locales';
import api from '../../data/network';

function* fetchStudentsSaga({ payload }) {
  try {
    const params = { offset: 0, limit: 9999 };
    const students = yield call([api.students, api.students.search], params);
    yield put(fetchStudents.success(students));
  } catch (e) {
    console.error(e);
    yield put(fetchStudents.failure(i18n.t('Unable to fetch students')));
  }
}

function* createStudentSaga({ payload }) {
  var coupon_program = null;
  var data = { ...payload };

  try {
    if (data.coupon_program) {
      coupon_program = data.coupon_program;
      delete data.coupon_program;
    }
    const student = yield call([api.students, api.students.create], data);

    yield put(createStudent.success(student, 'students'));
    yield put(setMessage(i18n.t('New student created')));

    if (coupon_program) {
      yield put(
        createCoupon({
          program: coupon_program,
          partner: student,
          count: 1,
        }),
      );
    }
  } catch (e) {
    console.error(e);
    yield put(setMessage(i18n.t('Student already registered')));
    yield put(createStudent.failure(i18n.t('Unable to create student')));
  }
}

function* importStudentsSaga({ payload }) {
  try {
    yield call([api.importStudents, api.importStudents.create], payload);
    yield put(importStudents.success());
    yield put(setMessage(i18n.t('New Students imported')));
    yield put(fetchItems(null, 'students'));
  } catch (e) {
    console.error(e);
    yield put(importStudents.failure(i18n.t('Unable to import students')));
  }
}

function* resendInviteSaga({ payload }) {
  try {
    yield call([api, api.resendInvite], payload.id);
    yield put(resendInvite.success());
    yield put(setMessage(i18n.t('Invite resent')));
  } catch (e) {
    console.error(e);
    yield put(resendInvite.failure(i18n.t('Unable to import students')));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchStudents.TRIGGER], fetchStudentsSaga)]);
  yield all([yield takeLatest([createStudent.TRIGGER], createStudentSaga)]);
  yield all([yield takeLatest([importStudents.TRIGGER], importStudentsSaga)]);
  yield all([yield takeLatest([resendInvite.TRIGGER], resendInviteSaga)]);
}
