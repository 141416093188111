import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import api from '../data/network';

export const QUERY_KEY = 'location.all';

/**
 * Get all the topics with find_portal = true
 */
const useTopics = () => {
  const req = useQuery([QUERY_KEY], api.topics.search, { retry: false, refetchOnWindowFocus: false });

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    topics: req.data?.results || [],
    ...req,
  };
};

export default useTopics;
