import React from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  TextField,
  Chip,
  Box,
  IconButton,
  Button,
  Tooltip,
  DialogContent,
  Select,
  DialogActions,
  Dialog,
  DialogTitle,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import usePartnerTags from '../../../hooks/PartnerTags/usePartnerTags';
import usePartnerTagsMutations from '../../../hooks/PartnerTags/usePartnerTagsMutations';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../store/reducers/SnackReducer';

const filter = createFilterOptions();

const PartnerTagsAutocomplete = ({ value, onChange, loading, allowDelete = true }) => {
  const { t } = useTranslation();
  const [dialog, setDialog] = React.useState({ open: false, title: null, content: null });
  const { tags, status } = usePartnerTags();
  const { createTag } = usePartnerTagsMutations();

  const handleChange = (event, newValues) => {
    // Create new tags before send the event back
    Promise.all(
      newValues.map(async tag => {
        if (!!tag.inputValue) {
          const x = await createTag.mutateAsync({ name: tag.inputValue });
          console.log(x);
          return x;
        } else {
          return tag;
        }
      }),
    ).then(newValues => onChange(event, newValues));
  };

  return (
    <Box display="flex" alignItems="center">
      <Autocomplete
        multiple
        fullWidth
        freeSolo
        options={tags}
        loading={typeof loading === 'undefined' ? status !== 'success' : status !== 'success' && loading}
        getOptionLabel={option => option.name || ''}
        renderInput={params => <TextField {...params} label={t('Tags')} variant="outlined" fullWidth />}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => <Chip key={option.id} label={option.name} {...getTagProps({ index })} />)
        }
        getOptionSelected={(option, value) => option.id === value.id}
        style={{ marginRight: 3 }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              id: -1,
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}" tag`,
            });
          }

          return filtered;
        }}
        value={value}
        onChange={handleChange}
      />
      {!!allowDelete && (
        <Tooltip title={t('Delete tags from list')}>
          <IconButton
            onClick={() =>
              setDialog({
                open: true,
                content: <DeleteTagDialog tags={tags} onClose={() => setDialog({ ...dialog, open: false })} />,
              })
            }
          >
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>
      )}
      <Dialog open={dialog.open} onClose={() => setDialog({ ...dialog, open: false })}>
        {dialog.content}
      </Dialog>
    </Box>
  );
};

const DeleteTagDialog = ({ onClose, tags }) => {
  const { t } = useTranslation();
  const [selectedTagId, setSelectedTagId] = React.useState(tags?.[0]?.id || null);
  const { deleteTag } = usePartnerTagsMutations();
  const dispatch = useDispatch();

  const handleDeleteTag = () => {
    if (tags?.length > 0)
      deleteTag
        .mutateAsync({ id: selectedTagId })
        .then(() => {
          dispatch(setMessage(t('Tag deleted successfully')));
        })
        .catch(() => {
          dispatch(setMessage(t('An error occurred during the request')));
        })
        .finally(onClose);
  };

  return (
    <>
      <DialogTitle>{t('Delete tag')}</DialogTitle>
      <DialogContent style={{ minWidth: 300 }}>
        <Select
          fullWidth
          variant="outlined"
          value={selectedTagId || tags?.[0]?.id || ''}
          onChange={event => setSelectedTagId(event.target.value)}
        >
          {tags.map(tag => (
            <MenuItem key={tag.id} value={tag.id}>
              {tag.name}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Cancel')}</Button>
        <Button onClick={handleDeleteTag} disabled={deleteTag.status === 'loading'}>
          {deleteTag.status === 'loading' ? <CircularProgress size={20} /> : t('Delete')}
        </Button>
      </DialogActions>
    </>
  );
};

export default PartnerTagsAutocomplete;
