import React from 'react';
import Button from '@material-ui/core/Button';

const BackLink = ({ label, to }) => {
  return (
    <Button href={to} disableElevation fullWidth color="primary" variant="contained" size="large">
      {label}
    </Button>
  );
};

export default React.memo(BackLink);
