import React from 'react';
import { useDispatch } from 'react-redux';
import { updateItem, fetchItems } from '../../store/reducers/GenericReducer';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { Box, Button, Menu } from '@material-ui/core';
import useOpportunitiesMutations from '../../hooks/Opportunities/useOpportunitiesMutations';
import { useTranslation } from 'react-i18next';
import { setMessage } from '../../store/reducers/SnackReducer';
import RequestAgreementFormDialog from './RequestAgreementForm';

const NEW_STAGE_ID = 1;
const QUALIFICATION_STAGE_ID = 2;
const REQUEST_AGREEMENT_STAGE_ID = 9;
const WON_STAGE_ID = 4;

function hasQualificationErrors(item) {
  if (!item.contact_name) {
    return 'Company name is required';
  }

  if (!item.street) {
    return 'Company address is required';
  }

  if (!item.city) {
    return 'Company city is required';
  }

  if (!item.country_id) {
    return 'Company country is required';
  }

  if (!item.signer_name) {
    return 'Signer name is required';
  }

  if (!item.signer_email) {
    return 'Signer email is required';
  }

  return false;
}

const OpportunitiesActions = ({ item }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = !!anchorEl;
  const dispatch = useDispatch();

  const [isRequestAgreementOpen, setIsRequestAgreementOpen] = React.useState(false);

  const { setLost } = useOpportunitiesMutations();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStage = React.useCallback(
    (item, value) => {
      if (value === REQUEST_AGREEMENT_STAGE_ID) {
        const error = hasQualificationErrors(item);
        if (error) {
          dispatch(setMessage(`${t('Opportunity must be qualified to request agreement')}: ${error}`));
          return;
        }
      }

      dispatch(
        updateItem({
          id: item.id,
          stage_id: parseInt(value),
        }),
      );
      handleClose();
    },
    [dispatch],
  );

  const handleLost = React.useCallback(item => {
    setLost.mutate(item.id, {
      onSuccess: () => {
        dispatch(fetchItems('opportunities'));
        dispatch(setMessage(t('Opportunity marked as lost')));
        handleClose();
      },
      onError: () => {
        dispatch(setMessage(t('Error marking opportunity as lost')));
        handleClose();
      },
    });
  }, []);

  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Actions
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {item.stage_id === NEW_STAGE_ID && (
          <MenuItem onClick={() => handleStage(item, QUALIFICATION_STAGE_ID)}>Qualified</MenuItem>
        )}
        {(item.stage_id === NEW_STAGE_ID || item.stage_id === QUALIFICATION_STAGE_ID) && (
          <MenuItem onClick={() => setIsRequestAgreementOpen(true)}>Request Agreement be Sent</MenuItem>
        )}

        {item.stage_id !== NEW_STAGE_ID &&
          item.stage_id !== QUALIFICATION_STAGE_ID &&
          item.stage_id !== WON_STAGE_ID && <MenuItem onClick={() => handleLost(item)}>Mark as Lost</MenuItem>}
      </Menu>

      {item.stage_id === NEW_STAGE_ID && (
        <Button onClick={() => handleStage(item, QUALIFICATION_STAGE_ID)}>Mark as Qualified</Button>
      )}

      <RequestAgreementFormDialog
        opportunity={item}
        isOpen={isRequestAgreementOpen}
        onClose={() => setIsRequestAgreementOpen(false)}
      />
    </Box>
  );
};

export default React.memo(OpportunitiesActions);
