import React, { useCallback, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form as FormikForm, useFormikContext } from 'formik';
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  CircularProgress,
  Grid,
  Box,
  Typography,
  Paper,
  Container,
  Tooltip,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useSelector } from 'react-redux';

import { getProfile } from '../../../store/selectors/auth';
import { JOBS_LIST } from '../../../navigation/routes';
import usePartnerOffer from '../../../hooks/Jobs/usePartnerOffer';
import usePartnerOffersMutations from '../../../hooks/Jobs/usePartnerOffersMutations';
import { setMessage } from '../../../store/reducers/SnackReducer';

import Layout from '../../../components/App/Layout';
import Snackbar from '../../../components/Common/Snackbar';
import CertificationsInput from '../../../components/Form/CertificationsInput';
import CountryInput from '../../../components/Form/CountryInput';
import StateInput from '../../../components/Form/StateInput';
import Checkbox from '../../../components/Form/Checkbox';
import DateInput from '../../../components/Form/DateInput';
import SelectInput from '../../../components/Form/SelectInput';
import SkillsInput from '../../../components/Form/SkillsInput';
import TextareaInput from '../../../components/Form/TextareaInput';
import TextInput from '../../../components/Form/TextInput';
import { JOB_TYPES } from '../../../common';
import { getTranslatedJobTypes } from '../../../utils/getTranslatedEnum';
import usePlaces from '../../../hooks/usePlaces';
import LanguagesInput from '../../../components/Form/LanguagesInput';

const getInitForm = (offer, profile, places, states) => {
  return {
    id: offer ? offer.id : null,
    certificates: offer ? offer.certificates : [],
    code: offer ? offer.code : '',
    description: offer ? offer.description : '',
    inplace: offer ? offer.inplace : false,
    job_end_date: offer ? offer.job_end_date : '',
    job_start_date: offer ? offer.job_start_date : '',
    country: offer ? offer.country : places.find(country => country.id === profile.public_country_id) || null,
    state: offer ? offer.state : states.find(state => state.id === profile.public_state_id) || null,
    city: offer ? offer.city : profile.public_city || '',
    name: offer ? offer.name : '',
    organization: offer ? offer.organization : profile.name || '',
    organization_address: offer ? offer.organization_address : profile.public_street || '',
    organization_email: offer ? offer.organization_email : profile.public_email || '',
    organization_website: offer ? offer.organization_website : profile.website || '',
    publish_date: offer ? offer.publish_date : '',
    close_date: offer ? offer.close_date : '',
    salary: offer ? offer.salary : '',
    remote: offer ? offer.remote : false,
    remote_international: offer ? offer.remote_international : false,
    skills: offer ? offer.skills : [],
    contract_type: offer ? offer.contract_type : '',
    external_url: offer ? offer.external_url : '',
  };
};

const Tooltipped = memo(({ children, title, cut = false, ...props }) => {
  const profile = useSelector(getProfile);

  return (
    <Box position="relative" {...(cut && { style: { width: 'fit-content' } })} {...props}>
      <Box
        style={{
          backgroundColor: '#fff',
          borderRadius: '50%',
          position: 'absolute',
          top: cut ? 0 : -8,
          right: -9,
          zIndex: 1,
        }}
      >
        <Tooltip title={title}>
          <HelpOutlineIcon color="secondary" style={{ height: 20, width: 20 }} />
        </Tooltip>
      </Box>
      {children}
    </Box>
  );
});

const combineStatuses = (...statuses) => {
  if (statuses.some(status => status === 'error')) return 'error';
  if (statuses.some(status => status === 'idle')) return 'idle';
  if (statuses.some(status => status === 'loading')) return 'loading';
  if (statuses.every(status => status === 'success')) return 'success';
};

const JobOfferPage = () => {
  const history = useHistory();
  const { createOffer, updateOffer, toggleOfferStatus } = usePartnerOffersMutations();
  const [activeStep, setActiveStep] = useState(0);
  const profile = useSelector(getProfile);
  const { places, states, status: placesStatus } = usePlaces();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { path } = useRouteMatch();
  const detail_route_match = useRouteMatch({
    path: path + '/:offerId/',
  });
  const duplicate = new URLSearchParams(useLocation().search).get('duplicate') || false;

  const offerId = detail_route_match?.params?.offerId;
  const { offer: offerData, status: offerStatus } = usePartnerOffer(offerId);
  const offer = offerId ? offerData : null;
  const status = offerId ? combineStatuses(offerStatus, placesStatus) : placesStatus;
  const isNewOffer = duplicate || !offer;

  const isLoading = useMemo(() => createOffer.status === 'loading' || updateOffer.status === 'loading', [
    createOffer.status,
    updateOffer.status,
  ]);

  const steps = useMemo(
    () => [
      t('Title and description'),
      t('Job details'),
      t('Job dates'),
      t('Location'),
      t('Publication details'),
      t('Publication dates'),
    ],
    [],
  );

  const jobTypes = useMemo(
    () => [
      { id: '', name: '' },
      ...Object.values(JOB_TYPES).map(value => ({ id: value, name: getTranslatedJobTypes(value, t) })),
    ],
    [],
  );

  const getStepContent = useCallback((step, getFormProps) => {
    switch (step) {
      case 0: // Title
        return (
          <>
            <Grid item xs={12} sm={12}>
              <Tooltipped title={t('customtext/job/title')}>
                <TextInput label={t('Title')} {...getFormProps('name')} required />
              </Tooltipped>

              <Tooltipped title={t('customtext/job/description')}>
                <TextareaInput label={t('Description')} {...getFormProps('description')} required />
              </Tooltipped>
            </Grid>
          </>
        );

      case 1: // Job details
        return (
          <>
            <Grid item xs={12} sm={6}>
              <Tooltipped title={t('customtext/job/employment_type')}>
                <SelectInput label={t('Employment type')} {...getFormProps('contract_type')} options={jobTypes} />
              </Tooltipped>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Tooltipped title={t('customtext/job/salary')}>
                <TextInput label={t('Salary')} {...getFormProps('salary')} placeholder="50k - 100k USD/year" />
              </Tooltipped>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Tooltipped title={t('customtext/job/skills')}>
                <SkillsInput label={t('Skills')} {...getFormProps('skills')} />
              </Tooltipped>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Tooltipped title={t('customtext/job/languages')}>
                <LanguagesInput label={t('Languages')} {...getFormProps('languages')} />
              </Tooltipped>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Tooltipped title={t('customtext/job/certifications')}>
                <CertificationsInput label={t('Certifications')} {...getFormProps('certificates')} />
              </Tooltipped>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Tooltipped title={t('customtext/job/url_to_full_post')}>
                <TextInput label={t('URL to full post')} {...getFormProps('external_url')} />
              </Tooltipped>
            </Grid>
          </>
        );

      case 2: // Job dates
        return (
          <>
            <Grid item xs={12} sm={6}>
              <Tooltipped title={t('customtext/job/start_date')}>
                <DateInput label={t('Start date')} {...getFormProps('job_start_date')} />
              </Tooltipped>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Tooltipped title={t('customtext/job/end_date')}>
                <DateInput label={t('End date')} {...getFormProps('job_end_date')} />
              </Tooltipped>
            </Grid>
          </>
        );

      case 3: // Location
        return (
          <>
            <Grid item xs={12} sm={4}>
              <Tooltipped title={t('customtext/job/country')}>
                <CountryInput
                  label={t('Country')}
                  {...(() => {
                    const { value, setFieldValue } = getFormProps('country');

                    return {
                      value,
                      onChange: (_e, value) => setFieldValue('country', value),
                    };
                  })()}
                />
              </Tooltipped>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Tooltipped title={t('customtext/job/state')}>
                <StateInput
                  label={t('State')}
                  {...(() => {
                    const { value, setFieldValue } = getFormProps('state');

                    return {
                      value,
                      onChange: (_e, value) => setFieldValue('state', value),
                    };
                  })()}
                />
              </Tooltipped>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Tooltipped title={t('customtext/job/city')}>
                <TextInput label={t('City')} {...getFormProps('city')} />
              </Tooltipped>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Tooltipped title={t('customtext/job/remote')} cut>
                <Checkbox mb={0} label={t('Remote')} {...getFormProps('remote')} />
              </Tooltipped>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Tooltipped title={t('customtext/job/remote_international')} cut>
                <Checkbox mb={0} label={t('Remote International')} {...getFormProps('remote_international')} />
              </Tooltipped>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Tooltipped title={t('customtext/job/in_office')} cut>
                <Checkbox mb={0} label={t('In Office')} {...getFormProps('inplace')} />
              </Tooltipped>
            </Grid>
          </>
        );

      case 4: // Publication details
        return (
          <>
            <Grid item xs={12} sm={12}>
              <Tooltipped title={t('customtext/job/organization_name')}>
                <TextInput label={t('Organization name')} {...getFormProps('organization')} />
              </Tooltipped>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Tooltipped title={t('customtext/job/organization_address')}>
                <TextInput label={t('Organization address')} {...getFormProps('organization_address')} />
              </Tooltipped>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Tooltipped title={t('customtext/job/organization_email')}>
                <TextInput label={t('Organization email')} {...getFormProps('organization_email')} />
              </Tooltipped>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Tooltipped title={t('customtext/job/organization_website')}>
                <TextInput label={t('Organization website')} {...getFormProps('organization_website')} />
              </Tooltipped>
            </Grid>
          </>
        );

      case 5: // Publication dates
        return (
          <>
            <Grid item xs={12} sm={6}>
              <Tooltipped title={t('customtext/job/publish_start_date')}>
                <DateInput label={t('Publish start date')} {...getFormProps('publish_date')} />
              </Tooltipped>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Tooltipped title={t('customtext/job/publish_end_date')}>
                <DateInput label={t('Publish end Date')} {...getFormProps('close_date')} />
              </Tooltipped>
            </Grid>
          </>
        );
    }
  }, []);

  const validate = useCallback(values => {
    const errors = {};
    if (!values.name) errors['name'] = t('This field is required');

    if (!values.description) {
      errors['description'] = t('This field is required');
    }
    return errors;
  }, []);

  /* Display a feedback if there are errors in some field and redirect user to the first step */
  const preSubmitChecks = (values, handleSubmit, submitForReview = false) => {
    if (Object.keys(validate(values)).length) {
      dispatch(setMessage(t('Fill in the required fields before submitting your request')));
      setActiveStep(0);
    }

    if (submitForReview) {
      values.submitForReview = true;
    }

    handleSubmit();
  };

  /* Transform data before submitting */
  const postSubmitChecks = async values => {
    const valuesClone = { ...values };

    // Delete id if this form is generated from an other offer
    if (isNewOffer && !!valuesClone.id) delete valuesClone.id;

    /*
    POST
    country_id = number
    state_id = number
    certificates = number[]
    skills = string[]

    GET
    country = {id, name}
    state = {id, name}
    certificates = {id, name}[]
    skills = {id, name}[]
    */

    if (!!valuesClone.certificates?.length) {
      valuesClone.certificates = valuesClone.certificates.map(option => option.id);
    }

    if (!!valuesClone.skills?.length) {
      valuesClone.skills = valuesClone.skills.map(option => option.name);
    }

    if (!!valuesClone.languages?.length) {
      valuesClone.languages = valuesClone.languages.map(option => option.id);
    }

    valuesClone.country_id = valuesClone.country?.id || null;
    valuesClone.state_id = valuesClone.state?.id || null;

    if (valuesClone.country) delete valuesClone.country;
    if (valuesClone.state) delete valuesClone.state;

    // Converts all '' to null
    for (const key in valuesClone) {
      if (typeof valuesClone[key] !== 'boolean' && !valuesClone[key]) {
        valuesClone[key] = null;
      }
    }

    let submitForReview = values.submitForReview;
    if (submitForReview) {
      if (profile.credit_job < 1) {
        dispatch(
          setMessage(
            t(
              'There are no Job Credits in your wallet. Please contact your CSM through the Support button in the sidebar.',
            ),
          ),
        );
        return;
      }
      delete values.submitForReview;
    }

    try {
      let _offer;
      if (isNewOffer) {
        _offer = await createOffer.mutateAsync({ payload: valuesClone });
      } else {
        _offer = await updateOffer.mutateAsync({ id: offer.id, payload: valuesClone });
      }

      if (submitForReview) {
        await toggleOfferStatus.mutateAsync({ id: _offer.id });

        dispatch(setMessage(t('Job offer submitted for review')));
      } else {
        dispatch(setMessage(!isNewOffer ? t('Job offer updated') : t('Job offer created')));
      }

      history.push(JOBS_LIST);
    } catch (error) {
      console.error(error);
      dispatch(setMessage(t('An error occurred during the request. Try again')));
    }
  };

  return (
    <Layout
      title={status === 'loading' ? t('Loading...') : !isNewOffer ? t('Edit offer') : t('Create offer')}
      back={true}
      backPath={JOBS_LIST}
    >
      <Container maxWidth="lg">
        {(() => {
          switch (status) {
            case 'idle':
            case 'loading':
              return (
                <Box display="flex" height="80vh" width="100%" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              );
            case 'error':
              return (
                <Box display="flex" height="80vh" width="100%" justifyContent="center" alignItems="center">
                  <Typography>{t('An error occurred during the request. Try again')}</Typography>
                </Box>
              );
            case 'success':
              return (
                <Paper style={{ padding: 10, margin: 20, marginTop: 40 }} elevation={0}>
                  <Formik
                    initialValues={getInitForm(offer, profile, places, states)}
                    onSubmit={postSubmitChecks}
                    validate={validate}
                    validateOnBlur
                  >
                    {({ handleSubmit, handleChange, errors, touched, values, handleBlur, setFieldValue }) => (
                      <FormikForm>
                        <Stepper activeStep={activeStep} orientation="vertical">
                          {steps.map((label, index) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                              <StepContent>
                                <Box my={2}>
                                  <Grid container spacing={1}>
                                    {getStepContent(index, function getFormProps(label) {
                                      return {
                                        name: label,
                                        error: touched[label] && errors[label],
                                        onChange: handleChange(label),
                                        onBlur: handleBlur,
                                        value: values[label],
                                        setFieldValue,
                                      };
                                    })}
                                  </Grid>
                                </Box>
                              </StepContent>
                            </Step>
                          ))}
                          <Box display="flex" justifyContent="flex-end">
                            <Button onClick={() => history.push(JOBS_LIST)} color="default">
                              {t('Cancel')}
                            </Button>

                            <Button
                              disabled={activeStep === 0}
                              onClick={() => setActiveStep(prevActiveStep => prevActiveStep - 1)}
                            >
                              {t('Back')}
                            </Button>

                            {activeStep !== steps.length - 1 && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setActiveStep(prevActiveStep => prevActiveStep + 1)}
                              >
                                {t('Next')}
                              </Button>
                            )}

                            {(() => {
                              if (activeStep === steps.length - 1) {
                                if (isLoading) {
                                  return (
                                    <Box mx={5}>
                                      <CircularProgress size={20} color="primary" />;
                                    </Box>
                                  );
                                }

                                if (isNewOffer) {
                                  return (
                                    <>
                                      <Button onClick={() => preSubmitChecks(values, handleSubmit)}>
                                        {t('Save as draft')}
                                      </Button>

                                      <Button
                                        variant="contained"
                                        onClick={() => preSubmitChecks(values, handleSubmit, true)}
                                        color="primary"
                                      >
                                        {t('Save and submit for review')}
                                      </Button>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <Button onClick={() => preSubmitChecks(values, handleSubmit)}>
                                        {t('Update draft')}
                                      </Button>

                                      <Button
                                        variant="contained"
                                        onClick={() => preSubmitChecks(values, handleSubmit, true)}
                                        color="primary"
                                      >
                                        {t('Update and submit for review')}
                                      </Button>
                                    </>
                                  );
                                }
                              }
                            })()}
                          </Box>
                        </Stepper>
                      </FormikForm>
                    )}
                  </Formik>
                </Paper>
              );
          }
        })()}
      </Container>
      <Snackbar />
    </Layout>
  );
};

export default JobOfferPage;
