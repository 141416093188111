import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../data/network';

const QUERY_KEY = 'support topic';

/** Get a single location */
const useHelpdeskTopics = id => {
  const req = useQuery([QUERY_KEY, id], () => api.supportTopics.search());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);
  console.log(req.data);
  return {
    topics: req.data?.results || [],
    ...req,
  };
};

export default useHelpdeskTopics;
