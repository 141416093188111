import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, styled, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const TotalBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '8px',
  paddingBottom: '8px',
});

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  item: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  qtyInput: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: 10,
  },

  qtyButton: {
    backgroundColor: '#ededed',
  },

  couponButton: {
    marginLeft: theme.spacing(2),
  },

  cartImage: {
    width: 80,
    borderRadius: 10,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: 60,
    },
  },
}));

export const CartItem = ({ item }) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection={matches ? 'row' : 'column'}
      className={styles.item}
    >
      <Box display="flex">
        <Box>{item.image && <img className={styles.cartImage} src={item.image} alt={item.product} />}</Box>
        <Box>
          <Typography variant="subtitle2">{item.product}</Typography>
          <Typography variant="caption">{item.variant}</Typography>
        </Box>
      </Box>

      <Box textAlign="right">
        <Typography variant="subtitle2">${item.amount.total}</Typography>
      </Box>
    </Box>
  );
};

const SaleOrderCart = ({ order }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return order ? (
    <Box className={styles.container}>
      {order.lines.items.map(item => (
        <CartItem key={item.id} item={item} />
      ))}
      <Divider />
      <TotalBox>
        <Typography variant="subtitle2">{t('Subtotal')}</Typography>
        <Typography variant="subtitle2">${order.amount.untaxed}</Typography>
      </TotalBox>
      <TotalBox>
        <Typography variant="subtitle2">{t('Tax')}</Typography>
        <Typography variant="subtitle2">${order.amount.tax}</Typography>
      </TotalBox>
      <Divider />
      <TotalBox>
        <Typography variant="subtitle2">{t('Total')}</Typography>
        <Typography variant="subtitle2">USD ${order.amount.total}</Typography>
      </TotalBox>
    </Box>
  ) : null;
};

export default SaleOrderCart;
