import { takeLatest, put, all, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { init, login, update, logout, stats, updatePublic } from '../reducers/AuthReducer';
import i18n from '../../locales';
import { setMessage } from '../reducers/SnackReducer';
import api from '../../data/network';
import { HOME } from '../../navigation/routes';

function* initSaga() {
  try {
    const user = yield call([api, api.userInfo]);
    const profile = yield call([api, api.getProfile]);
    yield put(init.success({ user, profile }));
  } catch (e) {
    yield put(init.failure());
  }
}

function* loginSaga({ payload }) {
  // codice aync
  try {
    const { username, password, next } = payload;

    // effettua la chiamata di rete per il login
    // const user = {name: "sara", password: "sara"}
    const user = yield call([api, api.login], username, password);
    const profile = yield call([api, api.getProfile]);
    const trainingCenters = yield call([api.trainingCenter, api.trainingCenter.search]);

    // manda la action success
    yield put(login.success({ user, profile, trainingCenters }));

    // manda l'utente in home
    yield put(push(next || HOME));
  } catch (e) {
    // manda la action failure
    yield put(login.failure(i18n.t('Username or password invalid')));
  }
}

function* statsSaga() {
  try {
    const data = yield call([api, api.fetchStats]);
    yield put(stats.success(data));
  } catch (e) {
    console.error(e);
  }
}

function* logoutSaga() {
  yield put(push(HOME));
  localStorage.clear();
}

function* updateProfileSaga({ payload }) {
  try {
    const profile = yield call([api, api.updateProfile], payload);
    yield put(update.success({ profile }));
    yield put(setMessage(i18n.t('Profile Saved')));
  } catch (e) {
    yield put(update.failure(i18n.t('Unable to update profile')));
    yield put(setMessage(i18n.t('Unable to update profile')));
  }
}

function* updatePublicProfileSaga({ payload }) {
  try {
    const profile = yield call([api, api.updatePublicProfile], payload);
    yield put(updatePublic.success({ profile }));
    yield put(setMessage(i18n.t('Public profile Saved')));
  } catch (e) {
    yield put(updatePublic.failure(i18n.t('Unable to update public profile')));
    yield put(setMessage(i18n.t('Unable to update public profile')));
  }
}

export default function* root() {
  yield all([yield takeLatest([init.TRIGGER], initSaga)]);
  yield all([yield takeLatest([login.TRIGGER], loginSaga)]);
  yield all([yield takeLatest([logout.TRIGGER], logoutSaga)]);
  yield all([yield takeLatest([stats.TRIGGER], statsSaga)]);
  yield all([yield takeLatest([update.TRIGGER], updateProfileSaga)]);
  yield all([yield takeLatest([updatePublic.TRIGGER], updatePublicProfileSaga)]);
}
