import Store from './store/store';

function transformCallback(payload) {
  const state = Store.store.getState();

  if (state?.auth?.user?.id)
    payload.person = {
      id: state.auth.user.id,
      username: state.auth.user.name,
      email: state.auth.user.email,
    };

  payload.custom = {
    partner_name: state.auth?.profile?.name,
    partner_id: state.auth?.profile?.partner_id,
  };

  payload.context = JSON.stringify(state);
}

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureIp: 'anonymize',
  environment: (() => {
    if (process.env.NODE_ENV === 'development') return 'development';
    if (window.location.host === process.env.REACT_APP_PRODUCTION_HOST) return 'production';
    if (window.location.host === process.env.REACT_APP_STAGING_HOST) return 'staging';
  })(),
  payload: {
    server: {
      host: window.location.host,
    },
    client: {
      code_version: process.env.npm_package_version,
    },
  },
  captureUncaught: true,
  captureUnhandledRejections: true,
  transform: transformCallback,

  // Only log production errors to stay within the free Rollbar tier
  // enabled: window.location.host === process.env.REACT_APP_PRODUCTION_HOST
};

export default rollbarConfig;
