import _values from 'lodash/values';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { SALEORDERS_LIST as rootPath } from '../../navigation/routes';
import { useSaleOrders } from '../../store/hooks/shop';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CardHeader from '@material-ui/core/CardHeader';

import Layout from '../../components/App/Layout';

import Table from '../../components/Generic/Table';
import Column from '../../components/Generic/Column';
import InvoicesMenu from '../../components/Shop/SaleOrders/InvoicesMenu';
import InvoiceStatusChip from '../../components/Shop/SaleOrders/InvoiceStatusChip';
import SaleOrderCart from '../../components/Shop/SaleOrders/SaleOrderCart';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  customCardRoot: {
    padding: 0,
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 0,
  },
  card: {},
  cardMedia: {
    objectFit: 'cover',
  },
  productRow: {
    display: 'flex',
    padding: '1rem 0',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  table: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  productImage: {
    display: 'flex',
    height: '50px',
    width: '50px',
    minWidth: '50px',
    borderRadius: '50%',
    overflow: 'hidden',
  },
  productName: {
    flex: '1',
  },
  productDescription: {},
}));

const SaleOrdersPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { path, params } = useRouteMatch();

  const { saleOrders } = useSaleOrders();

  const [shownItems, setShownItems] = React.useState([]);
  const [item, setItem] = React.useState();

  let history = useHistory();

  const handleRowClick = React.useCallback((id, row) => history.push(`${rootPath}/${id}`), [history]);

  React.useEffect(() => {
    setShownItems(_values(saleOrders));
  }, [saleOrders]);

  React.useEffect(() => {
    if (params && params.orderId) {
      setItem(saleOrders[params.orderId]);
    }
  }, [params, saleOrders]);

  return (
    <Layout title={t('Sale Orders')} back={path !== rootPath} backPath={rootPath}>
      <Box display="flex" justifyContent="space-between" pl={2} m={2} mt={4} flexDirection="row">
        <Typography variant="subtitle1">{t('Your sale orders')}</Typography>
        {/* <ActionButton onClick={() => setModalOpen(true)}>{t('Generate coupon')}</ActionButton> */}
      </Box>

      <Grid classes={{ root: classes.table }} container spacing={3} display="flex">
        <Hidden mdDown={path !== rootPath}>
          <Grid item xs={12} lg>
            <Card variant="outlined">
              <CardContent classes={{ root: classes.customCardRoot }}>
                <Table rows={shownItems} onRowClick={handleRowClick}>
                  <Column
                    label={t('Invoice Status')}
                    field="invoice_status"
                    render={value => {
                      return (
                        <InvoiceStatusChip
                          status={value ? value.toUpperCase() : ''}
                          mapping={{
                            upselled: 'info',
                            invoiced: 'success',
                            'to invoice': 'warning',
                            no: 'info',
                          }}
                        />
                      );
                    }}
                  />
                  <Column label={t('Code')} field="name" />
                  <Column
                    label={t('No. of Products')}
                    field="lines"
                    render={value => {
                      return value.count;
                    }}
                  />
                  <Column
                    label={t('Total')}
                    field="amount"
                    render={value => {
                      return `$${value.total}`;
                    }}
                  />
                  <Column
                    label={t('Download Invoice')}
                    field="invoices"
                    render={value => {
                      return (
                        <Grid container spacing={2} justify="flex-end">
                          <Grid item>
                            <InvoicesMenu invoices={value} />
                          </Grid>
                        </Grid>
                      );
                    }}
                  />
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Hidden>
        <Switch>
          {item && (
            <Route path={rootPath + '/:id'}>
              <Grid item xs={12} lg={4}>
                <Card className={classes.card}>
                  <CardHeader
                    title={item.name}
                    subheader={`${item.lines.count} products`}
                    action={
                      <Grid container spacing={1}>
                        <Grid item>
                          <InvoicesMenu invoices={item.invoices} />
                        </Grid>
                        <Grid item>
                          <IconButton onClick={() => history.push(rootPath)}>
                            <Close />
                          </IconButton>
                        </Grid>
                      </Grid>
                    }
                  />
                  <CardContent>
                    <SaleOrderCart order={item} />
                  </CardContent>
                </Card>
              </Grid>
            </Route>
          )}
        </Switch>
      </Grid>
    </Layout>
  );
};

export default React.memo(SaleOrdersPage);
