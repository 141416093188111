import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';

import ImgPlaceholder from '../../assets/images/img-placeholder.png';

const useStyles = makeStyles({
  image: {
    maxHeight: 150,
    maxWidth: '100%',
  },
});

function ImageInput({ value, onChange, readonly }) {
  const { t } = useTranslation();

  const classes = useStyles();
  const inputFile = useRef();

  const handleImageChange = () => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function() {
        onChange(reader.result.substr(reader.result.indexOf(',') + 1));
      },
      false,
    );

    if (inputFile) {
      const file = inputFile.current.files[0];
      if (file.size > 5 * 100 * 1024) {
        alert(t('This file is too large max 500kb'));
      } else {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleChooseImage = () => {
    inputFile && inputFile.current.click();
  };

  if (value && value.indexOf('data:') < 0 && !value.startsWith('http')) {
    value = `data:image;base64,${value}`;
  }

  if (!value) value = ImgPlaceholder;

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} sm={6} justify="center" alignItems="center">
        <Grid item>
          <img src={value} alt={'Profile'} className={classes.image} />
        </Grid>
      </Grid>
      {!readonly && (
        <>
          <Grid item container spacing={2} display="flex" direction="column" justify="space-around" xs={12} sm={6}>
            <Grid item>
              <Button variant="contained" size="small" onClick={handleChooseImage}>
                {t('Choose a file')}
              </Button>
            </Grid>

            <Grid item>
              <Typography variant="caption">{t('Acceptable formats jpg, png only')}</Typography>
            </Grid>

            <Grid item>
              <Typography variant="caption">{t('Max file size 500kb')}</Typography>
            </Grid>
          </Grid>
          <input
            type="file"
            accept="image/png,image/jpg,image/jpeg"
            ref={inputFile}
            onChange={handleImageChange}
            style={{ display: 'none' }}
          />
        </>
      )}
    </Grid>
  );
}

export default ImageInput;
