import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  stageSelectMenu: {
    height: 'auto',
    padding: '5px 15px',
    lineHeight: 1.75,
    paddingRight: '32px !important',
    fontSize: '0.875rem',
  },
}));

const UserAdminButtons = ({ user, changeRole, toggleUser, requests }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const ROLE_LABELS = { admin: t('Admin'), user: t('User') };
  return user ? (
    <Box p={2}>
      <FormControl variant="outlined" className={classes.formControl}>
        <Button onClick={toggleUser} variant="outlined" color="secondary" disabled={requests.toggleUserState.loading}>
          {user.state === 'active' ? t('Disable User') : t('Enable User')}
        </Button>
      </FormControl>

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="user-role-label">{t('Role')}</InputLabel>
        <Select
          labelId="user-role-label"
          value={user.partner_role}
          onChange={event => changeRole(event.target.value)}
          label={t('Role')}
          classes={{
            selectMenu: classes.stageSelectMenu,
          }}
        >
          {['admin', 'user'].map(function(option) {
            return (
              <MenuItem key={option} value={option}>
                {ROLE_LABELS[option]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  ) : null;
};

export default React.memo(UserAdminButtons);
