import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import usePlaces from '../../hooks/usePlaces';

const StateInput = ({
  error,
  value,
  valueID,
  name,
  label,
  required,
  onChange,
  disabled,
  countryField = 'country',
  ...props
}) => {
  const { values } = useFormikContext();
  const { places, states, status } = usePlaces();

  const statesContr = useMemo(() => {
    if (!!values[countryField]) {
      /* Display states of selected country */
      return (
        places.find(place => place.id == values[countryField] || place.id == values[countryField].id)?.states || []
      );
    } else {
      /* Display all the states */
      return states;
    }
  }, [values[countryField], places]);

  if (valueID) {
    value = states?.find(state => state.id == valueID);
  }

  return (
    <Box mb={2} {...props}>
      <Autocomplete
        value={value || ''}
        loading={status === 'loading'}
        name={name}
        disabled={disabled}
        options={statesContr}
        getOptionLabel={option => option?.name || ''}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            error={error}
            fullWidth
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            required={required}
          />
        )}
        onChange={onChange}
      />
      {error && <div>{error}</div>}
    </Box>
  );
};

export default React.memo(StateInput);
