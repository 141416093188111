import React from 'react';
import _debounce from 'lodash/debounce';
import { useHistory, useLocation } from 'react-router-dom';
import * as queryString from 'query-string';

const useFilters = config => {
  const location = useLocation();
  const history = useHistory();

  const URLfilters = queryString.parse(location.search);
  const [currentFilters, setCurrentFilters] = React.useState(URLfilters);

  const setOne = React.useCallback(
    (field, value) => {
      let tempFilters = { ...currentFilters };
      if (value === '') {
        delete tempFilters[field];
      } else {
        tempFilters[field] = value;
      }
      setCurrentFilters({ ...tempFilters });
    },
    [currentFilters],
  );

  const clear = React.useCallback(() => setCurrentFilters({}), [setCurrentFilters]);

  const [wrapper, setWrapper] = React.useState({
    current: currentFilters,
    set: setCurrentFilters,
    setOne: setOne,
    clear: clear,
    config: config,
  });

  const debounced = React.useRef(
    _debounce(_current => {
      // Put in URL only string filters
      const newCurrent = {};
      Object.keys(_current).forEach(key => {
        if (typeof _current[key] === 'string') newCurrent[key] = _current[key];
      });

      history.push({ search: queryString.stringify(newCurrent) });
    }, 100),
  );

  React.useEffect(() => {
    debounced.current(currentFilters);
  }, [currentFilters]);

  React.useEffect(() => {
    setWrapper({
      current: currentFilters,
      set: setCurrentFilters,
      setOne: setOne,
      clear: clear,
      config: config,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters, setCurrentFilters, setOne, clear]);

  return wrapper;
};

export { useFilters };
