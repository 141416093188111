import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Snackbar from '../../components/Common/Snackbar';
import Layout from '../../components/App/Layout';
import Panel from '../../components/Common/Panel';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  Select,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Table,
  Input,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import useVoucherProducts from '../../hooks/Shop/useVoucherProducts';
import { Label } from '@material-ui/icons';
import CountryInput from '../../components/Form/CountryInput';
import { FaTrash, FaTrashAlt } from 'react-icons/fa';
import useHelpdeskMutations from '../../hooks/Helpdesk/useHelpdeskMutations';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, getUser } from '../../store/selectors/auth';
import { setMessage } from '../../store/reducers/SnackReducer';

const ManualOrder = () => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();

  const [orderRows, setOrderRows] = useState([
    {
      quantity: 1,
      voucherProduct: null,
      country: null,
      medium: 'Pearson VUE',
    },
  ]);

  const { createTicket } = useHelpdeskMutations();

  const onSubmit = () => {
    if (orderRows.length === 0) {
      dispatch(setMessage(t('Please add at least one line')));
      return;
    }

    if (orderRows.some(row => !row.voucherProduct || !row.country || !row.medium)) {
      dispatch(setMessage(t('Please fill in all fields')));
      return;
    }

    createTicket
      .mutateAsync({
        queue: 'partner-support',
        title: `Manual voucher order received by ${user?.name} for ${profile?.name}`,
        message: `List of ordered vouchers:<br><br> ${orderRows.map(row => {
          return `n.${row.quantity} x ${row.voucherProduct.name} (${row.country.name} - ${row.medium})<br>`;
        })}`,
      })
      .then(() => {
        dispatch(setMessage(t('Order created successfully')));
        setOrderRows([
          {
            quantity: 1,
            voucherProduct: null,
            country: null,
            medium: 'Pearson VUE',
          },
        ]);
      })
      .catch(() => {
        dispatch(setMessage(t('Order creation failed')));
      });
  };

  return (
    <Layout title={'Manual order'}>
      <Box>
        <Panel>
          <TableContainer component={Paper}>
            <Table style={{ borderWidth: 0 }}>
              <TableHead>
                <TableRow>
                  <TableCell width={3}>{t('Quantity')}</TableCell>
                  <TableCell>{t('Voucher')}</TableCell>
                  <TableCell>{t('Country')}</TableCell>
                  <TableCell>{t('Medium')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderRows.map((order, index) => (
                  <OrderRow
                    key={index}
                    {...order}
                    onRemove={() => setOrderRows(orderRows.filter((_, i) => i !== index))}
                    onChange={raw => {
                      const newOrderRows = [...orderRows];
                      newOrderRows[index] = raw;
                      setOrderRows(newOrderRows);
                    }}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box marginY={4}>
            <Button
              variant="contained"
              onClick={() =>
                setOrderRows([
                  ...orderRows,
                  {
                    quantity: 1,
                    voucherProduct: null,
                    country: orderRows?.[orderRows.length - 1]?.country || null,
                    medium: 'Pearson VUE',
                  },
                ])
              }
            >
              {t('Add line')}
            </Button>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={onSubmit}
              disabled={createTicket.isLoading}
            >
              {t('Place order')}
            </Button>
          </Box>
        </Panel>
      </Box>

      <Snackbar />
    </Layout>
  );
};

const OrderRow = ({ quantity, voucherProduct, country, medium, onRemove, onChange }) => {
  const { status, vouchers } = useVoucherProducts();
  const { t } = useTranslation();

  if (status === 'loading') return null;

  return (
    <TableRow>
      <TableCell width={3}>
        <Input
          fullWidth
          type="number"
          variant="outlined"
          value={quantity}
          onChange={event => {
            onChange({
              quantity: event.target.value,
              voucherProduct,
              country,
              medium,
            });
          }}
        >
          {quantity}
        </Input>
      </TableCell>
      <TableCell>
        <Select
          fullWidth
          variant="outlined"
          placeholder={t('Product')}
          value={voucherProduct?.id || ''}
          onChange={event =>
            onChange({
              quantity,
              voucherProduct: vouchers.find(v => v.id == event.target.value),
              country,
              medium,
            })
          }
        >
          {vouchers.map(voucher => (
            <MenuItem key={voucher.id} value={voucher?.id}>
              {voucher.name}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell>
        <CountryInput
          fullWidth
          mb={0}
          value={country}
          onChange={(_e, value) => {
            onChange({
              quantity,
              voucherProduct,
              country: value,
              medium,
            });
          }}
        />
      </TableCell>
      <TableCell>
        <Select
          fullWidth
          variant="outlined"
          placeholder={t('Medium')}
          value={medium}
          onChange={event =>
            onChange({
              quantity,
              voucherProduct,
              country,
              medium: event.target.value,
            })
          }
        >
          <MenuItem value="Pearson VUE">{t('Pearson VUE')}</MenuItem>
          <MenuItem value="Yardstick (Linux Essentials only)">{t('Yardstick (Linux Essentials only)')}</MenuItem>
        </Select>
      </TableCell>
      <TableCell>
        <IconButton onClick={onRemove} color="secondary" aria-label="delete" component="span">
          <FaTrashAlt size={20} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ManualOrder;
