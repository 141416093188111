import _keyBy from 'lodash/keyBy';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const FETCH = 'Contacts.Fetch';
export const fetchContacts = createRoutine(FETCH);

const INITIAL_REQUEST_STATE = {
  fetch: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: {},
  requests: INITIAL_REQUEST_STATE,
};

const contactsReducer = handleActions(
  {
    [fetchContacts.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetch.loading = true;
        draft.requests.fetch.error = null;
      }),
    [fetchContacts.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.list = _keyBy(payload.results, 'id');
      }),
    [fetchContacts.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.requests.fetch.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default contactsReducer;
