import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
  title: {
    textTransform: 'uppercase',
  },
  subheader: {},
  cardActions: {
    borderTop: '1px solid #dedede',
  },
});

const Detail = ({ item, Actions, ...props }) => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const classes = useStyles();

  React.useEffect(() => {
    props.onRoute(match.params.id);
  }, [dispatch, props, match.params.id]);

  return (
    item && (
      <Card variant="outlined">
        <CardHeader
          classes={{
            title: classes.title,
            subheader: classes.subheader,
          }}
          title={props.typeName}
          subheader={props.titleComponent ? props.titleComponent(item) : item[props.titleField]}
          titleTypographyProps={{ variant: 'button', gutterBottom: true }}
          subheaderTypographyProps={{
            variant: 'h5',
            color: 'textPrimary',
          }}
          action={
            <>
              <IconButton onClick={props.onCloseClick}>
                <Close />
              </IconButton>
            </>
          }
        />
        <CardContent>
          {props.children.map(
            child =>
              item[child.props.field] &&
              React.cloneElement(child, {
                key: child.props.field,
                value: item[child.props.field],
              }),
          )}
        </CardContent>
        {(Actions || !props.readonly) && (
          <CardActions
            classes={{
              root: classes.cardActions,
            }}
            disableSpacing
          >
            {Actions && <Actions item={item} />}
            {!props.readonly && (
              <Box ml={'auto'}>
                {props.noEdit ? null : (
                  <Button onClick={props.onEditClick} color="secondary">
                    {'Edit'}
                  </Button>
                )}

                <Button onClick={props.onDeleteClick} color="secondary">
                  {'Delete'}
                </Button>
              </Box>
            )}
          </CardActions>
        )}
      </Card>
    )
  );
};

export default React.memo(Detail);
