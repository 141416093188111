import _values from 'lodash/values';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as queryString from 'query-string';

import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const Filters = ({ items, setItems, open, onClose, children, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const URLfilters = queryString.parse(location.search);
  const { t } = useTranslation();

  const setFilter = (field, value) => {
    value === '' ? delete URLfilters[field] : (URLfilters[field] = value);
    history.push({ search: queryString.stringify(URLfilters) });
  };

  React.useEffect(() => {
    if (items && props.options) {
      setItems(
        _values(items).filter(row => {
          for (let [key, raw] of Object.entries(URLfilters)) {
            if (key in props.options) {
              let val = props.options[key].value(raw);
              if (row[key] !== val) {
                return false;
              }
            }
          }
          return true;
        }),
      );
    }
  }, [items, props.options, setItems, URLfilters]);

  if (props.chip) {
    return (
      <Grid container direction="row" spacing={1}>
        {React.Children.map(children || null, (filter, index) => {
          if (filter.props.name in URLfilters) {
            let raw = URLfilters[filter.props.name];
            let val = props.options[filter.props.name].value(raw);
            let selected = props.options[filter.props.name].chip(val);
            return (
              <Grid item key={index}>
                <Chip
                  label={`${props.options[filter.props.name].label}: ${selected}`}
                  onDelete={() => setFilter(filter.props.name, '')}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    );
  }

  return (
    <Drawer BackdropProps={{ invisible: true }} anchor="right" open={open} onClose={onClose}>
      <Grid container direction="row">
        <Grid item xs={12} p={3}>
          <Box p={3}>
            <Typography variant="h1" xs={12}>
              {t('Filters')}
            </Typography>
          </Box>
        </Grid>
        {React.Children.map(children || null, (filter, index) => (
          <Grid item key={index} xs={12} p={3}>
            <Box p={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor={filter.props.name}>{props.options[filter.props.name].label}</InputLabel>
                {React.cloneElement(filter, {
                  key: index,
                  value: URLfilters[filter.props.name] || '',
                  onChange: e => {
                    let raw = filter.props.onChange(e);
                    let val = props.options[filter.props.name].value(raw);
                    setFilter(filter.props.name, val);
                  },
                  inputProps: {
                    name: filter.props.name,
                    id: filter.props.name,
                  },
                  fullWidth: true,
                })}
              </FormControl>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Drawer>
  );
};

export default React.memo(Filters);
