import { createSelector } from 'reselect';

export const selectCheckoutConfig = createSelector(
  state => state.shop.config,
  config => config,
);

export const selectCart = createSelector(
  state => state.shop.cart,
  cart => cart,
);

export const getVoucherProducts = createSelector(
  state => state.shop.voucherProducts,
  products => products,
);

export const getJobOffersProducts = createSelector(
  state => state.shop.jobOffersProducts,
  products => products,
);

export const getCartIsOpen = createSelector(
  state => state.shop.cartIsOpen,
  isOpen => isOpen,
);

export const getAddItemRequests = createSelector(
  state => state.shop.requests.addItem,
  requests => requests,
);

export const getApplyCouponRequests = createSelector(
  state => state.shop.requests.applyCoupon,
  requests => requests,
);

export const getSaleOrders = createSelector(
  state => state.shop.saleOrders,
  saleOrders => saleOrders,
);

export const getSaleOrdersRequests = createSelector(
  state => state.shop.requests.fetchSaleOrders,
  requests => requests,
);
