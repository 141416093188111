import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchPartners, fetchPartner } from '../reducers/PartnersReducer';
import i18n from '../../locales';
import api from '../../data/network';

function* fetchPartnersSaga({ payload }) {
  try {
    const params = { offset: 0, limit: 9999 };
    const partners = yield call([api.partners, api.partners.search], params);
    yield put(fetchPartners.success(partners));
  } catch (e) {
    console.error(e);
    yield put(fetchPartners.failure(i18n.t('Unable to fetch partners')));
  }
}

function* fetchPartnerSaga({ payload }) {
  try {
    const response = yield call([api.partners.get, api.partners.get], payload);
    const vouchers = yield call([api.vouchers.by_partner, api.vouchers.by_partner], payload);
    if (vouchers.results) {
      response.vouchers = vouchers.results;
    } else {
      response.vouchers = [];
    }
    yield put(fetchPartner.success(response));
  } catch (e) {
    yield put(fetchPartner.failure(i18n.t('Unable to fetch partner')));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchPartners.TRIGGER], fetchPartnersSaga)]);
  yield all([yield takeLatest([fetchPartner.TRIGGER], fetchPartnerSaga)]);
}
