import React from 'react';

import Anchor from '@material-ui/core/Link';
import LabelledValue from '../Common/LabelledValue';
import MaterialChip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const Text = React.memo(({ label, field, value, ...props }) => {
  return (
    <LabelledValue label={label} key={field}>
      {props.render ? props.render(value) : value}
    </LabelledValue>
  );
});

const Paragraph = React.memo(({ label, field, value }) => {
  return (
    <LabelledValue label={label} key={field}>
      <Typography paragraph variant="body1" color="textSecondary">
        {value}
      </Typography>
    </LabelledValue>
  );
});

const Link = React.memo(({ label, field, value, linkLabel }) => {
  if (value?.[0] !== '/') {
    value = 'http://' + value;
  }
  return (
    <LabelledValue label={label} key={field}>
      <Anchor color="secondary" href={value} target="_blank">
        {linkLabel || value}
      </Anchor>
    </LabelledValue>
  );
});

const EmailLink = React.memo(({ label, field, value }) => {
  return (
    <LabelledValue label={label} key={field}>
      <Anchor color="secondary" href={'mailto:' + value}>
        {value}
      </Anchor>
    </LabelledValue>
  );
});

const PhoneLink = React.memo(({ label, field, value }) => {
  return (
    <LabelledValue label={label} key={field}>
      <Anchor color="secondary" href={'tel:' + value}>
        {value}
      </Anchor>
    </LabelledValue>
  );
});

const Chip = React.memo(({ label, field, value, ...props }) => {
  if (props.many) {
    return (
      value && (
        <LabelledValue label={label} key={field}>
          <Grid container spacing={1}>
            {value.map((child, index) => (
              <Grid item key={index}>
                <MaterialChip color={props.color} size="small" label={props.render ? props.render(child) : child} />
              </Grid>
            ))}
          </Grid>
        </LabelledValue>
      )
    );
  }
  return (
    <LabelledValue label={label} key={field}>
      <MaterialChip
        color={props.color}
        size={props.size || 'small'}
        label={props.render ? props.render(value) : value}
      />
    </LabelledValue>
  );
});

export { Text, Paragraph, Link, EmailLink, PhoneLink, Chip };
