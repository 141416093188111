import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useUser } from '../store/hooks/company';
import { USERS_LIST, PROFILE_DETAILS } from '../navigation/routes';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Snackbar from '../components/Common/Snackbar';
import Layout from '../components/App/Layout';
import Panel from '../components/Common/Panel';
import UserProfileForm from '../components/Profile/UserProfileForm';
import UserAdminButtons from '../components/Users/UserAdminButtons';

const useStyles = makeStyles(theme => ({
  table: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
}));

const UserProfilePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const match = useRouteMatch(PROFILE_DETAILS);

  const [id, setId] = React.useState(match?.params?.id);
  const [canChange, setCanChange] = React.useState();
  const { user, isAdmin, changeRole, toggleUser, updateUser, requests, loggedUser } = useUser(id);

  React.useEffect(() => {
    match ? setId(match.params.id) : setId(undefined);
  }, [dispatch, setId, match]);

  React.useEffect(() => {
    if (user && loggedUser) {
      setCanChange(isAdmin || loggedUser.partner_role?.[0]?.id === user.id);
    } else {
      setCanChange(isAdmin);
    }
  }, [user, loggedUser, isAdmin]);

  const handleSubmit = React.useCallback(values => dispatch(updateUser(values)), [dispatch, updateUser]);

  return (
    <Layout title={t('Profile')} back={!!match} backPath={USERS_LIST}>
      <Grid container spacing={3} className={classes.table} justify="center">
        <Grid item xs={12} sm={8}>
          <Box>
            {user && (
              <Panel
                title={user.name}
                subtitle={t('Your profile')}
                actionComponent={() =>
                  isAdmin &&
                  loggedUser.partner_role?.[0]?.id !== user.id && (
                    <UserAdminButtons user={user} changeRole={changeRole} toggleUser={toggleUser} requests={requests} />
                  )
                }
              >
                <UserProfileForm
                  user={user}
                  loading={requests.loading}
                  error={requests.error}
                  onSubmit={handleSubmit}
                  readonly={!canChange}
                />
              </Panel>
            )}
          </Box>
        </Grid>
      </Grid>
      <Snackbar />
    </Layout>
  );
};

export default React.memo(UserProfilePage);
