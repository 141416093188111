import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCouponAssignees, useCouponPrograms } from '../../store/hooks/coupons';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import TableFilters from '../Generic/TableFilters';
import AutoCompleteFilter from '../Common/Filters/AutoCompleteFilter';

const CouponsFilters = ({ controller }) => {
  const { t } = useTranslation();
  const { assignees } = useCouponAssignees();
  const { programs } = useCouponPrograms();

  const getAssigneeNameById = useCallback(
    assigneeId => {
      let assignee = assignees.find(x => x.partner_id === parseInt(assigneeId));
      if (assignee) {
        return assignee.partner_name;
      }
    },
    [assignees],
  );

  const getProgramById = useCallback(value => programs.find(program => program.id === parseInt(value))?.name, [
    programs,
  ]);

  return (
    <TableFilters
      controller={controller}
      chips={{
        program_id: value => `${t('Program')}: ${getProgramById(value)}`,
        partner_id: value => `${t('Assignee')}: ${getAssigneeNameById(value)}`,
        showAssigned: value => value && `${t('Assigned')}`,
        showUnassigned: value => value && `${t('Unassigned')}`,
      }}
      renderFilters={(current, setFilter) => (
        <>
          <FormControl fullWidth variant="outlined">
            <FormControlLabel
              control={
                <Checkbox
                  checked={current.showAssigned}
                  onChange={event => {
                    setFilter('showAssigned', event.target.checked || '');
                  }}
                  name="showAssigned"
                  color="primary"
                />
              }
              label={t('Assigned')}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <FormControlLabel
              control={
                <Checkbox
                  checked={current.showUnassigned}
                  onChange={event => {
                    setFilter('showUnassigned', event.target.checked || '');
                  }}
                  name="showUnassigned"
                  color="primary"
                />
              }
              label={t('Unassigned')}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <AutoCompleteFilter
              name="partner_id"
              label={t('Assignee')}
              value={current.partner_id ? assignees.find(x => x.partner_id === parseInt(current.partner_id)) : null}
              options={assignees}
              optionLabel={option => (option.partner_name ? option.partner_name : '')}
              onChange={(event, value) => {
                setFilter('partner_id', value ? value.partner_id : '');
              }}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <AutoCompleteFilter
              name="program_id"
              label={t('Program')}
              value={current.program_id ? programs.find(program => program.id === parseInt(current.program_id)) : null}
              options={programs}
              optionLabel={option => (option.name ? option.name : '')}
              onChange={(_e, value) => {
                setFilter('program_id', value ? value.id : '');
              }}
              inputProps={{ 'data-testid': 'program' }}
            />
          </FormControl>
        </>
      )}
    />
  );
};

export default React.memo(CouponsFilters);
