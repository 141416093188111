import { createSelector } from 'reselect';

export const getVouchers = createSelector(
  state => state.vouchers.list,
  items => items,
);

export const getVoucherOrders = createSelector(
  state => state.vouchers.orders,
  items => items,
);
