import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAvailableTrainingPartners } from '../../store/hooks/students';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AutoCompleteFilter from '../Common/Filters/AutoCompleteFilter';
import useCouponMutations from '../../hooks/Coupons/useCouponMutations';
import { Box, Typography } from '@material-ui/core';

const AssignCouponModal = ({ item, open, onClose }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [selectedPartner, setSelectedPartner] = React.useState();
  const { partners } = useAvailableTrainingPartners();

  const { assign } = useCouponMutations();

  const handleAssign = React.useCallback(() => {
    let partner = selectedPartner ? partners.find(x => x.id === selectedPartner) : null;

    if (partner) {
      setError(null);
      const data = { coupon_id: item.id, partner_id: partner.partner_id };
      assign.mutate(data, {
        onSuccess: onClose,
        onError: () => {
          setError(t('You can not assign this coupon to ' + partner.partner_name));
        },
      });
    }
  }, [assign, item, onClose, selectedPartner, partners, setError]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>{t('Assign coupon')}</DialogTitle>
      <DialogContent>
        <AutoCompleteFilter
          groupBy={option => option._partner_type}
          name="partner_id"
          label={t('Partner')}
          value={selectedPartner ? partners.find(x => x.id === selectedPartner) : null}
          options={partners}
          optionLabel={option => (option.partner_name ? `[${option.lpi_id}] ${option.partner_name}` : '')}
          onChange={(event, value) => {
            console.log(value);
            setSelectedPartner(value ? value.id : null);
          }}
        />
        <Box>
          <Typography>{error}</Typography>
        </Box>
        <DialogActions>
          <Button onClick={handleAssign} color="secondary" disabled={!selectedPartner}>
            {t('Confirm')}
          </Button>
          <Button onClick={onClose} color="secondary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(AssignCouponModal);
