import { takeLatest, put, all, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { fetchVouchers, assignVoucher, channelAssignVoucher, fetchVoucherOrders } from '../reducers/VouchersReducer';
import { setMessage } from '../reducers/SnackReducer';
import i18n from '../../locales';
import api from '../../data/network';
import { VOUCHERS_LIST } from '../../navigation/routes';

function* fetchVouchersSaga({ payload }) {
  try {
    const vouchers = yield call([api.vouchers, api.vouchers.search]);
    yield put(fetchVouchers.success(vouchers));
  } catch (e) {
    yield put(fetchVouchers.failure('Unable to fetch vouchers'));
  }
}

function* assignVoucherSaga({ payload }) {
  try {
    yield call([api.vouchers, api.vouchers.assign], {
      voucher_id: payload.voucher.id,
      partner_id: payload.partner.partner_id,
    });
    yield put(assignVoucher.success());
    yield put(fetchVouchers());
    yield put(setMessage(`${i18n.t('Done! Voucher assigned to')} ${payload.partner.partner_name}`));
    yield put(push(VOUCHERS_LIST));
  } catch (e) {
    // manda la action failure
    console.error(e);
    yield put(assignVoucher.failure(i18n.t('Unable to assign voucher')));
  }
}

function* channelAssignVoucherSaga({ payload }) {
  try {
    yield call([api.vouchers, api.vouchers.channel_assign], {
      voucher_id: payload.voucher.id,
      partner_id: payload.partner.id,
    });
    yield put(channelAssignVoucher.success());
    yield put(fetchVouchers());
    yield put(setMessage(`${i18n.t('Done! Voucher assigned to')} ${payload.partner.name}`));
    yield put(push(VOUCHERS_LIST));
  } catch (e) {
    // manda la action failure
    console.error(e);
    yield put(channelAssignVoucher.failure(i18n.t('Unable to assign voucher')));
  }
}

function* fetchVoucherOrdersSaga({ payload }) {
  try {
    const orders = yield call([api.vouchers, api.vouchers.orders]);
    yield put(fetchVoucherOrders.success(orders));
  } catch (e) {
    yield put(fetchVoucherOrders.failure('Unable to fetch voucher orders'));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchVouchers.TRIGGER], fetchVouchersSaga)]);
  yield all([yield takeLatest([assignVoucher.TRIGGER], assignVoucherSaga)]);
  yield all([yield takeLatest([channelAssignVoucher.TRIGGER], channelAssignVoucherSaga)]);
  yield all([yield takeLatest([fetchVoucherOrders.TRIGGER], fetchVoucherOrdersSaga)]);
}
