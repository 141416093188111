import { useMutation, useQueryClient } from 'react-query';
import api from '../../data/network';

import { QUERY_KEY } from './useSiteAdmins';

const useSiteAdminsMutations = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  const createSiteAdmin = useMutation(payload => api.siteAdmins.create(payload), {
    onSuccess: () => {
      invalidateQuery();
    },
  });

  const updateSiteAdmin = useMutation(payload => api.siteAdmins.update(payload.id, payload), {
    onSuccess: data => {
      invalidateQuery();
      queryClient.invalidateQueries([QUERY_KEY, data.id]);
    },
  });

  const deleteSiteAdmin = useMutation(id => api.siteAdmins.remove(id), {
    onSuccess: () => {
      invalidateQuery();
    },
  });

  return {
    createSiteAdmin,
    updateSiteAdmin,
    deleteSiteAdmin,
  };
};

export default useSiteAdminsMutations;
