import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const FETCH = 'Skills.Fetch';
export const fetchSkills = createRoutine(FETCH);

const INITIAL_REQUEST_STATE = {
  fetch: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: [],
  requests: INITIAL_REQUEST_STATE,
};

const skillsReducer = handleActions(
  {
    [fetchSkills.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetch.loading = true;
        draft.requests.fetch.error = null;
      }),
    [fetchSkills.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.list = payload.results;
      }),
    [fetchSkills.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.requests.fetch.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default skillsReducer;
