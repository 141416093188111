import React from 'react';
import logo from '../../assets/images/logo-white.png';

import Avatar from 'react-avatar';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ededed',
  },
  container: {
    marginTop: 100,
    [theme.breakpoints.up('sm')]: {
      width: 700,
    },
  },
  mainBox: {
    [theme.breakpoints.up('md')]: {
      width: 600,
      padding: 40,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 20,
    },
    backgroundColor: '#fff',
  },
  logoBox: {
    paddingTop: 40,
    padding: 20,
    backgroundColor: theme.palette.primary.main,
  },
  subtitle: {
    paddingBottom: 20,
    display: 'block',
  },
}));

const Page = ({ title, subtitle, children, ...props }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" height="100vh" alignItems="center" className={classes.root}>
      <Grid container justify="center" className={classes.container}>
        <Grid item xs md className={classes.logoBox}>
          <img src={logo} alt="Linux Professional Institute" className={classes.logo} />
        </Grid>
        <Grid item xs={12} md={12} lg={6} className={classes.mainBox}>
          {props.user ? (
            <Box mb={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Avatar email={props.user.email} name={props.user.name} src={props.user.avatar} size="60" round={true} />
              <Typography variant="h5" gutterBottom>
                {title}
              </Typography>
              <Typography variant="subtitle2" className={classes.subtitle} {...props.subtitleProps}>
                {subtitle}
              </Typography>
            </Box>
          ) : (
            <>
              <Typography variant="h5" gutterBottom>
                {title}
              </Typography>
              <Typography variant="subtitle2" className={classes.subtitle} {...props.subtitleProps}>
                {subtitle}
              </Typography>
            </>
          )}

          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(Page);
