import React, { useMemo } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Formik, Form as FormikForm } from 'formik';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import ActionButton from '../Common/ActionButton';
import TextInput from '../Form/TextInput';
import { CircularProgress, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { FaFile } from 'react-icons/fa';

const initialValues = {
  lpi_id: '',
  partner_email: '',
};

const validate = values => {
  const errors = {};
  if (!values.lpi_id) {
    errors['lpi_id'] = i18n.t('LPI ID is required');
  }
  if (!values.partner_email) {
    errors['partner_email'] = i18n.t('Email is required');
  }

  return errors;
};

const AddHiringAction = ({ label, onSubmit, isLoading }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <React.Fragment>
      <ActionButton m={0} mr={1} onClick={() => setModalOpen(true)}>
        {label}
      </ActionButton>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            onSubmit={values => {
              onSubmit(values);
              setModalOpen(false);
            }}
            validate={validate}
          >
            {({ handleSubmit, handleChange, errors, touched, values, setValues }) => (
              <FormikForm>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      label={t('LPI ID')}
                      name="lpi_id"
                      error={touched['lpi_id'] && errors['lpi_id']}
                      onChange={handleChange('lpi_id')}
                      value={values['lpi_id']}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      label={t('Email')}
                      name="partner_email"
                      error={touched['partner_email'] && errors['partner_email']}
                      onChange={handleChange('partner_email')}
                      value={values['partner_email']}
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button onClick={() => setModalOpen(false)} color="default" disabled={isLoading}>
                    {t('Cancel')}
                  </Button>
                  <Button onClick={handleSubmit} color="secondary" disabled={isLoading}>
                    {isLoading ? <CircularProgress size={24} /> : t('Add')}
                  </Button>
                </DialogActions>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default React.memo(AddHiringAction);
