import React from 'react';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContacts } from '../../store/reducers/ContactsReducer';
import { getContactsList } from '../../store/selectors/contacts';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import TextInput from './TextInput';
import { Typography } from '@material-ui/core';

const CompanyInput = ({ error, ...props }) => {
  const dispatch = useDispatch();
  const contacts = useSelector(getContactsList);
  const { setFieldValue, values } = useFormikContext();

  React.useEffect(() => {
    dispatch(fetchContacts({}));
  }, [dispatch]);

  return values.id !== null ? (
    <TextInput {...props} />
  ) : (
    <Box mb={2}>
      <Autocomplete
        freeSolo
        value={props.value}
        name={props.name}
        options={contacts}
        getOptionLabel={option => (option ? (option.name ? option.name : option) : '')}
        onChange={(event, value) => {
          setFieldValue(props.name, value && value.name ? value.name : value);
          props.handleChange(setFieldValue, value);
        }}
        onInputChange={(event, value) => {
          setFieldValue(props.name, value);
          setFieldValue('partner_id', null);
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={props.label}
            error={props.error}
            fullWidth
            variant="outlined"
            required={props.required}
          />
        )}
      />
      {error && <Typography color="error">{error}</Typography>}
    </Box>
  );
};

export default React.memo(CompanyInput);
