import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getByPartner } from '../reducers/AgreementsReducer';
import api from '../../data/network';

function* getByPartnerSaga({ payload }) {
  try {
    const agreements = yield call([api.partnerAgreements, api.partnerAgreements.search]);
    yield put(getByPartner.success(agreements));
  } catch (e) {
    yield put(getByPartner.failure('Unable to get partner agreements'));
  }
}

export default function* root() {
  yield all([yield takeLatest([getByPartner.TRIGGER], getByPartnerSaga)]);
}
