import { createSelector } from 'reselect';

export const getUser = createSelector(
  state => state.auth.user,
  user => user,
);

export const getProfile = createSelector(
  state => state.auth.profile,
  profile => profile,
);

export const getTrainingCenters = createSelector(
  state => state.auth.trainingCenters,
  trainingCenters => trainingCenters,
);

export const getStats = createSelector(
  state => state.auth.stats,
  stats => stats,
);
