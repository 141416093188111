import React, { useMemo } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import useTrainingTopics from '../../../hooks/Locations/useTrainingTopics';
import { Box } from '@material-ui/core';
import useSiteAdmins from '../../../hooks/SiteAdmins/useSiteAdmins';

const SelectSiteAdmin = ({ name, label, value, onChange, error, ...props }) => {
  const { siteAdmins, status } = useSiteAdmins();

  const availableSiteAdmins = useMemo(
    () =>
      siteAdmins.map(siteAdmin => {
        if (siteAdmin.state !== 'accepted') {
          siteAdmin.partner_name = `(Pending) ${siteAdmin.lpi_id} `;
        }

        return siteAdmin;
      }),
    [siteAdmins],
  );

  return (
    <Box mb={2} {...props}>
      <Autocomplete
        value={value || ''}
        name={name}
        options={availableSiteAdmins}
        loading={status === 'loading'}
        getOptionLabel={option => option?.partner_name || ''}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            error={error}
            fullWidth
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        )}
        onChange={onChange}
      />
      {error && <div>{error}</div>}
    </Box>
  );
};

export default React.memo(SelectSiteAdmin);
