import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getUser } from '../../store/selectors/auth';

import Button from '@material-ui/core/Button';
import AssignChannelModal from './AssignChannelModal';
import AssignModal from './AssignModal';

const VouchersActions = ({ item }) => {
  const { t } = useTranslation();
  const [openAssignModal, setOpenAssignModal] = React.useState(false);
  const [openAssignChannelModal, setOpenAssignChannelModal] = React.useState(false);

  const user = useSelector(getUser);

  return (
    <React.Fragment>
      {item.assigned_to_id === null && (
        <Button onClick={() => setOpenAssignModal(true)} color="secondary">
          {t('Assign')}
        </Button>
      )}
      {item.channel_partner_id === null && user.partner_type.includes('channel') && (
        <Button onClick={() => setOpenAssignChannelModal(true)} color="secondary">
          {t('Assign To Partner')}
        </Button>
      )}
      <AssignModal item={item} open={openAssignModal} onClose={() => setOpenAssignModal(false)} />
      {user.partner_type.includes('channel') && (
        <AssignChannelModal
          item={item}
          open={openAssignChannelModal}
          onClose={() => setOpenAssignChannelModal(false)}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(VouchersActions);
