import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../store/selectors/auth';
import { login } from '../store/reducers/AuthReducer';
import LoginForm from '../components/Auth/LoginForm';
import LanguageSwitcher from '../components/App/LanguageSwitcher';
import * as queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import logo from '../assets/images/logo.png';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ForgotPasswordLink from '../components/Auth/ForgotPasswordLink';
import PageBackground from '../components/App/PageBackground';

const BlankLink = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const LoginPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const location = useLocation();
  const { next } = queryString.parse(location.search);

  const request = useSelector(state => state.auth.requests.login);

  const handleSubmit = useCallback(
    values => {
      values['next'] = next;
      return dispatch(login(values));
    },
    [dispatch, next],
  );

  return (
    <PageBackground styles={{ backgroundColor: '#fff9e0' }}>
      <Box className={classes.pageContainer}>
        <Box display="flex">
          <Box className={classes.textColumn}>
            <img src={logo} height={60} alt="Linux Professional Institute" className={classes.logo} />

            <p>
              {t(`Welcome to our partner portal.  You can use this site to manage your partnership, 
                  access partner resources, purchase and distribute exam vouchers, create coupons 
                  for your students and community members, and more (in development).`)}
            </p>

            <p>
              {t(
                "However, if you have reached this site and don't know why you are logging in.  You may be interested in:",
              )}
              <ul>
                <li>
                  {t('our')} <BlankLink href="https://lpi.org/partner">{t('partner')}</BlankLink> {t('and')}{' '}
                  <BlankLink href="https://lpi.org/trainers">{t('trainer')}</BlankLink> programs
                </li>

                <li>
                  <BlankLink href="https://lpi.org/articles">{t('news')}</BlankLink>,{' '}
                  <BlankLink href="https://lpi.org/blog">{t('blogs')}</BlankLink> and{' '}
                  <BlankLink href="https://lpi.org/events">{t('events')}</BlankLink> or the{' '}
                  <BlankLink href="https://discuss.lpi.org">{t('discussion forums')}</BlankLink>
                </li>
                <li>
                  <a href="mailto:partnering@lpi.org">{t('speaking with our partnering team')}</a>
                </li>
              </ul>

              <p>
                {t(
                  'If you are a formal Member, certification holder, instructor or community member, you may be looking for our community portal at',
                )}{' '}
                <BlankLink href="https://people.lpi.org">https://people.lpi.org</BlankLink>{' '}
              </p>

              <p>
                {t('Also, you can always send general inquiries to')} <a href="mailto:info@lpi.org">info@lpi.org</a>
              </p>
            </p>
          </Box>

          <Box className={classes.loginColumn}>
            <Paper className={classes.loginbox}>
              <Box className={classes.loginContainer}>
                <img src={logo} height={60} alt="Linux Professional Institute" className={classes.logoLogin} />
                <Typography variant="h5" gutterBottom>
                  {t('Login')}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {t('Insert your LPI Partner credentials')}
                </Typography>
                {!user && (
                  <Box>
                    <LoginForm loading={request.loading} error={request.error} onSubmit={handleSubmit} />
                  </Box>
                )}
                <ForgotPasswordLink />
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" gap={2} mt={5}>
        <LanguageSwitcher />
      </Box>
    </PageBackground>
  );
};

const useStyles = makeStyles(theme => ({
  pageContainer: {
    [theme.breakpoints.up('md')]: {
      width: '60%',
      paddingTop: '100px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: '20px',
    },
    margin: '0px auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  textColumn: {
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    width: '50%',
    paddingRight: 50,
    paddingLeft: 50,
  },
  loginColumn: {
    [theme.breakpoints.up('lg')]: {
      width: '50%',
      paddingRight: 20,
      paddingLeft: 20,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 10,
      paddingLeft: 10,
    },
  },
  loginbox: {
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 70,
    paddingBottom: 70,
  },
  loginContainer: {
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  logoLogin: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    marginBottom: 20,
  },
  textbox: {
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    textAlign: 'center',
    color: theme.palette.error.main,
  },
}));

export default LoginPage;
