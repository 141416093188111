import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../data/network';

export const QUERY_KEY = 'shop.cart';

/**
 * Get and manage a cart
 * @param { string } [orderId] If provided, fetch the cart with this orderId instead of default one
 */
const useCart = orderId => {
  const req = useQuery([QUERY_KEY, orderId], () =>
    orderId ? api.cart.fetch({ order_id: orderId }) : api.cart.fetch(),
  );

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    cart: req.data || [],
    ...req,
  };
};

export default useCart;
