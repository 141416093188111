import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  common: {
    color: '#ffffff',
    borderRadius: '4px',
    minWidth: '80px',
    height: '24px',
  },

  pending: {
    backgroundColor: '#FF9100',
  },
  accepted: {
    backgroundColor: '#00B35F',
  },
  active: {
    backgroundColor: '#00B35F',
  },
  confirmed: {
    backgroundColor: '#00B35F',
  },
  declined: {
    backgroundColor: '#CFD8DC',
  },
  new: {
    backgroundColor: '#00B35F',
  },
  assigned: {
    backgroundColor: '#FF9100',
  },
  redemeed: {
    backgroundColor: '#00B35F',
  },
  expired: {
    backgroundColor: '#CFD8DC',
  },
  canceled: {
    backgroundColor: '#FF9100',
  },
}));

const StatusChip = ({ status }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const colorClass = status ? classes[status.toLowerCase()] : '';
  return <Chip className={`${classes['common']} ${colorClass}`} label={t(status)} />;
};

StatusChip.propTypes = {
  status: PropTypes.string,
};

export default React.memo(StatusChip);
