import React from 'react';
import { useTranslation } from 'react-i18next';

import Page from '../../components/Generic/Page';

import CandidatesDetail from '../../components/Hiring/CandidatesDetail';
import CandidatesFilters from '../../components/Hiring/CandidatesFilters';
import CandidatesTable from '../../components/Hiring/CandidatesTable';
import ActionButton from '../../components/Common/ActionButton';
import useApplications from '../../hooks/Training/useApplications';

const CandidatesListPage = () => {
  const { t } = useTranslation();

  const labels = {
    title: t('Applications'),
  };

  return (
    <Page
      ep={'applications'}
      subtitle={t('List of applications')}
      labels={labels}
      Detail={CandidatesDetail}
      Filters={CandidatesFilters}
      Table={CandidatesTable}
      NavbarButtons={NavbarButtons}
    />
  );
};

const NavbarButtons = () => {
  const { t } = useTranslation();
  const { applications, isLoading } = useApplications();

  const handleExport = () => {
    const csv = applications.map(item => {
      return {
        'Application Code': item.code,
        'Job ID': item.job_id,
        'Job Name': item.job_name,
        Name: item.name,

        'About Applicant': item.about_applicant,
        Certificates: item.certificates.map(c => c.name).join(', '),

        Email: item.email,
        Phone: item.phone,

        'Resume Filename': item.resume_filename,
        'Resume Link': item.resume_link,
        'Link Download Resume': item.link_download_resume,
      };
    });

    const replacer = (key, value) => (value === null ? '' : value);
    const header = Object.keys(csv[0]);
    let csvData = csv.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csvData.unshift(header.join(','));
    csvData = csvData.join('\r\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'applications.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <ActionButton onClick={handleExport}>{t('Export')}</ActionButton>
    </>
  );
};

export default React.memo(CandidatesListPage);
