import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import api from '../../data/network';

export const QUERY_KEY = 'course-types';

/**
 * Get all available course types
 */
const useCourseTypes = () => {
  const req = useQuery([QUERY_KEY], () => api.trainingCenter.courses());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    courses: req.data?.results || [],
    ...req,
  };
};

export default useCourseTypes;
