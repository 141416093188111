import React from 'react';
import { useFormikContext } from 'formik';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import { useCouponPrograms } from '../../store/hooks/coupons';

const CouponProgramsInput = ({ error, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const { programs } = useCouponPrograms();
  return (
    <Box mb={2}>
      <Autocomplete
        value={props.value}
        name={props.name}
        options={programs}
        getOptionLabel={option => (option ? option.name : '')}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={params => (
          <TextField {...params} label={props.label} error={props.error} fullWidth variant="outlined" />
        )}
        onChange={(event, value) => setFieldValue(props.name, value ? value : '')}
      />
      {error && <div>{error}</div>}
    </Box>
  );
};

export default React.memo(CouponProgramsInput);
