import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Box, CircularProgress } from '@material-ui/core';

import useCourseTypes from '../../../hooks/Locations/useCourseTypes';

const SelectCourseTypes = ({ name, label, values, onChange, ...props }) => {
  const { courses, status } = useCourseTypes();

  if (status !== 'success') {
    return (
      <Box mb={2} display="flex" alignItems="center" justifyContent="center" {...props}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box mb={2} {...props}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <FormGroup>
          {courses.map(courseType => (
            <FormControlLabel
              key={courseType.id}
              control={
                <Checkbox
                  style={{ padding: '5px 5px 5px 14px' }}
                  checked={values.some(value => value.id === courseType.id)}
                  onChange={event => {
                    if (event.target.checked) {
                      onChange([...values, courseType]);
                    } else {
                      onChange(values.filter(value => value.id !== courseType.id));
                    }
                  }}
                />
              }
              label={courseType.name}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default React.memo(SelectCourseTypes);
