import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Receipt from '@material-ui/icons/GetApp';

const InvoicesMenu = ({ invoices }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    invoices.length > 0 && (
      <>
        <IconButton
          variant="outlined"
          size="medium"
          aria-controls="invoices-menu"
          aria-haspopup="true"
          onClick={handleClick}
          mh="auto"
        >
          <Receipt />
        </IconButton>
        <Menu id="invoices-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {invoices.map(invoice => (
            <MenuItem
              component="a"
              key={invoice.name}
              href={invoice.attachment}
              target="_blank"
              rel="noopener"
              color="secondary"
            >
              {invoice.name}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  );
};

export default React.memo(InvoicesMenu);
