import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';

import * as routes from '../../../navigation/routes';
import { useTranslation } from 'react-i18next';
import { getUser } from '../../../store/selectors/auth';

import { HiOutlineSupport } from 'react-icons/hi';
import AgreementsIcon from '@material-ui/icons/Description';
import CouponsIcon from '@material-ui/icons/Receipt';
import JobCandidatesIcon from '@material-ui/icons/Archive';
import JobOffersIcon from '@material-ui/icons/Flag';
import OrdersIcon from '@material-ui/icons/ListAlt';
import ProctorsIcon from '@material-ui/icons/PermIdentity';
import ProfileIcon from '@material-ui/icons/AccountBox';
import StudentsIcon from '@material-ui/icons/PeopleOutline';
import TeachersIcon from '@material-ui/icons/School';
import UsersIcon from '@material-ui/icons/People';
import VoucherOrdersIcon from '@material-ui/icons/Archive';
import VouchersIcon from '@material-ui/icons/LocalOffer';
import FolderIcon from '@material-ui/icons/Folder';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import { Box } from '@material-ui/core';

import NavList from './NavList';
import NavButton from './NavButton';
import { Inbox } from '@material-ui/icons';

export const getCategories = ({ t, user }) => [
  {
    label: t('Company'),
    pages: [
      {
        label: t('Profile'),
        icon: <ProfileIcon />,
        to: routes.PROFILE_SINGLE,
      },
      {
        label: t('Agreements'),
        icon: <AgreementsIcon />,
        to: routes.AGREEMENTS_LIST,
      },
      {
        label: t('Users'),
        icon: <UsersIcon />,
        to: routes.USERS_LIST,
      },
      {
        label: t('Locations'),
        icon: <ApartmentIcon />,
        to: routes.LOCATIONS,
      },
    ],
  },
  {
    label: t('CRM'),
    beta: true,
    renderCondition: user?.partner_type.includes('channel'),
    pages: [
      // {
      //   label: t('Leads'),
      //   icon: <ProfileIcon />,
      //   to: routes.LEADS_LIST,
      // },
      {
        label: t('Opportunities'),
        icon: <UsersIcon />,
        to: routes.OPPORTUNITIES_LIST,
      },
      {
        label: t('Partners'),
        icon: <ProfileIcon />,
        to: routes.PARTNERS_LIST,
      },
    ],
  },
  {
    label: t('Vouchers'),
    pages: [
      {
        label: t('Vouchers'),
        icon: <VouchersIcon />,
        to: routes.VOUCHERS_LIST,
      },
      {
        label: t('Voucher orders'),
        icon: <VoucherOrdersIcon />,
        to: routes.VOUCHER_ORDERS,
      },
      {
        label: t('Sale orders'),
        icon: <OrdersIcon />,
        to: routes.SALEORDERS_LIST,
      },
      {
        label: t('Manual order'),
        icon: <Inbox />,
        to: routes.MANUAL_ORDER,
      },
      {
        label: t('Coupons'),
        icon: <CouponsIcon />,
        to: routes.COUPONS_LIST,
      },
    ],
  },
  {
    // renderCondition: user?.partner_type.includes('training') || user?.partner_type.includes('academic'),
    label: t('Training'),
    pages: [
      {
        label: t('Teachers'),
        icon: <TeachersIcon />,
        to: routes.TEACHERS_LIST,
      },
      {
        label: t('Students'),
        icon: <StudentsIcon />,
        to: routes.STUDENTS_LIST,
      },
      {
        label: t('Learning Materials'),
        icon: <LibraryBooksIcon />,
        to: routes.MATERIALS_LIST,
      },
    ],
  },
  {
    renderCondition: user?.partner_type.includes('test_center'),
    label: t('Test Centers'),
    pages: [
      {
        label: t('Proctors'),
        icon: <ProctorsIcon />,
        to: routes.PROCTORS_LIST,
      },
      {
        label: t('Site Admins'),
        icon: <PersonPinCircleIcon />,
        to: routes.SITE_ADMINS_LIST,
      },
    ],
  },
  {
    label: t('Documents'),
    pages: [
      {
        label: t('Files'),
        icon: <FolderIcon />,
        to: routes.ALL_DOCUMENTS,
      },
    ],
  },

  {
    label: t('Hiring'),
    beta: true,
    pages: [
      {
        label: t('Job Offers'),
        icon: <JobOffersIcon />,
        to: routes.JOBS_LIST,
      },
      {
        label: t('Applications'),
        icon: <JobCandidatesIcon />,
        to: routes.CANDIDATES_LIST,
      },
    ],
  },
];

const Menu = () => {
  const user = useSelector(getUser);
  const location = useLocation();
  const { t } = useTranslation();
  const categories = React.useMemo(() => getCategories({ t, user }), [t, user]);

  const activeCategory = categories.find(category =>
    category.pages.some(page =>
      matchPath(location.pathname, {
        path: page.to,
        exact: false,
        strict: false,
      }),
    ),
  );

  const [openList, setOpenList] = useState(activeCategory?.label);

  return (
    <>
      {categories.map(({ label, pages, renderCondition }, index) => {
        if (typeof renderCondition === 'undefined' || !!renderCondition)
          return (
            <NavList key={index} label={label} open={openList} setOpen={setOpenList} testid={label.replace(' ', '-')}>
              {pages.map((page, index) => (
                <NavButton
                  key={index}
                  label={page.label}
                  icon={page.icon}
                  to={page.to}
                  beta={page.beta}
                  testid={`${label.replace(' ', '-')}-${page.label.replace(' ', '-')}`}
                />
              ))}
            </NavList>
          );
        else return null;
      })}
    </>
  );
};

export default React.memo(Menu);
