import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { lighten, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  table: {
    padding: theme.spacing(3),
    minWidth: 750,
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  toolbarTitle: {
    flex: '1 1 100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowHover: {
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const Table = ({ rows, defaultOrderBy, onRowClick, children, check, defaultOrderDir = 'asc', ...props }) => {
  const [order, setOrder] = React.useState(defaultOrderDir);
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy || children[0].props.field);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { t } = useTranslation();
  const classes = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  if (props.loading)
    return (
      <Box height="60vh" width="100%" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress color="primary" />
      </Box>
    );

  return (
    <>
      {props.Filters && (
        <Toolbar
          className={clsx(classes.toolbarRoot, {
            [classes.highlight]: selected.length > 0,
          })}
        >
          {selected.length > 0 ? (
            <Typography className={classes.toolbarTitle} color="inherit" variant="subtitle1" component="div">
              {selected.length} selected
            </Typography>
          ) : null}

          {selected.length > 0 ? (
            <Tooltip title={t('Delete')}>
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              {props.Filters && (
                <Box>
                  <props.Filters chip />
                </Box>
              )}

              <Box ml="auto">
                {props.Filters && (
                  <Tooltip title={t('Filter list')}>
                    <IconButton onClick={() => props.setOpenFilters(true)}>
                      <FilterListIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </>
          )}
        </Toolbar>
      )}
      <TableContainer>
        <MaterialTable className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              {check && (
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < rows.length}
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}
              {children.map((col, index) =>
                React.cloneElement(col, {
                  key: index,
                  activeOrder: orderBy === col.props.field,
                  order: order,
                  sortHandler: createSortHandler,
                }),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => {
                const isItemSelected = isSelected(row.id);

                return (
                  <TableRow
                    hover
                    key={rowIndex}
                    onClick={event => onRowClick && onRowClick(row.id, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    classes={onRowClick && { hover: classes.rowHover }}
                  >
                    {check && (
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} onClick={event => handleSelect(event, row.id)} />
                      </TableCell>
                    )}
                    {children.map((col, index) => {
                      return index === 0 ? (
                        <TableCell key={col.props.field} component="th" scope="row">
                          {col.props.renderDepends
                            ? col.props.renderDepends(row)
                            : col.props.render
                            ? col.props.render(row[col.props.field])
                            : row[col.props.field]}
                        </TableCell>
                      ) : (
                        <TableCell key={col.props.field}>
                          {col.props.renderDepends
                            ? col.props.renderDepends(row)
                            : col.props.render
                            ? col.props.render(row[col.props.field])
                            : row[col.props.field]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </MaterialTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(event, newPage) => {
          setPage(newPage);
        }}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        {...props.tableProps}
      />
    </>
  );
};

export default React.memo(Table);
