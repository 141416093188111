import { createSelector } from 'reselect';

export const getOffers = createSelector(
  state => state.generic.jobs.items,
  items => items,
);

export const getApplications = createSelector(
  state => state.generic.applications.items,
  items => items,
);
