import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/ArrowBack';

import User from './Sidebar/User';
import CartButton from './CartButton';

import { getUser } from '../../store/selectors/auth';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  appBar: {
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      borderTop: `30px solid ${theme.palette.primary.dark}`,
      boxShadow: 'none',
    },
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

const Navbar = ({ title, actions, onMenuClicked, back, backPath }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const user = useSelector(getUser);

  const handleBack = useCallback(() => {
    if (backPath) {
      history.push(backPath);
    } else {
      history.goBack();
    }
  }, [history, backPath]);

  return (
    user && (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {!back && (
            <Hidden smUp>
              <IconButton
                edge="start"
                onClick={onMenuClicked}
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                data-testid="drawer-btn"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          )}
          {back && (
            <IconButton
              edge="start"
              onClick={handleBack}
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <BackIcon />
            </IconButton>
          )}
          <Box display="flex" alignItems="center" flexDirection="row" flexGrow="2">
            <Typography variant="h1">{title}</Typography>
            {actions}
          </Box>
          <LanguageSwitcher />
          <CartButton />
          <User />
        </Toolbar>
      </AppBar>
    )
  );
};

Navbar.propTypes = {
  title: PropTypes.string,
  onMenuClicked: PropTypes.func,
};

export default React.memo(Navbar);
