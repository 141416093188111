import React from 'react';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

const TextareaInput = ({ error, boxProps, ...props }) => {
  return (
    <Box mb={2} {...boxProps}>
      <TextField
        variant="outlined"
        multiline
        rows={3}
        rowsMax={3}
        fullWidth
        error={!!error}
        helperText={error}
        {...props}
      />
    </Box>
  );
};

export default React.memo(TextareaInput);
