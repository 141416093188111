import React from 'react';
import { useTranslation } from 'react-i18next';

import Page from '../../components/Generic/Page';

import AgreementsDetail from '../../components/Agreements/AgreementsDetail';
import AgreementsTable from '../../components/Agreements/AgreementsTable';
import AgreementsActions from '../../components/Agreements/AgreementsActions';

const AgreementsListPage = () => {
  const { t } = useTranslation();

  const labels = {
    title: t('Agreements'),
  };

  return (
    <Page
      ep={'agreements'}
      subtitle={t('List of agreements between LPI and your company')}
      labels={labels}
      Detail={AgreementsDetail}
      Table={AgreementsTable}
      Actions={AgreementsActions}
    />
  );
};

export default React.memo(AgreementsListPage);
