import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchUsers, createUser, updateUser, toggleUserState, changeUserRole } from '../reducers/UsersReducer';
import i18n from '../../locales';
import api from '../../data/network';
import { setMessage } from '../reducers/SnackReducer';

function* fetchUsersSaga({ payload }) {
  try {
    const params = { offset: 0, limit: 9999 };
    const users = yield call([api.users, api.users.search], params);

    yield put(fetchUsers.success(users));
  } catch (e) {
    yield put(fetchUsers.failure(i18n.t('Unable to fetch users')));
  }
}

function* createUserSaga({ payload }) {
  try {
    const user = yield call([api.users, api.users.create], payload);

    yield put(createUser.success(user));
    yield put(fetchUsers());
    yield put(setMessage(i18n.t('Invite sent')));
  } catch (e) {
    yield put(setMessage(i18n.t('Unable to create user')));
    yield put(createUser.failure(i18n.t('Unable to create user')));
  }
}

function* updateUserSaga({ payload }) {
  try {
    const user = yield call([api.users, api.users.update], payload.id, payload);

    yield put(updateUser.success(user));
    yield put(setMessage(i18n.t('User updated')));
  } catch (e) {
    yield put(setMessage(i18n.t('Unable to update user')));
    yield put(updateUser.failure(i18n.t('Unable to update user')));
  }
}

function* toggleUserStateSaga({ payload }) {
  try {
    yield call([api.users, api.users.toggleUserState], { id: payload });
    const users = yield call([api.users, api.users.search], {
      offset: 0,
      limit: 9999,
    });
    yield put(toggleUserState.success(users));
  } catch (e) {
    console.error(e);
    yield put(toggleUserState.failure(i18n.t('Unable to toggle user state')));
  }
}

function* changeUserRoleSaga({ payload }) {
  try {
    const user = yield call([api.users, api.users.changeRole], payload);
    yield put(changeUserRole.success(user));
    yield put(setMessage(i18n.t('User role updated')));
  } catch (e) {
    yield put(setMessage(i18n.t('Unable to change user role')));
    yield put(changeUserRole.failure(i18n.t('Unable to change user role')));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchUsers.TRIGGER], fetchUsersSaga)]);
  yield all([yield takeLatest([createUser.TRIGGER], createUserSaga)]);
  yield all([yield takeLatest([updateUser.TRIGGER], updateUserSaga)]);
  yield all([yield takeLatest([toggleUserState.TRIGGER], toggleUserStateSaga)]);
  yield all([yield takeLatest([changeUserRole.TRIGGER], changeUserRoleSaga)]);
}
