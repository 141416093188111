import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../data/network';

import { QUERY_KEY } from './useStudents';

/** Get a single student */
const useStudent = id => {
  const req = useQuery([QUERY_KEY, id], () => api.students.get(id));

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    student: req.data || {},
    ...req,
  };
};

export default useStudent;
