import React from 'react';
import { useTranslation } from 'react-i18next';

import Page from '../../components/Generic/Page';

import LeadsActions from '../../components/Leads/LeadsActions';
import LeadsDetail from '../../components/Leads/LeadsDetail';
import LeadsForm from '../../components/Leads/LeadsForm';
import LeadsTable from '../../components/Leads/LeadsTable';

const LeadsListPage = () => {
  const { t } = useTranslation();

  const labels = {
    title: t('Leads'),
    createLabel: t('Create lead'),
    updateLabel: t('Update lead'),
    confirmDeleteLabel: t('Delete this lead?'),
  };

  return (
    <Page
      ep={'leads'}
      subtitle={t('Create and manage your leads')}
      labels={labels}
      Actions={LeadsActions}
      Detail={LeadsDetail}
      Form={LeadsForm}
      Table={LeadsTable}
    />
  );
};

export default React.memo(LeadsListPage);
