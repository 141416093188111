import _values from 'lodash/values';
import _keyBy from 'lodash/keyBy';

import React from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import Layout from '../../../components/App/Layout';
import Snackbar from '../../../components/Common/Snackbar';

import DataTable from './Table';
import DataTableDetail from './Detail';
import DataTableFilters from './Filters';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  customCardRoot: {
    padding: 0,
  },
  table: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
}));

const CouponsPage = ({ page, items, columns, filters, detail, detailLink, selectionActions }) => {
  const classes = useStyles();

  const { path } = useRouteMatch();
  let history = useHistory();

  const detailRouteMatch = useRouteMatch({ path: path + '/:id/' });

  const [shownItems, setShownItems] = React.useState([]);
  const [id, setId] = React.useState();
  const item = _keyBy(items, 'id')[id];

  React.useEffect(() => setShownItems(_values(items)), [setShownItems, items]);

  const handleRowClick = React.useCallback((id, row) => history.push(detailLink ? detailLink(row) : path + '/' + id), [
    history,
    path,
    detailLink,
  ]);

  const handleCloseDetail = React.useCallback(() => {
    setId(null);
    history.push(path);
  }, [history, path]);

  React.useEffect(() => setShownItems(filters.execute(_values(items))), [filters, items]);

  return (
    <Layout title={page.title} back={!!detailRouteMatch} backPath={path}>
      {page.actions && (
        <Box display="flex" alignItems="center" pl={2} justifyContent="space-between" m={2} mt={4} flexDirection="row">
          <Typography variant="subtitle1">{page.subtitle}</Typography>
          {page.actions()}
        </Box>
      )}

      {filters && <DataTableFilters controller={filters} />}

      <Grid className={classes.table} container spacing={3} display="flex">
        <Hidden mdDown={!!detailRouteMatch}>
          <Grid item xs={12} lg>
            <Card variant="outlined">
              <CardContent classes={{ root: classes.customCardRoot }}>
                <DataTable
                  rows={shownItems}
                  columns={columns}
                  filters={filters.config}
                  selectionActions={selectionActions}
                  onRowClick={(detail || detailLink) && handleRowClick}
                />
              </CardContent>
            </Card>
          </Grid>
        </Hidden>
        {detail && (
          <Switch>
            <Route path={path + '/:id'}>
              <Grid item xs={12} lg={4}>
                <DataTableDetail
                  item={item}
                  headerProps={detail.headerProps}
                  content={detail.content}
                  actions={detail.actions}
                  onRoute={setId}
                  onCloseClick={handleCloseDetail}
                />
              </Grid>
            </Route>
          </Switch>
        )}
      </Grid>
      <Snackbar />
    </Layout>
  );
};

export default React.memo(CouponsPage);
