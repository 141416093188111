import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  qtyInputBase: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: 18,
  },
  qtyInput: {
    textAlign: 'center',
  },
  qtyButton: {
    backgroundColor: '#ededed',
  },
}));

const ProductQuantity = ({ quantity, setQuantity, ...props }) => {
  const classes = useStyles();

  const increaseQuantity = () => setQuantity(quantity + 1);
  const decreaseQuantity = () => setQuantity(Math.max(1, quantity - 1));

  return (
    <Box display="flex" alignItems="center" {...props}>
      <IconButton classes={{ root: classes.qtyButton }} onClick={decreaseQuantity}>
        <RemoveIcon style={{ fontSize: 12 }} />
      </IconButton>
      <Box width={66}>
        <InputBase
          className={classes.qtyInputBase}
          value={quantity}
          onChange={ev => parseInt(ev.target.value) && setQuantity(parseInt(ev.target.value))}
          inputProps={{ className: classes.qtyInput }}
        />
      </Box>
      <IconButton classes={{ root: classes.qtyButton }} onClick={increaseQuantity}>
        <AddIcon style={{ fontSize: 12 }} />
      </IconButton>
    </Box>
  );
};

export default React.memo(ProductQuantity);
