import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import * as Field from '../Common/Fields';
import Detail from '../Generic/Detail';

const VouchersDetail = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Detail
      typeName={t('voucher')}
      titleComponent={item => (
        <Box display="flex" flexDirection="column">
          <Typography variant="h5" color="textPrimary">
            {item.product_id_name}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            {item.code}
          </Typography>
        </Box>
      )}
      readonly
      {...props}
    >
      <Field.Text label={t('Buyer')} field="buyer_id_name" />
      <Field.Text label={t('Assigned To')} field="assigned_to_name" />
      <Field.Text label={t('Expiring Date')} field="expiration_date" />
    </Detail>
  );
};

export default React.memo(VouchersDetail);
