import _keyBy from 'lodash/keyBy';
import _cloneDeep from 'lodash/cloneDeep';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const ROUTE = 'Generic.Route';
export const FETCH = 'Generic.Fetch';
export const CREATE = 'Generic.Create';
export const UPDATE = 'Generic.Update';
export const DELETE = 'Generic.Delete';

const metaCreator = (payload, endpoint) => {
  return endpoint ? { endpoint } : {};
};

export const route = createRoutine(ROUTE, null, metaCreator);
export const fetchItems = createRoutine(FETCH, null, metaCreator);
export const createItem = createRoutine(CREATE, null, metaCreator);
export const updateItem = createRoutine(UPDATE, null, metaCreator);
export const deleteItem = createRoutine(DELETE, null, metaCreator);

const LEADS_STATE = { items: {} };
const OPPORTUNITIES_STATE = { items: {} };
const TEACHERS_STATE = { items: {} };
const PROCTORS_STATE = { items: {} };
const STUDENTS_STATE = { items: {} };
const USERS_STATE = { items: {} };
const AGREEMENTS_STATE = { items: {} };
const PARTNERS_STATE = { items: {} };
const NEWS_STATE = { items: {} };
const JOBS_STATE = { items: {} };
const SKILLS_STATE = { items: {} };
const PEOPLE_STATE = { items: {} };
const APPLICATIONS_STATE = { items: {} };
const VOUCHERS_STATE = { items: {} };
const VOUCHER_ORDERS_STATE = { items: {} };

const INITIAL_STATE = {
  ep: null,
  loading: false,
  leads: LEADS_STATE,
  opportunities: OPPORTUNITIES_STATE,
  teachers: TEACHERS_STATE,
  proctors: PROCTORS_STATE,
  students: STUDENTS_STATE,
  users: USERS_STATE,
  agreements: AGREEMENTS_STATE,
  partners: PARTNERS_STATE,
  news: NEWS_STATE,
  jobs: JOBS_STATE,
  skills: SKILLS_STATE,
  people: PEOPLE_STATE,
  applications: APPLICATIONS_STATE,
  vouchers: VOUCHERS_STATE,
  voucherOrders: VOUCHER_ORDERS_STATE,
};

// Reducer
const genericReducer = handleActions(
  {
    [route.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.ep = payload;
      }),
    // fetch
    [fetchItems.TRIGGER]: (state, action) =>
      produce(state, draft => {
        draft.loading = true;
      }),
    [fetchItems.SUCCESS]: (state, { payload, meta }) =>
      produce(state, draft => {
        draft.loading = false;
        draft[meta.endpoint || draft.ep].items = _keyBy(payload.results, 'id');
      }),
    [fetchItems.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.loading = false;
      }),
    // create
    [createItem.TRIGGER]: state =>
      produce(state, draft => {
        draft.loading = true;
      }),
    [createItem.SUCCESS]: (state, { payload, meta }) =>
      produce(state, draft => {
        draft.loading = false;
        draft[meta.endpoint || draft.ep].items[payload.id] = payload;
      }),
    [createItem.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.loading = false;
      }),
    // update
    [updateItem.TRIGGER]: state =>
      produce(state, draft => {
        draft.loading = true;
      }),
    [updateItem.SUCCESS]: (state, { payload, meta }) =>
      produce(state, draft => {
        draft.loading = false;
        draft[meta.endpoint || draft.ep].items[payload.id] = payload;
      }),
    [updateItem.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.loading = false;
      }),
    // delete
    [deleteItem.TRIGGER]: state =>
      produce(state, draft => {
        draft.loading = true;
      }),
    [deleteItem.SUCCESS]: (state, { payload, meta }) =>
      produce(state, draft => {
        draft.loading = false;
        let items = _cloneDeep(draft[draft.ep].items);
        delete items[payload.id];
        draft[meta.endpoint || draft.ep].items = items;
      }),
    [deleteItem.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.loading = false;
      }),
  },
  INITIAL_STATE,
);

export default genericReducer;
