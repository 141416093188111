import { createSelector } from 'reselect';

export const getUser = createSelector(
  state => state.password.user,
  user => user,
);

export const getServices = createSelector(
  state => state.password.services,
  services => services,
);

export const getEmailRequest = createSelector(
  state => state.password.requests.email,
  request => request,
);

export const getTokenCheckRequest = createSelector(
  state => state.password.requests.token,
  request => request,
);

export const getResetRequest = createSelector(
  state => state.password.requests.reset,
  request => request,
);
