import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCart,
  fetchVoucherProducts,
  fetchJobOffersProducts,
  fetchCheckoutConfig,
  fetchSaleOrders,
  openCart,
  closeCart,
} from '../reducers/ShopReducer';
import {
  selectCart,
  getVoucherProducts,
  getJobOffersProducts,
  selectCheckoutConfig,
  getCartIsOpen,
  getSaleOrders,
  getSaleOrdersRequests,
} from '../selectors/shop';

export const useVoucherProducts = () => {
  const dispatch = useDispatch();
  const products = useSelector(getVoucherProducts);

  React.useEffect(() => {
    dispatch(fetchVoucherProducts());
  }, [dispatch]);

  return products;
};

export const useJobOffersProducts = () => {
  const dispatch = useDispatch();
  const products = useSelector(getJobOffersProducts);

  React.useEffect(() => {
    dispatch(fetchJobOffersProducts());
  }, [dispatch]);

  return products;
};

export const useSaleOrders = () => {
  const dispatch = useDispatch();
  const saleOrders = useSelector(getSaleOrders);
  const requests = useSelector(getSaleOrdersRequests);

  React.useEffect(() => {
    dispatch(fetchSaleOrders());
  }, [dispatch]);

  return { saleOrders, requests };
};

export const useCartButton = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getCartIsOpen);

  const handleOpenCart = () => dispatch(openCart());
  const handleCloseCart = () => dispatch(closeCart());

  // const isOpen = Boolean(anchorEl)

  return { isOpen, openCart: handleOpenCart, closeCart: handleCloseCart };
};
