import React from 'react';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSkills } from '../../store/reducers/SkillsReducer';
import { getSkills } from '../../store/selectors/skills';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import useSkills from '../../hooks/Jobs/useSkills';

const SkillsInput = ({ error, ...props }) => {
  const { setFieldValue } = useFormikContext();

  const { status, skills } = useSkills();

  return (
    <Box mb={2}>
      <Autocomplete
        freeSolo
        multiple
        disabled={status !== 'success'}
        value={props.value || []}
        name={props.name}
        options={skills}
        getOptionLabel={option => option.name || ''}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip key={index} variant="outlined" label={option.name} {...getTagProps({ index })} />
          ))
        }
        renderInput={params => (
          <TextField {...params} label={props.label} error={props.error} fullWidth variant="outlined" />
        )}
        onChange={(event, values) => {
          // Converts custom skills to Skill obj
          values = values.map(value => {
            if (typeof value === 'string') {
              return { id: null, name: value };
            } else return value;
          });

          setFieldValue(props.name, values || []);
        }}
      />
      {error && <div>{error}</div>}
    </Box>
  );
};

export default React.memo(SkillsInput);
