import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import FilterListIcon from '@material-ui/icons/FilterList';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 250,
  },
  filtersWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const TableFilters = ({ controller, chips, renderFilters }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [showFilters, setShowFilters] = React.useState(false);

  return (
    <Toolbar className={classes.toolbarRoot}>
      <Box>
        <Grid container direction="row" spacing={1}>
          {Object.keys(controller.current).map((filterName, index) => {
            return (
              <Grid item key={index}>
                <Chip
                  label={<span>{chips[filterName](controller.current[filterName])}</span>}
                  onDelete={() => controller.setOne(filterName, '')}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box ml="auto">
        <Tooltip title={t('Filters')}>
          <IconButton onClick={() => setShowFilters(true)} data-testid="drawer-button">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Drawer
        BackdropProps={{ invisible: true }}
        anchor="right"
        classes={{ paper: classes.drawer }}
        open={showFilters}
        onClose={() => setShowFilters(false)}
      >
        <Box p={3} className={classes.filtersWrapper}>
          <Box>
            <Typography variant="h4" gutterBottom>
              {t('Filters')}
            </Typography>
            {renderFilters(controller.current, controller.setOne)}
          </Box>
        </Box>
      </Drawer>
    </Toolbar>
  );
};

export default React.memo(TableFilters);
