import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../data/network';

export const QUERY_KEY = 'students';

/** Get all the students of the logged partner */
const useStudents = () => {
  const req = useQuery([QUERY_KEY], () => api.students.search());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    students: req.data?.results || [],
    ...req,
  };
};

export default useStudents;
