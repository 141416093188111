import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  common: {
    color: '#ffffff',
    minWidth: '80px',
  },

  squared: {
    borderRadius: '4px',
  },
}));

const CommonChip = ({ mapping, squared, ...props }) => {
  const classes = useStyles();
  props.className = classes.common;

  if (squared) {
    props.className = `${props.className} ${classes.squared}`;
  }

  if (mapping) {
    props.style = { ...mapping[props.label.toLowerCase()] };
  }

  return <Chip {...props} />;
};

export default React.memo(CommonChip);
