import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { channelAssignVoucher } from '../../store/reducers/VouchersReducer';
import { usePartners } from '../../store/hooks/partners';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AutoCompleteFilter from '../Common/Filters/AutoCompleteFilter';

const AssignChannelModal = ({ item, open, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedPartner, setSelectedPartner] = React.useState();
  const { partners } = usePartners();

  const handleAssign = React.useCallback(() => {
    let partner = selectedPartner ? partners.find(x => x.id === parseInt(selectedPartner)) : null;
    partner && dispatch(channelAssignVoucher({ voucher: item, partner }));
    onClose();
  }, [dispatch, item, onClose, selectedPartner, partners]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>{t('Assign voucher')}</DialogTitle>
      <DialogContent>
        <AutoCompleteFilter
          name="assigned_to_id"
          label={t('Assignee')}
          value={selectedPartner ? partners.find(x => x.id === parseInt(selectedPartner)) : null}
          options={partners}
          optionLabel={option => (option.name ? option.name : '')}
          onChange={(event, value) => {
            setSelectedPartner(value ? value.id : null);
          }}
        />
        <DialogActions>
          <Button onClick={handleAssign} color="secondary" disabled={!selectedPartner}>
            {t('Confirm')}
          </Button>
          <Button onClick={onClose} color="secondary">
            {t('Cancel')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(AssignChannelModal);
