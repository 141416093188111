import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { checkToken, resetPassword } from '../../store/reducers/PasswordReducer';
import { getUser, getServices, getTokenCheckRequest, getResetRequest } from '../../store/selectors/password';
import { EMAIL_FORM } from '../../navigation/routes';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import BackLink from '../../components/Password/BackLink';
import Page from '../../components/Password/Page';
import ResetForm from '../../components/Password/ResetForm';
import Spinner from '../../components/Password/Spinner';

const ResetPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const services = useSelector(getServices);
  const request = useSelector(getResetRequest);
  const tokenCheck = useSelector(getTokenCheckRequest);
  const [token, setToken] = React.useState(null);
  const match = useRouteMatch();

  React.useEffect(() => {
    setToken(match.params.token);
    dispatch(checkToken({ token: match.params.token }));
  }, [dispatch, match.params.token]);

  const handleSubmit = React.useCallback(
    values => {
      dispatch(resetPassword({ token, ...values }));
    },
    [dispatch, token],
  );

  if (tokenCheck.loading) {
    return (
      <Page title={t('Welcome')} subtitle={t('Getting your info...')}>
        <Spinner />
      </Page>
    );
  }

  if (request.success) {
    return (
      <Page
        title={t('Your account is ready!')}
        subtitle={t('Your password was set successfully.\nYou can now access your services using the links below.')}
      >
        {services && (
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {t('Services')}
              </ListSubheader>
            }
          >
            {services.map((service, index) => (
              <ListItem key={index} button component="a" href={service.url}>
                <ListItemText primary={service.name} />
                <ListItemIcon>
                  <ArrowForwardIcon />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        )}
      </Page>
    );
  }

  if (!tokenCheck.success || (request.error && request.error.token)) {
    return (
      <Page title={t('Password reset')} subtitle={t('Wrong or expired link')} subtitleProps={{ color: 'error' }}>
        <BackLink label={t('Go Back')} to={EMAIL_FORM} />
      </Page>
    );
  }

  return (
    <Page title={t('Welcome') + ' ' + user.name} subtitle={t('Enter your new password and confirm.')} user={user}>
      {tokenCheck.loading ? (
        <Spinner />
      ) : (
        <ResetForm error={request.error} loading={request.loading} onSubmit={handleSubmit} />
      )}
    </Page>
  );
};

export default React.memo(ResetPage);
