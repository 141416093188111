import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import _values from 'lodash/values';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  Hidden,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import { Link as RouteLink } from 'react-router-dom';

import { JOB_PRODUCT_LIST, JOB_CREATE_OFFER } from '../../navigation/routes';

import { getProfile } from '../../store/selectors/auth';
import { setMessage } from '../../store/reducers/SnackReducer';

import usePartnerOffers from '../../hooks/Jobs/usePartnerOffers';
import usePartnerOffersMutations from '../../hooks/Jobs/usePartnerOffersMutations';

import OffersDetail from '../../components/Hiring/OffersDetail';
import OffersTable from '../../components/Hiring/OffersTable';
import ActionButton from '../../components/Common/ActionButton';
import Layout from '../../components/App/Layout';
import Snackbar from '../../components/Common/Snackbar';

const PAGE_SIZE = 10;

const useStyles = makeStyles(theme => ({
  customCardRoot: {
    padding: 0,
  },
  table: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  tabs: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
}));

const OffersListPage = () => {
  const [page, setPage] = useState(0);
  const [item, setItem] = useState();
  const isUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const classes = useStyles();
  const profile = useSelector(getProfile);
  const { t } = useTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const detail_route_match = useRouteMatch({
    path: path + '/:id/',
  });
  const { status, offers, data } = usePartnerOffers({ pageSize: PAGE_SIZE, page });
  const { deleteOffer, withdrawOffer, toggleOfferStatus } = usePartnerOffersMutations();
  const [dialog, setDialog] = useState({ open: false });

  const handleRowClick = useCallback(
    (id, item) => {
      history.push(path + '/' + id);
      setItem(item);
    },
    [path],
  );

  const handleCloseDetail = useCallback(() => {
    setItem();
    history.push(path);
  }, [path]);

  const handleDeleteConfirm = useCallback(() => {
    setDialog({
      title: t('Are you sure?'),
      content: t("Are you sure you cancel this job offer? You can't go back"),
      okText: t('Confirm'),
      open: true,
      onConfirm: handleDelete,
    });
  }, [item]);

  const handleWithdrawConfirm = useCallback(() => {
    setDialog({
      title: t('Are you sure?'),
      content: t('Are you sure you want to withdraw this job offer?'),
      okText: t('Confirm'),
      open: true,
      onConfirm: handleWithdraw,
    });
  }, [item]);

  const handleDelete = useCallback(() => {
    deleteOffer
      .mutateAsync({ id: item.id })
      .then(() => {
        setItem();
        history.push(path);
        dispatch(setMessage(t('Offer deleted')));
      })
      .catch(() => {
        dispatch(setMessage(t('An error occurred during the request. Try again')));
      });
  }, [item]);

  const handleWithdraw = useCallback(() => {
    withdrawOffer
      .mutateAsync(item)
      .then(() => {
        setItem();
        history.push(path);
        dispatch(setMessage(t('Offer withdrawn')));
      })
      .catch(() => {
        dispatch(setMessage(t('An error occurred during the request. Try again')));
      });
  }, [item]);

  const handleToggleOfferStatus = useCallback(() => {
    const currentStatus = item.status;
    toggleOfferStatus
      .mutateAsync({ id: item.id })
      .then(() => {
        handleCloseDetail();
        if (currentStatus === 'draft') {
          dispatch(setMessage(t('Your job offer has been submitted for review')));
        }

        if (currentStatus === 'review') {
          dispatch(setMessage(t('Your job offer has been reverted as a draft')));
        }
      })
      .catch(() => {
        dispatch(setMessage(t('An error occurred during the request. Try again')));
      });
  }, [item]);

  const handleDuplicate = useCallback(() => {
    history.push(`${JOB_CREATE_OFFER}/${item.id}?duplicate=true`);
  }, [item]);

  useEffect(() => {
    if (detail_route_match?.params?.id) {
      setItem(offers.find(offer => offer.id === detail_route_match?.params?.id));
    }
  }, [offers]);

  return (
    <Layout title={t('Offers')} back={!!detail_route_match} backPath={path}>
      <Box display="flex" justifyContent="space-between" pl={2} m={2} mt={4} flexDirection="row">
        <Typography variant="subtitle1">{t('Create and manage your job offers')}</Typography>
        <ActionButton component={RouteLink} to={JOB_CREATE_OFFER}>
          {t('Create offer')}
        </ActionButton>
      </Box>

      <Grid classes={{ root: classes.table }} container spacing={3} display="flex">
        <Hidden mdDown={!!detail_route_match}>
          <Grid item xs={12} md={!!detail_route_match ? 8 : 12}>
            <Card variant="outlined">
              <CardContent classes={{ root: classes.customCardRoot }}>
                <OffersTable
                  rows={offers}
                  onRowClick={handleRowClick}
                  tableProps={{
                    rowsPerPage: PAGE_SIZE,
                    rowsPerPageOptions: [],
                    page,
                    onChangePage: (_e, newPage) => {
                      handleCloseDetail();
                      setPage(newPage);
                    },
                    count: data?.total_count || 0,
                  }}
                  loading={status === 'loading'}
                />
              </CardContent>
            </Card>
          </Grid>
        </Hidden>
        <Switch>
          <Route path={path + '/:id'}>
            <Grid item xs={12} lg={4}>
              <OffersDetail
                item={item}
                Actions={({ item }) => (
                  <Grid container direction={isUpMd ? 'row' : 'column'}>
                    {(() => {
                      if (toggleOfferStatus.status === 'loading') {
                        return (
                          <Button color="secondary" disabled>
                            <CircularProgress size={20} color="primary" />
                          </Button>
                        );
                      }

                      if (item.status === 'review') {
                        return (
                          <Button onClick={handleToggleOfferStatus} color="secondary">
                            {t('Revert offer to draft')}
                          </Button>
                        );
                      }

                      if (profile.credit_job < 1) {
                        return (
                          <Button color="secondary" component={RouteLink} to={JOB_PRODUCT_LIST}>
                            {t('Buy credits')}
                          </Button>
                        );
                      }

                      if (profile.credit_job >= 1) {
                        if (item.status === 'draft') {
                          return (
                            <Button onClick={handleToggleOfferStatus} variant="contained" color="primary">
                              {t('Submit for review')}
                            </Button>
                          );
                        }
                      }
                    })()}

                    <Button color="secondary" onClick={handleDuplicate} style={{ marginLeft: 5 }}>
                      {t('Duplicate')}
                    </Button>

                    {(() => {
                      if (item.status != 'publish') {
                        return (
                          <Box ml={'auto'}>
                            <Button
                              onClick={() => history.push(JOB_CREATE_OFFER + '/' + item.id)}
                              color="secondary"
                              disabled={item.status === 'review'}
                            >
                              {t('Edit')}
                            </Button>

                            <Button onClick={handleDeleteConfirm} color="secondary" disabled={item.status === 'review'}>
                              {deleteOffer.status === 'loading' ? (
                                <CircularProgress size={20} color="primary" />
                              ) : (
                                t('Delete')
                              )}
                            </Button>
                          </Box>
                        );
                      } else {
                        return (
                          <Box ml={'auto'}>
                            <Button onClick={handleWithdrawConfirm} color="secondary">
                              {withdrawOffer.status === 'loading' ? (
                                <CircularProgress size={20} color="primary" />
                              ) : (
                                t('Withdraw offer')
                              )}
                            </Button>
                          </Box>
                        );
                      }
                    })()}
                  </Grid>
                )}
                onRoute={() => {}}
                onCloseClick={handleCloseDetail}
              />
            </Grid>
          </Route>
        </Switch>
      </Grid>

      <Dialog
        onClose={() => {
          setDialog({ ...dialog, open: false });
        }}
        open={dialog.open}
      >
        <DialogTitle id="simple-dialog-title">{dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialog({ ...dialog, open: false });
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              dialog.onConfirm();
              setDialog({ ...dialog, open: false });
            }}
            color="primary"
            autoFocus
            variant="contained"
          >
            {dialog.okText}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar />
    </Layout>
  );
};

export default OffersListPage;
