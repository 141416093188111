import React from 'react';
import { useTranslation } from 'react-i18next';

import Page from '../../components/Generic/Page';

import VoucherOrdersTable from '../../components/Vouchers/VoucherOrdersTable';
import VoucherOrdersDetail from '../../components/Vouchers/VoucherOrdersDetail';
import VoucherOrdersActions from '../../components/Vouchers/VoucherOrdersActions';

const VoucherOrdersPage = () => {
  const { t } = useTranslation();

  const labels = {
    title: t('Voucher Orders'),
  };

  return (
    <Page
      ep={'voucherOrders'}
      subtitle={t('List of voucher purchased')}
      labels={labels}
      Detail={VoucherOrdersDetail}
      Table={VoucherOrdersTable}
      Actions={VoucherOrdersActions}
    />
  );
};

export default React.memo(VoucherOrdersPage);
