import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchStages, getStage } from '../reducers/StagesReducer';
import i18n from '../../locales';
import api from '../../data/network';

function* fetchStagesSaga({ payload }) {
  try {
    const params = { offset: 0, limit: 9999 };

    // effettua la chiamata di rete per leggere i stages
    const stages = yield call([api.stages, api.stages.search], params);

    // manda la action success
    yield put(fetchStages.success(stages));
  } catch (e) {
    console.error(e);
    // manda la action failure
    yield put(fetchStages.failure(i18n.t('Unable to fetch stages')));
  }
}

function* getStageSaga({ payload }) {
  try {
    // effettua la chiamata di rete per leggere i stages
    const stage = yield call([api.stages, api.stages.get], payload.id);

    // manda la action success
    yield put(getStage.success(stage));
  } catch (e) {
    console.error(e);
    // manda la action failure
    yield put(getStage.failure(i18n.t('Unable to get stage')));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchStages.TRIGGER], fetchStagesSaga)]);
  yield all([yield takeLatest([getStage.TRIGGER], getStageSaga)]);
}
