import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Button, Dialog, DialogTitle, Chip, DialogContent, DialogActions, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { useFilters } from '../../store/hooks/filters';
import { searchText } from '../../components/Common/Filters/TextFilter';
import * as Field from '../../components/Common/Fields';
import EmailTitle from '../../components/Common/EmailTitle';
import DataTable from '../../components/Common/DataTable/Page';
import TextFilter from '../../components/Common/Filters/TextFilter';
import StatusFilter from '../../components/Students/Filters/StatusFilter';
import AddStudentAction from '../../components/Students/AddStudentAction';
import InvitationStatusChip from '../../components/Students/InvitationStatusChip';
import useStudents from '../../hooks/Students/useStudents';
import useStudentsMutations from '../../hooks/Students/useStudentsMutations';
import { setMessage } from '../../store/reducers/SnackReducer';
import PartnerTagsAutocomplete from '../../components/Common/Filters/PartnerTagsAutocomplete';
import EditStudentForm from '../../components/Students/EditStudentForm';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MultiTagDialog from '../../components/Common/MultiTagDialog';

const UserListPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const [dialog, setDialog] = React.useState({ open: false, title: null, content: null });
  const { students } = useStudents();
  const { createStudent, resendInvite, deleteStudent, updateStudent } = useStudentsMutations();

  const filters = useFilters({
    name: {
      chip: value => `${t('Name')}: ${value}`,
      component: (currentFilters, setFilter) => (
        <TextFilter label={t('Name')} field="name" currentFilters={currentFilters} setFilter={setFilter} />
      ),
    },
    email: {
      chip: value => `${t('Email')}: ${value}`,
      component: (currentFilters, setFilter) => (
        <TextFilter label={t('Email')} field="email" currentFilters={currentFilters} setFilter={setFilter} />
      ),
    },
    lpi_id: {
      chip: value => `${t('LPI ID')}: ${value}`,
      component: (currentFilters, setFilter) => (
        <TextFilter label={t('LPI ID')} field="lpi_id" currentFilters={currentFilters} setFilter={setFilter} />
      ),
    },
    tags: {
      chip: values => `${t('Tags')}: ${values.map(value => value.name).join(', ')}`,
      component: (currentFilters, setFilter) => (
        <PartnerTagsAutocomplete
          value={currentFilters['tags'] || []}
          onChange={(_, newValue) => setFilter('tags', newValue?.length > 0 ? newValue : '')}
        />
      ),
    },
    status: {
      chip: value => `${t('Status')}: ${value}`,
      component: (currentFilters, setFilter) => <StatusFilter currentFilters={currentFilters} setFilter={setFilter} />,
    },
  });

  const executeFilters = items => {
    let filtered = [...items];
    if ('name' in filters.current) {
      filtered = filtered.filter(item => searchText(item.partner_name, filters.current.name));
    }
    if ('email' in filters.current) {
      filtered = filtered.filter(item => searchText(item.partner_email, filters.current.email));
    }
    if ('lpi_id' in filters.current) {
      filtered = filtered.filter(item => searchText(item.lpi_id, filters.current.lpi_id));
    }
    if ('status' in filters.current) {
      filtered = filtered.filter(item => item.state === filters.current.status);
    }
    if ('tags' in filters.current) {
      filtered = filtered.filter(item => {
        const tag_ids = item.tags.map(tag => tag.id);
        return filters.current.tags.every(tag => tag_ids.includes(tag.id));
      });
    }
    return filtered;
  };

  const getDetailHeaderProps = item => ({
    title: t('student'),
    subheader: <EmailTitle item={item} titleField="partner_name" emailField="partner_email" />,
  });

  const getDetailContent = item => (
    <React.Fragment>
      <Field.Text label={t('LPI ID')} value={item.lpi_id} />
      <Field.Paragraph label={t('Bio')} value={item.bio} />
      {!!item.membership_start && <Field.Text label={t('Authorization Data')} value={item.membership_start} />}
      {!!item.membership_stop && <Field.Text label={t('Expiring Data')} value={item.membership_stop} />}
      {!!item.certifications?.length && (
        <Field.Chip label={t('Certifications')} value={item.certifications} many color="default" />
      )}
      {!!item.tags?.length && (
        <Field.Chip label={t('Tags')} value={item.tags.map(tag => tag.name)} many color="primary" />
      )}
    </React.Fragment>
  );

  const getDetailActions = item => (
    <>
      {item.state !== 'accepted' && (
        <Button
          onClick={() => {
            resendInvite
              .mutateAsync(item.id)
              .then(() => dispatch(setMessage(i18next.t('Invite sent successfully'))))
              .catch(error => dispatch(setMessage(i18next.t('An error occurred during the request'))));
          }}
          color="secondary"
          disabled={resendInvite.status === 'loading'}
        >
          {t('Resend Invite')}
        </Button>
      )}

      <Button
        onClick={() =>
          setDialog({
            open: true,
            title: t('Edit student'),
            content: (
              <EditStudentForm partnerId={item.partner_id} onClose={() => setDialog({ ...dialog, open: false })} />
            ),
          })
        }
      >
        {t('Edit')}
      </Button>

      <Button
        onClick={() =>
          setDialog({
            open: true,
            title: t('Delete student'),
            content: (
              <>
                <DialogContent>{t('Are you sure you want to delete this student?')}</DialogContent>
                <DialogActions>
                  <Button onClick={() => setDialog({ ...dialog, open: false })}>{t('Cancel')}</Button>
                  <Button
                    onClick={() =>
                      deleteStudent
                        .mutateAsync(item.partner_id)
                        .then(() => dispatch(setMessage(t('Student deleted successfully'))))
                        .catch(() => dispatch(setMessage(t('An error occurred during the request'))))
                        .finally(() => setDialog({ ...dialog, open: false }))
                    }
                    color="secondary"
                    disabled={deleteStudent.status === 'loading'}
                  >
                    {deleteStudent.status === 'loading' ? <CircularProgress size={20} /> : t('Confirm')}
                  </Button>
                </DialogActions>
              </>
            ),
          })
        }
      >
        {t('Delete')}
      </Button>
    </>
  );

  const getActions = () => (
    <AddStudentAction
      onSubmit={payload => {
        if (
          students.find(student => student.partner_email === payload.partner_email || student.lpi_id === payload.lpi_id)
        ) {
          dispatch(setMessage(t('You already added this student')));
          return;
        }

        createStudent
          .mutateAsync(payload)
          .then(() => dispatch(setMessage(t('Student created'))))
          .catch(error => dispatch(setMessage(t('LPI ID does not exist or email is not valid'))));
      }}
      isLoading={createStudent.status === 'loading'}
    />
  );

  const handleAddTag = (partnerIds, tags) => {
    Promise.all(partnerIds.map(studentId => {
      const student = students.find(student => student.partner_id === studentId);
      if(!student) return
      const newTags = tags.filter(tag => !student.tags.map(tag => tag.id).includes(tag.id)).concat(student.tags)
      updateStudent.mutateAsync({ id: studentId, tags: newTags })}))
      .then(() => dispatch(setMessage(t('Tags updated'))))
      .catch(() => dispatch(setMessage(t('An error occurred during the request'))))
      .finally(() => setDialog({ ...dialog, open: false }));
  };

  const handleRemoveTag = (partnerIds, tags) => {
    Promise.all(partnerIds.map(studentId => {
      const student = students.find(student => student.partner_id === studentId);
      if(!student) return
      const newTags = student.tags.filter(tag => !tags.map(tag => tag.id).includes(tag.id))
      updateStudent.mutateAsync({ id: studentId, tags: newTags })}))
      .then(() => dispatch(setMessage(t('Tags updated'))))
      .catch(() => dispatch(setMessage(t('An error occurred during the request'))))
      .finally(() => setDialog({ ...dialog, open: false }));
  };

  return (
    <>
      <DataTable
        selectionActions={[
          {
            icon: <LocalOfferIcon />,
            label: t('Add tag'),
            onClick: partnerIds =>
              setDialog({
                open: true,
                title: t('Add tag'),
                content: (
                  <MultiTagDialog
                    onClose={() => setDialog({ ...dialog, open: false })}
                    onConfirm={tags => handleAddTag(partnerIds, tags)}
                  />
                ),
              }),
          },
          {
            icon: <LocalOfferIcon />,
            label: t('Remove tag'),
            onClick: partnerIds =>
              setDialog({
                open: true,
                title: t('Remove tag'),
                content: (
                  <MultiTagDialog
                    onClose={() => setDialog({ ...dialog, open: false })}
                    onConfirm={tags => handleRemoveTag(partnerIds, tags)}
                  />
                ),
              }),
          },
        ]}
        page={{
          title: t('Students'),
          subtitle: t('Register your students to assign Discount Coupons and Vouchers'),
          actions: getActions,
        }}
        items={students}
        columns={{
          partner_name: { label: t('Name') },
          lpi_id: { label: t('LPI ID') },
          state: {
            label: t('Status'),
            render: value => value && <InvitationStatusChip status={value} />,
          },
          certifications: {
            label: t('Certifications'),
            render: value => value && value.join(', '),
          },
          membership_stop: { label: t('Expiring Date') },
          tags: {
            label: t('Tags'),
            render: values =>
              !!values &&
              values.map(value => (
                <Chip
                  key={value.id}
                  label={value.name}
                  size="small"
                  color="primary"
                  style={{ marginRight: 3, marginBottom: 3 }}
                />
              )),
          },
        }}
        filters={{ ...filters, execute: executeFilters }}
        detail={{
          headerProps: getDetailHeaderProps,
          content: getDetailContent,
          actions: getDetailActions,
        }}
        detailLink={value => path + '/' + value.id}
      />

      <Dialog open={dialog.open} onClose={() => setDialog({ ...dialog, open: false })}>
        <DialogTitle>{dialog.title}</DialogTitle>
        {dialog.content}
      </Dialog>
    </>
  );
};

export default React.memo(UserListPage);
