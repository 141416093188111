import { takeLatest, put, all, call } from 'redux-saga/effects';
import dedent from 'dedent';

import { createMaterial /* fetchMaterials */ } from '../reducers/MaterialsReducer';
import api from '../../data/network';

function* createMaterialSaga({ payload: { username, topic, language, image } }) {
  try {
    if (!username || !topic || !language) throw new Error('Invalid payload');

    const response = yield call([api.tickets, api.tickets.create], {
      queue_alias: 'learning',
      title: `Co-branded materials requested by ${username}`,
      message: dedent(`
        Topic: "${topic}"
        Language: "${language}"
        `),
    });

    if (!response || !response.id) throw new Error('Bad response');

    if (!!image) {
      let formData = new FormData();
      formData.append('model', 'helpdesk.ticket');
      formData.append('res_id', response.id);
      formData.append('ufile', image);
      yield call([api.attachments, api.attachments.create], formData);
    }

    yield put(createMaterial.success());
  } catch (error) {
    console.error(error);
    yield put(createMaterial.failure(error));
  }
}

export default function* root() {
  // yield all([yield takeLatest([fetchMaterials.TRIGGER], fetchMaterialsSaga)])
  yield all([yield takeLatest([createMaterial.TRIGGER], createMaterialSaga)]);
}
