import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent, DialogActions, CircularProgress, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';

import useStudent from '../../hooks/Students/useStudent';
import useStudentsMutations from '../../hooks/Students/useStudentsMutations';
import { setMessage } from '../../store/reducers/SnackReducer';
import PartnerTagsAutocomplete from '../Common/Filters/PartnerTagsAutocomplete';

const EditStudentForm = ({ partnerId, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { student, status } = useStudent(partnerId);
  const { updateStudent } = useStudentsMutations();
  const [newTags, setNewTags] = useState([]);

  useEffect(() => {
    if (status === 'success') {
      setNewTags(student.tags);
    }
  }, [status]);

  switch (status) {
    case 'idle':
    case 'loading':
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ minWidth: '300px', minHeight: '100px' }}
        >
          <CircularProgress />
        </Box>
      );

    case 'error':
      return <DialogContent>{t('An error occurred during the request')}</DialogContent>;

    case 'success':
      return (
        <>
          <DialogContent style={{ minWidth: '300px' }}>
            <PartnerTagsAutocomplete
              allowDelete={false}
              value={newTags}
              onChange={(_, tags) => setNewTags(tags)}
              loading={updateStudent.status === 'loading'}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                updateStudent
                  .mutateAsync({ id: partnerId, tags: newTags })
                  .then(onClose)
                  .catch(error => {
                    console.error(error);
                    dispatch(setMessage(t('An error occurred during the request')));
                  })
              }
              disabled={updateStudent.status === 'loading'}
            >
              {updateStudent.status === 'loading' ? <CircularProgress size={20} /> : t('Ok')}
            </Button>
            <Button onClick={onClose}>{t('Close')}</Button>
          </DialogActions>
        </>
      );
  }
};

export default EditStudentForm;
