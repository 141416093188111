import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Spinner from './Spinner';

const init = { password1: '', password2: '' };

const ResetForm = ({ error, loading, onSubmit }) => {
  const { t } = useTranslation();

  const validate = values => {
    const errors = {};
    if (!values.password1) {
      errors.password1 = t('Password cannot be empty');
    }
    if (values.password1 !== values.password2) {
      errors.password2 = t('Passwords must be the same');
    }
    return errors;
  };

  return (
    <Formik initialValues={init} validate={validate} onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, errors, touched, values }) => (
        <Form>
          <Box mb={2}>
            <TextField
              required
              fullWidth
              type="password"
              name="password1"
              variant="outlined"
              helperText={(error && error.password1) || (touched.password1 && errors.password1)}
              label={t('New password')}
              placeholder={t('New password')}
              error={!!(error && error.password1) || !!(touched.password1 && errors.password1)}
              value={values.password1}
              onChange={handleChange('password1')}
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              fullWidth
              type="password"
              name="password2"
              variant="outlined"
              helperText={(error && error.password2) || (touched.password2 && errors.password2)}
              label={t('Confirm new password')}
              placeholder={t('Confirm new password')}
              error={!!(error && error.password2) || !!(touched.password2 && errors.password2)}
              value={values.password2}
              onChange={handleChange('password2')}
            />
          </Box>
          {loading ? (
            <Spinner />
          ) : (
            <Button
              disableElevation
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              onClick={handleSubmit}
            >
              {t('Submit')}
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(ResetForm);
