import _keys from 'lodash/keys';

// Common routes
export const HOME = '/';
export const LOGIN = '/login';
export const PROFILE = '/profile';
export const PROFILE_DETAILS = '/profile/:id';
export const LOCATIONS = '/locations';
export const LOCATION_CREATE = '/location/';
export const LOCATION_UPDATE = '/location/:id';
export const SERVER_UNDER_MAINTENANCE = '/server-under-maintenance';
// Training routes
export const TEACHERS_LIST = '/teachers';
export const PROCTORS_LIST = '/proctors';
export const STUDENTS_LIST = '/students';
export const SITE_ADMINS_LIST = '/site-admins';
export const MATERIALS_LIST = '/materials';
export const EXAMS_LIST = '/exams';
// Portfolio routes
export const PARTNERS_LIST = '/partners';
export const PARTNERS_DETAILS = '/partners/:partnerId';
export const VOUCHERS_LIST = '/vouchers';
export const VOUCHER_ORDERS = '/voucher/orders';
export const COUPONS_LIST = '/coupons';
// Sales process routes
export const LEADS_LIST = '/leads';
export const OPPORTUNITIES_LIST = '/opportunities';
// Company routes
export const JOBS_LIST = '/jobs';
export const CANDIDATES_LIST = '/candidates';
// Company routes
export const PROFILE_SINGLE = '/company/profile';
export const USERS_LIST = '/users';
export const AGREEMENTS_LIST = '/agreements';

// Documents
export const ALL_DOCUMENTS = '/documents';

// Shop routes
export const CART = '/cart';
export const MANUAL_ORDER = '/manual-order';

// Match routes with default cart or a giver order ID
export const CHECKOUT = '/checkout';
export const CHECKOUT_ORDER = '/checkout/:orderId';
export const CHECKOUT_TRANSFER = '/checkout/bank-transfer';
export const CHECKOUT_PAYPAL = '/checkout/paypal';
export const CHECKOUT_CARD = '/checkout/credit-card';
export const CHECKOUT_SUCCESS = '/checkout/success';
export const CHECKOUT_FAILURE = '/checkout/failure';

export const JOB_PRODUCT_LIST = '/products/job';
export const JOB_PRODUCT_DETAILS = '/products/job/:productId';
export const JOB_CREATE_OFFER = '/job/offer';
export const JOB_UPDATE_OFFER = '/job/offer/:offerId';
export const VOUCHER_PRODUCT_LIST = '/products/voucher';
export const VOUCHER_PRODUCT_DETAILS = '/products/voucher/:productId';
export const SALEORDERS_LIST = '/orders';
export const SALEORDERS_DETAILS = '/orders/:orderId';
// Password change/reset + Welcome routes
export const EMAIL_FORM = '/password';
export const RESET_FORM = '/password/reset/:token';
export const WELCOME = '/welcome/:token';

// Helpdesk routes
export const HELPDESK_TICKETS = '/support';
export const HELPDESK_TICKET_DETAILS = '/support/tickets/:id';
export const HELPDESK_TICKET_CREATE = '/support/create';

export const toQueryString = object =>
  '?' +
  Object.keys(object)
    .map(key => `${key}=${object[key].toString()}`)
    .join('&');

export const getRoute = (path, args, params = {}) => {
  let dest = _keys(args).reduce((acc, cur) => acc.replace(`:${cur}`, args[cur]), path);
  if (Object.entries(params).length !== 0 && params.constructor === Object) {
    dest = `${dest}${toQueryString(params)}`;
  }
  return dest;
};
