import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import TableFilters from '../Generic/TableFilters';
import AutoCompleteFilter from '../Common/Filters/AutoCompleteFilter';

import { useVouchers } from '../../store/hooks/vouchers';
import { useVoucherProducts } from '../../store/hooks/shop';

const VouchersFilters = ({ controller }) => {
  const { t } = useTranslation();

  const voucherProducts = useVoucherProducts();
  const vouchersTypes = useMemo(() => Object.values(voucherProducts), [voucherProducts]);

  const vouchers = useVouchers();

  const assignees = useMemo(() => {
    const assignees = [];
    vouchers.all.forEach(voucher => {
      assignees.push({ id: voucher.assigned_to_id, name: voucher.assigned_to_name });
    });
    return assignees.filter((assignee, index, self) => self.findIndex(a => a.id === assignee.id) === index);
  }, [vouchers]);

  const getAssigneeNameById = useCallback(
    assigneeId => {
      let assignee = assignees.find(x => x.id === parseInt(assigneeId));
      if (assignee) {
        return assignee.name;
      }
    },
    [assignees],
  );

  const getTypeNameById = useCallback(value => vouchersTypes.find(voucher => voucher.id === parseInt(value))?.name, [
    vouchersTypes,
  ]);

  return (
    <TableFilters
      controller={controller}
      chips={{
        product_template_id: value => `${t('Voucher name')}: ${getTypeNameById(value)}`,
        assigned_to_id: value => `${t('Assignee')}: ${getAssigneeNameById(value)}`,
        state: value => `${t('state')}: ${value}`,
      }}
      renderFilters={(current, setFilter) => (
        <>
          <FormControl fullWidth variant="outlined">
            <Select
              placeholder={t('State')}
              value={current.state}
              onChange={event => {
                setFilter('state', event.target.value || '');
              }}
            >
              <MenuItem value="">{t('All')}</MenuItem>
              <MenuItem value="new">{t('New')}</MenuItem>
              <MenuItem value="assigned">{t('Assigned')}</MenuItem>
              <MenuItem value="used">{t('Used')}</MenuItem>
              <MenuItem value="expired">{t('Expired')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <AutoCompleteFilter
              name="assigned_to_id"
              label={t('Assignee')}
              value={current.assigned_to_id ? assignees.find(x => x.id === parseInt(current.assigned_to_id)) : null}
              options={assignees}
              optionLabel={option => (option.name ? option.name : '')}
              onChange={(event, value) => {
                setFilter('assigned_to_id', value ? value.id : '');
              }}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <AutoCompleteFilter
              name="product_template_id"
              label={t('Voucher name')}
              value={
                current.product_template_id
                  ? vouchersTypes.find(voucher => voucher.id === parseInt(current.product_template_id))
                  : null
              }
              options={vouchersTypes}
              optionLabel={option => option.name}
              onChange={(_e, value) => {
                setFilter('product_template_id', value ? value.id : '');
              }}
            />
          </FormControl>
        </>
      )}
    />
  );
};

export default React.memo(VouchersFilters);
