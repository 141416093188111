import React from 'react';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Formik, Form as FormikForm } from 'formik';
// import { importStudents } from '../../store/reducers/StudentsReducer';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import ActionButton from '../Common/ActionButton';
import TextInput from '../Form/TextInput';
import CouponProgramsInput from '../Coupons/CouponProgramsInput';
import CSVLoader from './CSVLoader';
import useStudentsMutations from '../../hooks/Students/useStudentsMutations';
import { setMessage } from '../../store/reducers/SnackReducer';
import PartnerTagsAutocomplete from '../../components/Common/Filters/PartnerTagsAutocomplete';
import { Typography } from '@material-ui/core';

const initialValues = {
  lpi_id: '',
  partner_email: '',
  coupon_program: '',
  students: [],
};

const validate = values => {
  const errors = {};
  if (!values.lpi_id) {
    errors['lpi_id'] = i18n.t('LPI ID is required');
  }
  if (!values.partner_email) {
    errors['partner_email'] = i18n.t('Email is required');
  }
  return errors;
};

const AddStudentAction = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { importStudents } = useStudentsMutations();

  const [selectedTags, setSelectedTags] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [currentTab, setTab] = React.useState(0);
  const [students, setStudents] = React.useState([]);
  const [resModal, setResModal] = React.useState({ open: false, content: null });

  const submitImport = values => {
    importStudents
      .mutateAsync({
        coupon_program: values.coupon_program?.id,
        tags: selectedTags.map(tag => tag.id),
        students: students,
      })
      .then(res => {
        setResModal({
          open: true,
          content: (
            <>
              <DialogTitle>{t('Import results')}</DialogTitle>
              <DialogContent>
                {res.message.split('\n').map(function(item, idx) {
                  return <Typography key={idx}>{item}</Typography>;
                })}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setResModal({ ...resModal, open: false })} color="default">
                  {t('Ok')}
                </Button>
              </DialogActions>
            </>
          ),
        });
      })
      .catch(error => {
        dispatch(setMessage(error.message));
      });
  };

  return (
    <React.Fragment>
      <ActionButton m={0} mr={1} onClick={() => setModalOpen(true)}>
        {t('Add Students')}
      </ActionButton>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>{t('Add Students')}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            onSubmit={values => {
              currentTab === 0 ? onSubmit(values) : submitImport(values);
              setModalOpen(false);
            }}
            validate={currentTab === 0 ? validate : undefined}
          >
            {({ handleSubmit, handleChange, errors, touched, values }) => (
              <FormikForm>
                <Box mb={4}>
                  <Tabs
                    value={currentTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(event, newValue) => setTab(newValue)}
                    aria-label="disabled tabs example"
                    centered
                  >
                    <Tab label={t('Create')} />
                    <Tab label={t('Import')} />
                  </Tabs>
                </Box>
                <Grid container spacing={3}>
                  {currentTab === 0 ? (
                    <React.Fragment>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          label={t('LPI ID')}
                          name="lpi_id"
                          error={touched['lpi_id'] && errors['lpi_id']}
                          onChange={handleChange('lpi_id')}
                          value={values['lpi_id']}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          label={t('Email')}
                          name="partner_email"
                          error={touched['partner_email'] && errors['partner_email']}
                          onChange={handleChange('partner_email')}
                          value={values['partner_email']}
                        />
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <>
                      <Grid item xs={12} sm={12}>
                        <CSVLoader
                          label={t('CSV')}
                          color="secondary"
                          name="csv"
                          columns={['lpi_id', 'partner_email', 'tags']}
                          onChange={(filename, value) => setStudents(value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <PartnerTagsAutocomplete
                          value={selectedTags}
                          onChange={(_, newValue) => setSelectedTags(newValue)}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={12}>
                    <CouponProgramsInput
                      label={t('Coupon Program')}
                      name="coupon_program"
                      error={touched['coupon_program'] && errors['coupon_program']}
                      onChange={handleChange('coupon_program')}
                      value={values['coupon_program']}
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button onClick={() => setModalOpen(false)} color="default">
                    {t('Cancel')}
                  </Button>
                  <Button onClick={handleSubmit} color="secondary">
                    {currentTab === 0 ? t('Create') : t('Import')}
                  </Button>
                </DialogActions>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Dialog open={resModal.open}>{resModal.content}</Dialog>
    </React.Fragment>
  );
};

export default React.memo(AddStudentAction);
