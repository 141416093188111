import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Field from '../Common/Fields';
import Detail from '../Generic/Detail';

const VoucherOrdersDetail = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Detail typeName={t('voucher order')} titleField={'name'} readonly {...props}>
      <Field.Text label={t('Order ID')} field="sale_order_id_name" />
      <Field.Text label={t('Purchase Date')} field="date" />
    </Detail>
  );
};

export default React.memo(VoucherOrdersDetail);
