import _values from 'lodash/values';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { route, createItem, updateItem, deleteItem } from '../../store/reducers/GenericReducer';
import { getItems, selectIsLoading } from '../../store/selectors/generic';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ActionButton from '../Common/ActionButton';
import EmptyState from '../Common/EmptyState';
import Layout from '../App/Layout';
import LoadingState from '../Common/LoadingState';
import Snackbar from '../Common/Snackbar';
import { Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  customCardRoot: {
    padding: 0,
  },
  table: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
}));

const Page = ({ ep, labels, subtitle, Table, Form, Detail, Actions, Filters, NavbarButtons, ...props }) => {
  const dispatch = useDispatch();
  const [id, setId] = React.useState();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalItem, setModalItem] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [openFilters, setOpenFilters] = React.useState(false);

  const isLoading = useSelector(selectIsLoading);
  const items = useSelector(getItems);
  const [shownItems, setShownItems] = React.useState([]);
  const item = items[id];

  const { path } = useRouteMatch();
  const detail_route_match = useRouteMatch({
    path: path + '/:id/',
  });

  let history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    setShownItems(_values(items));
  }, [items]);

  React.useEffect(() => {
    dispatch(route(ep));
  }, [dispatch, ep]);

  const closeFilters = React.useCallback(() => {
    setOpenFilters(false);
  }, [setOpenFilters]);

  const handleRowClick = React.useCallback(
    (id, row) => {
      history.push(path + '/' + id);
    },
    [history, path],
  );

  const handleCreateClick = React.useCallback(() => {
    setModalItem(null);
    setModalOpen(true);
  }, []);

  const handleEdit = React.useCallback(() => {
    setModalItem(item);
    setModalOpen(true);
  }, [item]);

  const handleCloseDetail = React.useCallback(() => {
    setId(null);
    history.push(path);
  }, [history, path]);

  const handleDelete = React.useCallback(() => {
    if (item) {
      dispatch(deleteItem(item));
    }
    setConfirmDelete(false);
  }, [dispatch, item]);

  const handleSubmit = React.useCallback(
    values => {
      if (modalItem) {
        dispatch(updateItem(values));
      } else {
        if (props.customCreateAction) {
          dispatch(props.customCreateAction(values));
        } else {
          dispatch(createItem(values));
        }
      }
      setModalOpen(false);
    },
    [dispatch, modalItem, props],
  );

  return (
    <Layout title={labels.title} back={!!detail_route_match} backPath={path}>
      {(Actions || subtitle) && (
        <Box display="flex" justifyContent="space-between" m={2} mt={4} pl={2} flexDirection="row">
          <Typography variant="subtitle1">{subtitle}</Typography>

          <Box display="flex" flexDirection="row"> 
          {labels.createLabel && (
            <ActionButton m={0} mr={1} onClick={handleCreateClick}>
              {labels.createLabel}
            </ActionButton>
          )}
          {NavbarButtons && <NavbarButtons />}
          </Box>
        </Box>
      )}

      {_values(items).length > 0 ? (
        <Grid className={classes.table} container spacing={3} display="flex">
          <Hidden mdDown={!!detail_route_match}>
            <Grid item xs={12} lg>
              <Card variant="outlined">
                <CardContent classes={{ root: classes.customCardRoot }}>
                  {Table && (
                    <Table
                      rows={shownItems}
                      onRowClick={handleRowClick}
                      Filters={Filters}
                      setOpenFilters={setOpenFilters}
                    />
                  )}
                  {Filters && (
                    <Filters items={items} setItems={setShownItems} open={openFilters} onClose={closeFilters} />
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Hidden>
          {Detail && (
            <Switch>
              <Route path={path + '/:id'}>
                <Grid item xs={12} lg={4}>
                  <Detail
                    item={item}
                    Actions={Actions}
                    onRoute={setId}
                    labels={labels}
                    onEditClick={handleEdit}
                    onCloseClick={handleCloseDetail}
                    onDeleteClick={() => setConfirmDelete(true)}
                  />
                </Grid>
              </Route>
            </Switch>
          )}
        </Grid>
      ) : isLoading ? (
        <LoadingState />
      ) : (
        <EmptyState>
          {labels.createLabel && (
            <ActionButton m={0} mr={1} onClick={handleCreateClick}>
              {labels.createLabel}
            </ActionButton>
          )}
        </EmptyState>
      )}
      {Form && (
        <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
          <DialogTitle>{modalItem === null ? labels.createLabel : labels.updateLabel}</DialogTitle>
          <DialogContent>
            <Form item={modalItem} onSubmit={handleSubmit} onClose={() => setModalOpen(false)} />
          </DialogContent>
        </Dialog>
      )}
      <Dialog
        open={confirmDelete}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        onClose={() => setConfirmDelete(false)}
      >
        <DialogTitle>{labels.confirmDeleteLabel}</DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={() => setConfirmDelete(false)} color="default">
            {t('Cancel')}
          </Button>
          <Button onClick={handleDelete} color="secondary">
            {t('Ok')}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar />
    </Layout>
  );
};

export default React.memo(Page);
