import { takeLatest, put, all, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  fetchCoupons,
  fetchCouponPrograms,
  createCoupon,
  assignCoupon,
  fetchAssignees,
} from '../reducers/CouponsReducer';
import { setMessage } from '../reducers/SnackReducer';
import i18n from '../../locales';
import api from '../../data/network';
import { COUPONS_LIST } from '../../navigation/routes';

function* fetchCouponsSaga({ payload }) {
  try {
    const coupons = yield call([api.coupons, api.coupons.search]);
    yield put(fetchCoupons.success(coupons));
  } catch (e) {
    yield put(fetchCoupons.failure('Unable to fetch coupons'));
  }
}

function* createCouponSaga({ payload }) {
  try {
    var data = {
      program_id: payload.program.id,
      count: payload.count,
    };
    if (payload.partner) {
      data.partner_id = payload.partner.partner_id;
    }

    yield call([api.coupons, api.coupons.create], data);
    yield put(createCoupon.success());
    yield put(fetchCoupons());

    if (payload.partner) {
      yield put(setMessage(`${i18n.t('Done! Coupon created for')} ${payload.partner.partner_name}`));
    } else {
      yield put(setMessage(i18n.t('Done! Coupon created')));
      yield put(push(COUPONS_LIST));
    }
  } catch (e) {
    // manda la action failure
    console.error(e);
    yield put(setMessage(i18n.t('Invalid number of coupons to generate')));
    yield put(createCoupon.failure(i18n.t('Unable to create coupon')));
  }
}

function* assignCouponSaga({ payload }) {
  try {
    yield call([api.coupons, api.coupons.assign], {
      coupon_id: payload.coupon.id,
      partner_id: payload.partner.partner_id,
    });
    yield put(assignCoupon.success());
    yield put(fetchCoupons());
    yield put(fetchAssignees());
    yield put(setMessage(`${i18n.t('Done! Coupon assigned to')} ${payload.partner.partner_name}`));
    yield put(push(COUPONS_LIST));
  } catch (e) {
    // manda la action failure
    console.error(e);
    yield put(assignCoupon.failure(i18n.t('Unable to assign coupon')));
  }
}

function* fetchCouponProgramsSaga({ payload }) {
  try {
    const programs = yield call([api.couponPrograms, api.couponPrograms.search]);
    yield put(fetchCouponPrograms.success(programs));
  } catch (e) {
    yield put(fetchCouponPrograms.failure('Unable to fetch coupon programs'));
  }
}

function* fetchAssigneesSaga() {
  try {
    const assignees = yield call([api.coupons, api.coupons.fetchAssignees]);
    yield put(fetchAssignees.success(assignees));
  } catch (e) {
    yield put(fetchAssignees.failure('Unable to fetch assignees'));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchCoupons.TRIGGER], fetchCouponsSaga)]);
  yield all([yield takeLatest([createCoupon.TRIGGER], createCouponSaga)]);
  yield all([yield takeLatest([assignCoupon.TRIGGER], assignCouponSaga)]);
  yield all([yield takeLatest([fetchCouponPrograms.TRIGGER], fetchCouponProgramsSaga)]);
  yield all([yield takeLatest([fetchAssignees.TRIGGER], fetchAssigneesSaga)]);
}
