import React from 'react';
import {
  TextField,
  Chip,
  Box,
  IconButton,
  Button,
  Tooltip,
  DialogContent,
  Select,
  DialogActions,
  Dialog,
  DialogTitle,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { fetchItems } from '../../store/reducers/GenericReducer';

import CompanyInput from '../Form/CompanyInput';
import Form from '../Generic/Form';
import TextInput from '../Form/TextInput';
import MultiSelectInput from '../Form/MultiSelectInput';
import CountryInput from '../Form/CountryInput';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../store/reducers/SnackReducer';
import { useFormikContext } from 'formik';
import usePartnerTypes from '../../hooks/Agreements/usePartnerTypes';
import usePartnerLevels from '../../hooks/Agreements/usePartnerLevels';
import SelectInput from '../Form/SelectInput';
import useOpportunitiesMutations from '../../hooks/Opportunities/useOpportunitiesMutations';

const REQUEST_AGREEMENT_STAGE_ID = 9;

const CountryInputWrapper = ({ label, field, required }) => {
  const { setFieldValue, values, touched, errors } = useFormikContext();
  return (
    <CountryInput
      label={label}
      error={touched[field] && errors[field]}
      onChange={(_e, value) => setFieldValue(field, value?.id || null)}
      valueID={values[field]}
    />
  );
};

const RequestAgreementFormDialog = ({ opportunity, isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: partnerTypes } = usePartnerTypes();
  const { data: partnerLevels } = usePartnerLevels();
  const { update } = useOpportunitiesMutations();

  function handleSubmit(values) {
    values.stage_id = REQUEST_AGREEMENT_STAGE_ID;

    // conver partner_level_id and partner_type_id to integer
    values.partner_level_id = parseInt(values.partner_level_id);
    values.partner_type_id = parseInt(values.partner_type_id);

    update.mutate(
      { id: opportunity.id, data: values },
      {
        onSuccess: () => {
          dispatch(fetchItems('opportunities'));
          dispatch(setMessage(t('Agreement requested')));
          onClose();
        },
        onError: () => {
          dispatch(setMessage(t('Error requesting agreement')));
        },
      },
    );
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Request agreement</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={handleSubmit}
          init={() => getInitForm(opportunity)}
          validate={validate}
          submitLabel={t('Request Agreement')}
        >
          {partnerTypes && (
            <SelectInput
              label={t('Partner Type')}
              field="partner_type_id"
              options={partnerTypes.results.map(type => ({
                name: type.name,
                id: type.id,
              }))}
              xs={12}
              sm={6}
              required
            />
          )}

          {partnerLevels && (
            <SelectInput
              label={t('Partner Level')}
              field="partner_level_id"
              options={partnerLevels.results.map(type => ({
                name: type.name,
                id: type.id,
              }))}
              xs={12}
              sm={6}
              required
            />
          )}

          <CompanyInput
            label={t('Company')}
            field="partner_name"
            handleChange={(setFieldValue, value) => {
              if (value) {
                setFieldValue('partner_id', value.id);
                setFieldValue('email_from', value.email ? value.email : '');
                setFieldValue('phone', value.phone ? value.phone : '');
                setFieldValue('mobile', value.mobile ? value.mobile : '');
                setFieldValue('website', value.website ? value.website : '');
              }
            }}
            xs={12}
            required
          />
          <TextInput label={t('Email')} field="email_from" xs={12} sm={6} required />
          <TextInput label={t('Phone')} field="phone" xs={12} sm={6} />
          <TextInput label={t('Mobile')} field="mobile" xs={12} sm={6} />
          <TextInput label={t('Website')} field="website" xs={12} sm={6} />
          <CountryInputWrapper label={t('Country')} field="country_id" xs={12} sm={12} required />
          <TextInput label={t('Address')} field="street" xs={12} required />
          <TextInput label={t('City')} field="city" xs={12} sm={6} required />
          <TextInput label={t('Zip')} field="zip" xs={12} sm={6} required />
          <TextInput label={t('VAT')} field="vat" xs={12} />
          <TextInput label={t('Signer Name')} field="signer_name" xs={12} sm={6} required />
          <TextInput label={t('Signer Email')} field="signer_email" xs={12} sm={6} required />
          <TextInput label={t('Portal User Name')} field="portal_user_name" xs={12} sm={6} />
          <TextInput label={t('Portal User Email')} field="portal_user_email" xs={12} sm={6} />
        </Form>
      </DialogContent>
    </Dialog>
  );
};

const validate = values => {
  const errors = {};

  // required: parnter_name, address, city, zip, signer,

  if (!values.partner_name) {
    errors['partner_name'] = i18n.t('Partner name is required');
  }

  if (!values.street) {
    errors['street'] = i18n.t('Address is required');
  }

  if (!values.city) {
    errors['city'] = i18n.t('City is required');
  }

  if (!values.zip) {
    errors['zip'] = i18n.t('Zip is required');
  }

  if (!values.signer_name) {
    errors['signer_name'] = i18n.t('Signer name is required');
  }

  if (!values.signer_email) {
    errors['signer_email'] = i18n.t('Signer email is required');
  }

  if (!values.country_id) {
    errors['country_id'] = i18n.t('Country is required');
  }

  return errors;
};

const getInitForm = item => {
  return {
    id: item ? item.id : null,
    name: item ? item.name : '',
    partner_id: item ? item.partner_id : null,
    partner_name: item ? item.partner_name : '',
    email_from: item ? item.email_from : '',
    country_id: item ? item.country_id : null,
    phone: item ? item.phone : '',
    mobile: item ? item.mobile : '',
    website: item ? item.website : '',
    tags: item ? item.tags : [],
    planned_revenue: item ? item.planned_revenue : '',
    date_deadline: item ? item.date_deadline : null,
    stage_id: item ? item.stage_id : 1,
    description: item ? item.description : '',
    type: 'opportunity',
    street: item ? item.street : '',
    city: item ? item.city : '',
    state_id: item ? item.state_id : null,
    vat: item ? item.vat : '',
    zip: item ? item.zip : '',
    signer_name: item ? item.signer_name : '',
    signer_email: item ? item.signer_email : '',
    portal_user_name: item ? item.portal_user_name : '',
    portal_user_email: item ? item.portal_user_email : '',
    partner_type_id: item ? item.partner_type_id : null,
    partner_level_id: item ? item.partner_level_id : null,
  };
};

export default RequestAgreementFormDialog;
