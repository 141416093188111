import React, { useMemo } from 'react';
import useLocation from '../../hooks/Locations/useLocation';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../store/selectors/auth';

import Layout from '../../components/App/Layout';
import { setMessage } from '../../store/reducers/SnackReducer';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import TextInput from '../../components/Form/TextInput';
import { LOCATION_UPDATE } from '../../navigation/routes';
import CountryInput from '../../components/Form/CountryInput';
import StateInput from '../../components/Form/StateInput';
import SelectTrainingTopics from '../../components/Profile/Training/SelectTrainingTopics';
import SelectCourseTypes from '../../components/Profile/Training/SelectCourseTypes';
import TextareaInput from '../../components/Form/TextareaInput';
import SelectSiteAdmin from '../../components/Profile/Training/SelectSiteAdmin';
import SelectLanguages from '../../components/Profile/Training/SelectLanguages';
import useLocationMutations from '../../hooks/Locations/useLocationsMutations';
import FileUpload from '../../components/Form/FileUpload';

const getInitForm = location => ({
  name: location?.name || '',
  description: location?.description || '',

  type: location?.type || 'training-center',
  visible: location ? location.visible : true,

  country: location?.country || null,
  state: location?.state || null,
  city: location?.city || '',
  address: location?.address || '',
  zip: location?.zip || '',

  website: location?.website || '',
  languages: location?.languages || [],

  courses: location?.courses || [],
  topics: location?.topics || [],
  site_admin: location?.site_admin || null,

  attachments: location?.attachments || [],
});

const LocationFormPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const locationId = useRouteMatch({
    path: LOCATION_UPDATE,
  })?.params?.id;
  const { location, status } = useLocation(locationId);
  const { createLocation, updateLocation } = useLocationMutations();
  const isMutating = createLocation.isLoading || updateLocation.isLoading;
  const user = useSelector(getUser);

  const isTestCenter = useMemo(() => user?.partner_type.includes('test_center'), [user]);

  const submit = async values => {
    // Prepare payload
    const payload = {};
    payload.name = values.name;
    payload.description = values.description || null;
    payload.type = values.type;
    payload.visible = values.visible;
    payload.city = values.city || null;
    payload.address = values.address || null;
    payload.zip = values.zip || null;
    payload.website = values.website || null;

    payload.country_id = values.country?.id || null;
    payload.state_id = values.state?.id || null;
    payload.language_ids = values.languages.map(lang => lang.id);
    payload.course_ids = values.courses.map(course => course.id);
    payload.topic_ids = values.topics.map(topic => topic.id);
    payload.site_admin_partner_id = values.site_admin?.partner_id || null;

    payload.attachments = values.attachments || [];

    try {
      if (locationId) {
        payload.id = locationId;
        await updateLocation.mutateAsync(payload);
      } else {
        await createLocation.mutateAsync(payload);
      }
      dispatch(setMessage(t('Location saved')));
    } catch (error) {
      dispatch(setMessage(t('An error occurred during the request')));
    }
    history.push('/locations');
  };

  const validate = vals => {
    const errors = {};

    if (!vals.name) errors.name = t('Name is required');

    if (vals.type === 'training-test-center' || vals.type === 'test-center') {
      if (!vals.site_admin) {
        errors.site_admin = t('Site admin is required for test centers');
      }
    }

    const files = Array.from(vals.attachments);

    if (files.some(file => file.size > 5 * 1024 * 1024)) {
      errors['attachments'] = t('File size is too big. Max 5MB');
    }

    if (
      files.reduce(
        (acc, file) => {
          acc.size += file.size;
          return acc;
        },
        { size: 0 },
      ).size >
      10 * 1024 * 1024
    ) {
      errors['attachments'] = t('Total file size is too big. Max 10MB');
    }

    return errors;
  };

  return (
    <Layout title={locationId ? t('Edit location') : t('Create location')} back={true}>
      <Container maxWidth="md">
        {locationId &&
          (() => {
            switch (status) {
              case 'idle':
              case 'loading':
                return (
                  <Box display="flex" height="70vh" width="100%" justifyContent="center" alignItems="center">
                    <CircularProgress />
                  </Box>
                );
              case 'error':
                return (
                  <Box display="flex" height="70vh" width="100%" justifyContent="center" alignItems="center">
                    <Typography>{t('Cannot find this location')}</Typography>
                  </Box>
                );
            }
          })()}

        {(!locationId || status === 'success') && (
          <Paper style={{ padding: 10, margin: 20, marginTop: 40 }} elevation={0}>
            <Formik initialValues={getInitForm(location)} onSubmit={submit} validate={validate}>
              {({ handleSubmit, handleChange, errors, touched, values, setFieldValue }) => (
                <Form>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} direction="column">
                      <TextInput
                        boxProps={{ mb: 0 }}
                        label={t('Name')}
                        value={values.name}
                        error={touched['name'] && errors['name']}
                        helperText={errors['name']}
                        onChange={handleChange('name')}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextareaInput
                        boxProps={{ mb: 0 }}
                        name="description"
                        label={t('Description')}
                        error={touched['description'] && errors['description']}
                        onChange={handleChange('description')}
                        value={values.description}
                      />
                    </Grid>

                    {isTestCenter && (
                      <Grid item xs={12} sm={6}>
                        <Select
                          variant="outlined"
                          fullWidth
                          label={t('Location type')}
                          value={values.type}
                          onChange={handleChange('type')}
                        >
                          <MenuItem disabled={!isTestCenter} value="test-center">
                            {t('Test center')}
                          </MenuItem>
                          <MenuItem value="training-center">{t('Training center')}</MenuItem>
                          <MenuItem disabled={!isTestCenter} value="training-test-center">
                            {t('Training and test center')}
                          </MenuItem>
                        </Select>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.visible}
                            onChange={event => setFieldValue('visible', event.target.checked)}
                          />
                        }
                        label={t('Visible in Partner Find portal')}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        {t('Location info')}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <CountryInput
                        mb={0}
                        label={t('Country')}
                        value={values.country}
                        error={errors['country']}
                        onChange={(_e, value) => setFieldValue('country', value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StateInput
                        mb={0}
                        label={t('State')}
                        value={values.state}
                        error={errors['state']}
                        onChange={(_e, value) => setFieldValue('state', value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        boxProps={{ mb: 0 }}
                        label={t('City')}
                        value={values.city}
                        error={errors['city']}
                        helperText={errors['city']}
                        onChange={handleChange('city')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        boxProps={{ mb: 0 }}
                        label={t('Address')}
                        value={values.address}
                        error={errors['address']}
                        helperText={errors['address']}
                        onChange={handleChange('address')}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextInput
                        boxProps={{ mb: 0 }}
                        label={t('Zip code')}
                        value={values.zip}
                        error={errors['zip']}
                        helperText={errors['zip']}
                        onChange={handleChange('zip')}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextInput
                        boxProps={{ mb: 0 }}
                        label={t('Website')}
                        value={values.website}
                        error={errors['website']}
                        helperText={errors['website']}
                        onChange={handleChange('website')}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box mb={2}>
                        <SelectLanguages
                          label={t('Languages')}
                          value={values.languages}
                          onChange={(_e, value) => setFieldValue('languages', value)}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        {t('Documents')}
                      </Typography>

                      <Typography variant="body2" gutterBottom>
                        {t('Upload documents for this location to send to the Site admin')}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <FileUpload
                        onChange={files => setFieldValue('attachments', files)}
                        value={values.attachments}
                        error={errors['attachments']}
                      />
                    </Grid>

                    {/* Training centers */
                    values?.type.includes('training') && (
                      <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6" gutterBottom>
                            {t('Training center info')}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <SelectCourseTypes
                            mb={0}
                            label={t('Course Type')}
                            values={values.courses}
                            onChange={values => setFieldValue('courses', values)}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <SelectTrainingTopics
                            mb={0}
                            label={t('Training topics')}
                            values={values.topics}
                            onChange={values => setFieldValue('topics', values)}
                          />
                        </Grid>
                      </Grid>
                    )}

                    {/* Test centers */
                    values.type.includes('test') && (
                      <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6" gutterBottom>
                            {t('Test center info')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <SelectSiteAdmin
                            mb={0}
                            label={t('Site Admin')}
                            value={values.site_admin}
                            error={touched['site_admin'] && errors['site_admin']}
                            onChange={(_e, value) => setFieldValue('site_admin', value)}
                          />
                        </Grid>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button variant="contained" color="primary" onClick={handleSubmit} mr={4} disabled={isMutating}>
                          {isMutating ? <CircularProgress size={20} /> : t('Save')}
                        </Button>

                        <Button onClick={() => history.goBack()}>{t('Cancel')}</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        )}
      </Container>
    </Layout>
  );
};

export default LocationFormPage;
