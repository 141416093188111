import React from 'react';
import { useTranslation } from 'react-i18next';

import CommonChip from '../Common/CommonChip';

const InvitationStatusChip = ({ status }) => {
  const { t } = useTranslation();

  const LABELS = {
    pending: t('Pending'),
    accepted: t('Accepted'),
    refused: t('Refused'),
  };

  const COLORS = {
    pending: {
      backgroundColor: '#1565c0',
    },
    accepted: {
      backgroundColor: '#2e7d32',
    },
    refused: {
      backgroundColor: '#d84315',
    },
  };

  return <CommonChip squared label={LABELS[status]} size="small" mapping={COLORS} />;
};

export default React.memo(InvitationStatusChip);
