import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const FETCH_VOUCHERS = 'Vouchers.Fetch';
export const ASSIGN_VOUCHER = 'Vouchers.ChannelAssign';
export const CHANNEL_ASSIGN_VOUCHER = 'Vouchers.Assign';
export const FETCH_VOUCHER_ORDERS = 'VoucherOrders.Fetch';

export const fetchVouchers = createRoutine(FETCH_VOUCHERS);
export const assignVoucher = createRoutine(ASSIGN_VOUCHER);
export const channelAssignVoucher = createRoutine(CHANNEL_ASSIGN_VOUCHER);
export const fetchVoucherOrders = createRoutine(FETCH_VOUCHER_ORDERS);

const INITIAL_REQUEST_STATE = {
  fetchVouchers: { loading: false, error: null },
  fetchVoucherOrders: { loading: false, error: null },
  assignVoucher: { loading: false, error: null },
  channelAssignVoucher: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: [],
  orders: [],
  requests: INITIAL_REQUEST_STATE,
};

const vouchersReducer = handleActions(
  {
    // fetchVouchers
    [fetchVouchers.TRIGGER]: (state, action) =>
      produce(state, draft => {
        draft.requests.fetchVouchers.loading = true;
      }),
    [fetchVouchers.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchVouchers.loading = false;
        draft.list = payload.results;
      }),
    [fetchVouchers.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchVouchers.loading = false;
      }),
    // assignVoucher
    [assignVoucher.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.assignVoucher.loading = true;
        draft.requests.assignVoucher.error = null;
      }),
    [assignVoucher.SUCCESS]: state =>
      produce(state, draft => {
        draft.requests.assignVoucher.loading = false;
      }),
    [assignVoucher.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.assignVoucher.loading = false;
        draft.requests.assignVoucher.error = payload;
      }),
    // channelAssignVoucher
    [channelAssignVoucher.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.channelAssignVoucher.loading = true;
        draft.requests.channelAssignVoucher.error = null;
      }),
    [channelAssignVoucher.SUCCESS]: state =>
      produce(state, draft => {
        draft.requests.channelAssignVoucher.loading = false;
      }),
    [channelAssignVoucher.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.channelAssignVoucher.loading = false;
        draft.requests.channelAssignVoucher.error = payload;
      }),
    // fetchVoucherOrders
    [fetchVoucherOrders.TRIGGER]: (state, action) =>
      produce(state, draft => {
        draft.requests.fetchVoucherOrders.loading = true;
      }),
    [fetchVoucherOrders.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchVoucherOrders.loading = false;
        draft.orders = payload.results;
      }),
    [fetchVoucherOrders.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchVoucherOrders.loading = false;
      }),
  },
  INITIAL_STATE,
);

export default vouchersReducer;
