import { takeLatest, put, all, call } from 'redux-saga/effects';
import { publishJob, notifyJob } from '../reducers/JobsReducer';
import { fetchItems } from '../reducers/GenericReducer';
import i18n from '../../locales';
import api from '../../data/network';
import { setMessage } from '../reducers/SnackReducer';

function* notifyJobSaga({ payload }) {
  try {
    yield call([api.jobs, api.jobs.notify], { job_id: payload });
    yield put(notifyJob.success());
    yield put(fetchItems());
    yield put(setMessage(i18n.t('Done! Your offer has notified')));
  } catch (e) {
    // manda la action failure
    yield put(publishJob.failure(i18n.t('Unable to notify your offer')));
  }
}

function* publishJobSaga({ payload }) {
  try {
    yield call([api.jobs, api.jobs.publish], { job_id: payload });
    yield put(publishJob.success());
    yield put(fetchItems());
    yield put(setMessage(i18n.t('Done! Your offer is under review')));
  } catch (e) {
    // manda la action failure
    yield put(setMessage(i18n.t("Sorry, you don't have enough credits")));
    yield put(publishJob.failure(i18n.t('Unable to publish job')));
  }
}

export default function* root() {
  yield all([yield takeLatest([publishJob.TRIGGER], publishJobSaga)]);
  yield all([yield takeLatest([notifyJob.TRIGGER], notifyJobSaga)]);
}
