import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
}));

const parseCSV = (string, columns) => {
  return string
    .trim()
    .split(/\n/)
    .map(line => {
      var result = {};
      for (var i = 0; i < columns.length; i++) {
        result[columns[i]] = line.split(/,/)[i];
      }
      return result;
    });
};

const CSVLoader = ({ label, color, name, columns, onChange }) => {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  const [filename, setFilename] = React.useState('CSV columns: LPI ID, Email');

  const handleUploadClick = event => {
    var file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function() {
      setFilename(file.name);
      setProgress(0);
      const timer = setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress === 100) {
            clearInterval(timer);
          }
          return Math.min(oldProgress + 20, 100);
        });
      }, 100);

      onChange(file.name, parseCSV(reader.result, columns));
    };
    reader.readAsText(file);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid container item direction="column">
        <Grid item>
          <Typography>{filename}</Typography>
        </Grid>
        <Grid item>
          <LinearProgress variant="determinate" value={progress} />
        </Grid>
      </Grid>
      <Grid item>
        <input name={name} type="file" className={classes.input} onChange={handleUploadClick} id="upload-file-button" />
        <label htmlFor="upload-file-button">
          <Button fullWidth variant="outlined" color={color} component="span" startIcon={<CloudUploadIcon />}>
            {label}
          </Button>
        </label>
      </Grid>
    </Grid>
  );
};

export default React.memo(CSVLoader);
