import _keyBy from 'lodash/keyBy';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const GET_BY_PARTNER = 'Agreements.GetByPartner';
export const getByPartner = createRoutine(GET_BY_PARTNER);

const INITIAL_REQUEST_STATE = {
  getByPartner: { loading: false, error: null },
};

const INITIAL_STATE = {
  byPartner: [],
  requests: INITIAL_REQUEST_STATE,
};

const agreementsReducer = handleActions(
  {
    [getByPartner.TRIGGER]: (state, action) =>
      produce(state, draft => {
        draft.requests.getByPartner.loading = true;
      }),
    [getByPartner.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.getByPartner.loading = false;
        draft.byPartner = _keyBy(payload.results, 'id');
      }),
    [getByPartner.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.getByPartner.loading = false;
      }),
  },
  INITIAL_STATE,
);

export default agreementsReducer;
