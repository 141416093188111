import React from 'react';
import { Box, Typography } from '@material-ui/core';
import emptyImage from '../../assets/images/empty.svg';
import { useTranslation } from 'react-i18next';

const EmptyState = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Box justifyContent="center" alignContent="center" display="flex" flexDirection="column" mt={5}>
      <img src={emptyImage} height={350} alt="empty" />

      <Typography align="center" variant="h1">
        {t('Nothing Here, yet!')}
      </Typography>
      <Box mt={4} display="flex" justifyContent="center">
        {children}
      </Box>
    </Box>
  );
};

export default EmptyState;
