import _keyBy from 'lodash/keyBy';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

// Action Creators
export const STAGE_FETCH = 'Stages.Fetch';
export const STAGE_GET = 'Stages.Get';

export const fetchStages = createRoutine(STAGE_FETCH);
export const getStage = createRoutine(STAGE_GET);

// Initial State
const INITIAL_REQUEST_STATE = {
  fetch: { loading: false, error: null },
  get: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: {},
  offset: 0,
  limit: 25,
  requests: INITIAL_REQUEST_STATE,
};

// Reducer
const stagesReducer = handleActions(
  {
    [fetchStages.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetch.loading = true;
        draft.requests.fetch.error = null;
      }),
    [fetchStages.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.list = _keyBy(payload.results, 'id');
      }),
    [fetchStages.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.requests.fetch.error = payload;
      }),

    [getStage.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.get.loading = true;
        draft.requests.get.error = null;
      }),
    [getStage.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.get.loading = false;
        draft.detail = payload;
      }),
    [getStage.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.get.loading = false;
        draft.requests.get.error = payload;
        draft.detail = null;
      }),
  },
  INITIAL_STATE,
);

export default stagesReducer;
