import { createSelector } from 'reselect';

export const getCoupons = createSelector(
  state => state.coupons.list,
  items => items,
);

export const getCouponPrograms = createSelector(
  state => state.coupons.programs,
  items => items,
);

export const isCouponCreationLoading = createSelector(
  state => state.coupons.requests.createCoupon.loading,
  loading => loading,
);

export const getAssignees = createSelector(
  state => state.coupons.assignees,
  items => items,
);
