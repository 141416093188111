import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { sendEmail } from '../../store/reducers/PasswordReducer';
import { getEmailRequest } from '../../store/selectors/password';

import Page from '../../components/Password/Page';
import BackLink from '../../components/Password/BackLink';
import EmailForm from '../../components/Password/EmailForm';

const EmailPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const request = useSelector(getEmailRequest);

  const handleSubmit = React.useCallback(
    values => {
      dispatch(sendEmail(values));
    },
    [dispatch],
  );

  if (request && request.success) {
    return (
      <Page
        title={t('Password reset')}
        subtitle={t('A link to reset your password has been sent to your email')}
      ></Page>
    );
  }

  return (
    <Page
      title={t('Password reset')}
      subtitle={t(
        "Enter the email address associated with your account and we'll send you a link to reset your password.",
      )}
    >
      <EmailForm error={request.error} loading={request.loading} onSubmit={handleSubmit} />
    </Page>
  );
};

export default React.memo(EmailPage);
