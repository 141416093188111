import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const AutoCompleteFilter = ({ name, label, value, options, optionLabel, onChange, groupBy, inputProps = {} }) => {
  return (
    <Autocomplete
      value={value}
      name={name}
      options={options}
      groupBy={groupBy}
      getOptionLabel={optionLabel}
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          margin="normal"
          variant="outlined"
          inputProps={{ ...params.inputProps, ...inputProps }}
        />
      )}
    />
  );
};

export default React.memo(AutoCompleteFilter);
