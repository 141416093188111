import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import Attachment from '../Training/RequestMaterialForm/Attachment';
import api from '../../data/network';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../store/reducers/SnackReducer';
import useMaterialConfig from '../../hooks/Materials/useMaterialConfig';

const TrainingMaterials = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { config, isLoading: configLoading, status } = useMaterialConfig();
  const {
    setValue,
    handleSubmit,
    watch,
    control,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm();

  const onSubmit = async data => {
    clearErrors();
    if (!data.name) return;
    if (!data.image) {
      return setError('image', { type: 'custom', message: 'Image must be set' });
    }

    try {
      data.image = await toBase64(data.image);
    } catch (e) {
      console.error(e);
    }

    setLoading(true);

    try {
      await api.materialConfig.create(data).catch();

      dispatch(setMessage('Configuration uploaded'));
    } catch (e) {
      console.error(e);
      dispatch(setMessage('An error occurred during the request'));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (config.image) {
      setValue('image', dataURLtoFile(transformOdooBinary(config.image), 'filename'));
    }
    if (config.name) {
      setValue('name', config.name);
    }
    if (config.url) {
      setValue('url', config.url);
    }
  }, [status]);

  // if (configLoading) {
  //   return (
  //     <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
  //       <CircularProgress />
  //     </Box>
  //   );
  // }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ width: '100%' }}>
          <Controller
            name="name"
            control={control}
            rules={{ required: { value: true, message: t('This field is required') } }}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('Company name on material')}
                fullWidth
                defaultValue={config.name}
                variant="outlined"
                disabled={loading}
                error={!!errors.name}
                helperText={errors.name?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} style={{ width: '100%' }}>
          <Controller
            name="url"
            control={control}
            rules={{ required: { value: true, message: t('This field is required') } }}
            render={({ field }) => (
              <TextField
                {...field}
                defaultValue={config.url}
                label={t('Link on material')}
                fullWidth
                variant="outlined"
                disabled={loading}
                error={!!errors.url}
                helperText={errors.url?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          {errors.image && <Typography color="error">{errors.image.message}</Typography>}
          <Attachment
            value={watch('image')}
            onChange={data => {
              clearErrors('image');
              setValue('image', data);
            }}
          />
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            {t('Submit')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function transformOdooBinary(data) {
  return 'data:image/png;base64,' + data;
}

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let res = reader.result.replace(/^data:image\/[a-z]+;base64,/, '');
      resolve(res);
    };
    reader.onerror = error => reject(error);
  });

export default TrainingMaterials;
