import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Formik, Form as FormikForm } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import ActionButton from '../Common/ActionButton';
import TextInput from '../Form/TextInput';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const initialValues = {
  name: '',
  email: '',
};

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors['name'] = i18n.t('Name is required');
  }
  if (!values.email) {
    errors['email'] = i18n.t('Email is required');
  }
  return errors;
};

const InviteUsersForm = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <React.Fragment>
      <ActionButton m={0} mr={1} onClick={() => setModalOpen(true)}>
        {t('Invite User')}
      </ActionButton>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>{t('Invite User')}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            onSubmit={payload => {
              onSubmit(payload);
              setModalOpen(false);
            }}
            validate={validate}
          >
            {({ handleSubmit, handleChange, errors, touched, values }) => (
              <FormikForm>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      label={t('Name')}
                      name="name"
                      value={values['name']}
                      error={touched['name'] && errors['name']}
                      onChange={handleChange('name')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      label={t('Email')}
                      name="email"
                      value={values['email']}
                      error={touched['email'] && errors['email']}
                      onChange={handleChange('email')}
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button onClick={() => setModalOpen(false)} color="default">
                    {'Cancel'}
                  </Button>
                  <div className={classes.wrapper}>
                    <Button onClick={handleSubmit} color="secondary" disabled={isLoading}>
                      {t('Invite')}
                    </Button>
                    {isLoading && <CircularProgress size={24} color="secondary" className={classes.buttonProgress} />}
                  </div>
                </DialogActions>
              </FormikForm>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default React.memo(InviteUsersForm);
