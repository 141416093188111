import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
}));

const TabActions = ({ value, options, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tabs centered value={value} onChange={onChange}>
        {options.map(o => (
          <Tab key={o} label={o} />
        ))}
      </Tabs>
    </div>
  );
};

export default React.memo(TabActions);
