import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import Layout from '../../components/App/Layout';
import Cart from '../../components/Shop/Cart';
import Container from '@material-ui/core/Container';
import { Box, Paper, Typography } from '@material-ui/core';
import useCart from '../../hooks/Shop/useCart';
import EmptyCart from '../../components/Shop/EmptyCart';

const useStyles = makeStyles(theme => ({
  table: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
}));

const CartPage = () => {
  const { t } = useTranslation();
  const { status, cart } = useCart();
  const classes = useStyles();

  return (
    <Layout title={t('Cart')}>
      <Container maxWidth="sm" className={classes.table}>
        {status === 'success' ? (
          cart.lines.count > 0 ? (
            <React.Fragment>
              <Box marginBottom={3}>
                <Typography variant="h2">{t('Your items')}</Typography>
                <Typography variant="h6">{t(`${cart.lines.count} items total`)}</Typography>
              </Box>
              <Paper>
                <Cart toCheckout editable={cart.state === 'draft'} />
              </Paper>
            </React.Fragment>
          ) : (
            <EmptyCart />
          )
        ) : null}
      </Container>
    </Layout>
  );
};

export default React.memo(CartPage);
