import React, { useState, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import { Button, IconButton, InputBase, makeStyles, styled, Typography, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import useCart from '../../hooks/Shop/useCart';
import { CART, CHECKOUT } from '../../navigation/routes';
import { closeCart } from '../../store/reducers/ShopReducer';
import useCartMutations from '../../hooks/Shop/useCartMutations';

const TotalBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '8px',
  paddingBottom: '8px',
});

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },

  item: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  qtyInputBase: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: 10,
  },
  qtyInput: {
    textAlign: 'center',
  },

  qtyButton: {
    backgroundColor: '#ededed',
  },

  couponButton: {
    marginLeft: theme.spacing(2),
  },

  cartImage: {
    width: 80,
    borderRadius: 10,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: 60,
    },
  },

  loadingBox: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, .5)',
  },
}));

const CartItemQty = ({ qty, onItemQtyChange }) => {
  const [value, setValue] = useState(qty);
  const styles = useStyles();

  useEffect(() => {
    setValue(qty);
  }, [qty]);

  return (
    <Box display="flex" alignItems="center" marginTop={1}>
      <IconButton
        size="small"
        classes={{ sizeSmall: styles.qtyButton }}
        onClick={() => onItemQtyChange(qty - 1)}
        data-testid="remove-item"
      >
        <RemoveIcon style={{ fontSize: 12 }} />
      </IconButton>
      <Box width={50}>
        <InputBase
          className={styles.qtyInputBase}
          value={value}
          onChange={ev => setValue(ev.target.value)}
          onBlur={() => onItemQtyChange(value)}
          inputProps={{ className: styles.qtyInput }}
        />
      </Box>
      <IconButton
        size="small"
        classes={{ sizeSmall: styles.qtyButton }}
        onClick={() => onItemQtyChange(qty + 1)}
        data-testid="add-item"
      >
        <AddIcon style={{ fontSize: 12 }} />
      </IconButton>
    </Box>
  );
};

export const CartItem = ({ item, currency, onRemove, onItemQtyChange, editable, loading }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const handleItemQtyChange = qty => {
    onItemQtyChange(item, qty);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection={matches ? 'row' : 'column'}
      position="relative"
      className={styles.item}
    >
      <Box display="flex">
        <Box>{item.image && <img className={styles.cartImage} src={item.image} alt={item.product} />}</Box>
        <Box>
          <Typography variant="subtitle2">{item.product}</Typography>
          <Typography variant="caption">{item.variant}</Typography>
          {editable && (
            <Box>
              <Link onClick={() => onRemove(item)} underline="hover" size="small" to="#" data-testid="delete-item">
                {t('Remove')}
              </Link>
            </Box>
          )}
        </Box>
      </Box>

      <Box textAlign="right">
        <Typography variant="subtitle2">
          {currency.symbol}
          {item.amount.untaxed}
        </Typography>
        {editable && !item.is_reward && <CartItemQty onItemQtyChange={handleItemQtyChange} qty={item.qty} />}
      </Box>
      {loading && <Box display="flex" justifyContent="center" alignItems="center" className={styles.loadingBox} />}
    </Box>
  );
};

const Cart = ({ orderId, editable = true, toCheckout = true, toCart = false }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  // If undefined, fetch default cart
  const { status, cart } = useCart(orderId);
  const { updateItem, removeItem } = useCartMutations();
  const { t } = useTranslation();

  const isItemChanging = updateItem.status === 'loading' || removeItem.status === 'loading';

  switch (status) {
    case 'idle':
    case 'loading':
      return (
        <Box
          className={styles.container}
          display="flex"
          justifyContent="center"
          minHeight="400px"
          minWidth="400px"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      );
    case 'error':
      return null;
    case 'success':
      return (
        <Box className={styles.container}>
          {cart.lines.items.map(item => (
            <CartItem
              editable={editable}
              onItemQtyChange={(item, quantity) => {
                updateItem.mutateAsync({ item, quantity });
              }}
              onRemove={() => removeItem.mutateAsync(item.id)}
              currency={cart.currency}
              key={item.id}
              item={item}
              loading={isItemChanging}
            />
          ))}

          <Divider />

          <TotalBox>
            <Typography variant="subtitle2">{t('Subtotal')}</Typography>
            <Typography variant="subtitle2">
              {cart.currency.symbol}
              {cart.amount.untaxed}
            </Typography>
          </TotalBox>

          <TotalBox>
            <Typography variant="subtitle2">{t('Tax')}</Typography>
            <Typography variant="subtitle2">
              {cart.currency.symbol}
              {cart.amount.tax}
            </Typography>
          </TotalBox>

          <Divider />

          <TotalBox>
            <Typography variant="subtitle2">{t('Total')}</Typography>
            <Typography variant="subtitle2">
              {cart.currency.symbol}
              {cart.amount.total}
            </Typography>
          </TotalBox>
          {toCheckout && (
            <Box marginTop={3} marginBottom={2} display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                component={Link}
                to={CHECKOUT}
                onClick={() => dispatch(closeCart())}
                disabled={isItemChanging}
                data-testid="checkout-btn"
              >
                {isItemChanging ? <CircularProgress size={25} /> : t('CHECKOUT')}
              </Button>
            </Box>
          )}

          {toCart && (
            <Box marginTop={3} marginBottom={2} display="flex" justifyContent="center">
              <Button
                variant="outlined"
                size="large"
                fullWidth
                component={Link}
                to={CART}
                onClick={() => dispatch(closeCart())}
              >
                {t('GO TO CART')}
              </Button>
            </Box>
          )}
        </Box>
      );
  }
};

export default memo(Cart);
