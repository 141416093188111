import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchSkills } from '../reducers/SkillsReducer';
import i18n from '../../locales';
import api from '../../data/network';

function* fetchSkillsSaga({ payload }) {
  try {
    const params = { offset: 0, limit: 9999 };
    const skills = yield call([api.skills, api.skills.search], params);
    yield put(fetchSkills.success(skills));
  } catch (e) {
    yield put(fetchSkills.failure(i18n.t('Unable to fetch skills')));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchSkills.TRIGGER], fetchSkillsSaga)]);
}
