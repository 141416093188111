import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';

import useCertificates from '../../hooks/useCertificates';
import useLanguages from '../../hooks/useLanguages';

const LanguagesInput = ({ error, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const { languages, status } = useLanguages();

  return (
    <Box mb={2}>
      <Autocomplete
        multiple
        value={props.value || []}
        name={props.name}
        loading={status === 'loading'}
        options={languages}
        getOptionLabel={option => option.name}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip key={index} variant="outlined" label={option.name} {...getTagProps({ index })} />
          ))
        }
        renderInput={params => (
          <TextField {...params} label={props.label} error={props.error} fullWidth variant="outlined" />
        )}
        onChange={(_e, value) => {
          setFieldValue(props.name, value || []);
        }}
      />
      {error && <div>{error}</div>}
    </Box>
  );
};

export default React.memo(LanguagesInput);
