import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getStagesList } from '../../store/selectors/stages';
import { useTags } from '../../store/hooks/tags';

import CompanyInput from '../Form/CompanyInput';
import DateInput from '../Form/DateInput';
import Form from '../Generic/Form';
import TextareaInput from '../Form/TextareaInput';
import TextInput from '../Form/TextInput';
import MultiSelectInput from '../Form/MultiSelectInput';
import CountryInput from '../Form/CountryInput';
import { useFormikContext } from 'formik';
import SelectInput from '../Form/SelectInput';

const getInitForm = item => {
  return {
    id: item ? item.id : null,
    name: item ? item.name : '',
    partner_id: item ? item.partner_id : null,
    partner_name: item ? item.partner_name : '',
    email_from: item ? item.email_from : '',
    country_id: item ? item.country_id : null,
    phone: item ? item.phone : '',
    mobile: item ? item.mobile : '',
    website: item ? item.website : '',
    tags: item ? item.tags : [],
    planned_revenue: item ? item.planned_revenue : '',
    date_deadline: item ? item.date_deadline : null,
    stage_id: item ? item.stage_id : 1,
    description: item ? item.description : '',
    type: 'opportunity',
    street: item ? item.street : '',
    city: item ? item.city : '',
    state_id: item ? item.state_id : null,
    vat: item ? item.vat : '',
    zip: item ? item.zip : '',
    signer_name: item ? item.signer_name : '',
    signer_email: item ? item.signer_email : '',
    portal_user_name: item ? item.portal_user_name : '',
    portal_user_email: item ? item.portal_user_email : '',
  };
};

const validate = values => {
  const errors = {};

  if (!values.partner_name) {
    errors['partner_name'] = i18n.t('Partner name is required');
  }

  if (!values.country_id) {
    errors['country_id'] = i18n.t('Country is required');
  }

  if (!values.tags?.length) {
    errors['tags'] = i18n.t('Tags are required');
  }

  if (values.planned_revenue) {
    values.planned_revenue = parseInt(values.planned_revenue) || 0;
  } else {
    values.planned_revenue = undefined;
  }
  values.stage_id = parseInt(values.stage_id);

  return errors;
};

const CountryInputWrapper = ({ label, field, required }) => {
  const { setFieldValue, values, touched, errors } = useFormikContext();
  return (
    <CountryInput
      label={label}
      error={touched[field] && errors[field]}
      onChange={(_e, value) => setFieldValue(field, value?.id || null)}
      valueID={values[field]}
    />
  );
};

const OpportunitiesForm = ({ ...props }) => {
  const { t } = useTranslation();
  const { tags } = useTags();

  return (
    <Form init={getInitForm} validate={validate} {...props}>
      <TextInput label={t('Opportunity Name')} field="name" xs={12} sm={12} required />
      <MultiSelectInput label={t('Tags')} field="tags" options={tags} xs={12} sm={12} required />

      <CompanyInput
        label={t('Company/Account')}
        field="partner_name"
        handleChange={(setFieldValue, value) => {
          if (value) {
            setFieldValue('partner_id', value.id);
            setFieldValue('email_from', value.email ? value.email : '');
            setFieldValue('phone', value.phone ? value.phone : '');
            setFieldValue('mobile', value.mobile ? value.mobile : '');
            setFieldValue('website', value.website ? value.website : '');
          }
        }}
        xs={12}
        required
      />
      <TextInput label={t('Email')} field="email_from" xs={12} sm={6} />
      <TextInput label={t('Phone')} field="phone" xs={12} sm={6} />
      <TextInput label={t('Mobile')} field="mobile" xs={12} sm={6} />
      <TextInput label={t('Website')} field="website" xs={12} sm={6} />
      <CountryInputWrapper label={t('Country')} field="country_id" xs={12} sm={12} required />
      <TextInput label={t('Address')} field="street" xs={12} />
      <TextInput label={t('City')} field="city" xs={12} sm={6} />
      <TextInput label={t('Zip')} field="zip" xs={12} sm={6} />
      <TextInput label={t('VAT')} field="vat" xs={12} sm={6} />
      <TextInput label={t('Expected Revenue')} field="planned_revenue" xs={12} sm={6} />
      <DateInput label={t('Expected Close date')} field="date_deadline" xs={12} sm={6} />
      {/* <SelectInput label={t('Stage')} field="stage_id" options={stages} xs={12} sm={6} /> */}
      <TextareaInput label={t('Notes')} field="description" xs={12} sm={12} />
    </Form>
  );
};

export default React.memo(OpportunitiesForm);
