import React from 'react';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

const TextInput = ({ error, boxProps, ...props }) => {
  return (
    <Box mb={2} {...boxProps}>
      <TextField variant="outlined" fullWidth error={!!error} helperText={error} {...props} />
    </Box>
  );
};

export default React.memo(TextInput);
