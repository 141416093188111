import { JOB_TYPES } from '../common';
import i18next from 'i18next';

const t = i18next.t.bind(i18next);

export const getTranslatedJobTypes = jobType => {
  switch (jobType) {
    case JOB_TYPES.FULL_TIME:
      return t('Full time');
    case JOB_TYPES.PART_TIME:
      return t('Part time');
    case JOB_TYPES.CONTRACT:
      return t('Contract');
    case JOB_TYPES.INTERNSHIP:
      return t('Internship');
    case JOB_TYPES.VOLUNTEER:
      return t('Volunteer');
    default:
      return jobType;
  }
};

export const getTranslatedLocationStatus = value => {
  switch (value) {
    case 'draft':
      return t('Draft');
    case 'lpi-review':
      return t('Under review by LPI');
    case 'site-admin-review':
      return t('Under review by Site Admin');
    case 'verified':
      return t('Verified');
  }
};

export const getTranslatedLocationType = value => {
  switch (value) {
    case 'test-center':
      return t('Test center');
    case 'training-center':
      return t('Training center');
    case 'training-test-center':
      return t('Training and test center');
  }
};
