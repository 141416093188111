import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchTeachers, createTeacher } from '../reducers/TeachersReducer';
import i18n from '../../locales';
import api from '../../data/network';

function* fetchTeachersSaga({ payload }) {
  try {
    const params = { offset: 0, limit: 9999 };

    // effettua la chiamata di rete per leggere i teachers
    const teachers = yield call([api.teachers, api.teachers.search], params);

    // manda la action success
    yield put(fetchTeachers.success(teachers));
  } catch (e) {
    console.error(e);
    // manda la action failure
    yield put(fetchTeachers.failure(i18n.t('Unable to fetch teachers')));
  }
}

function* createTeacherSaga({ payload }) {
  try {
    // effettua la chiamata di rete per leggere i teachers
    const teacher = yield call([api.teachers, api.teachers.create], payload);

    // manda la action success
    yield put(createTeacher.success(teacher));
  } catch (e) {
    console.error(e);
    // manda la action failure
    yield put(createTeacher.failure(i18n.t('Unable to create proctor')));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchTeachers.TRIGGER], fetchTeachersSaga)]);
  yield all([yield takeLatest([createTeacher.TRIGGER], createTeacherSaga)]);
}
