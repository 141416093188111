import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import * as Field from '../Common/Fields';
import Detail from '../Generic/Detail';

const CouponsDetail = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Detail
      typeName={t('coupon')}
      titleComponent={item => (
        <Box display="flex" flexDirection="column">
          <Typography variant="h5" color="textPrimary">
            {item.program_name}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            {item.code}
          </Typography>
        </Box>
      )}
      readonly
      {...props}
    >
      <Field.Text label={t('Partner')} field="lpi_partner_name" />
      <Field.Text label={t('Assigned To')} field="partner_name" />
      <Field.Text label={t('Expiring Date')} field="expiration_date" />
    </Detail>
  );
};

export default React.memo(CouponsDetail);
