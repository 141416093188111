import { useMutation } from 'react-query';
import api from '../../data/network';

const useOpportunitiesMutations = () => {
  /* Define mutation hooks */
  const setLost = useMutation(opportunityId => api.opportunities.lost(opportunityId));
  const update = useMutation(({ id, data }) => api.opportunities.update(id, data));

  return {
    setLost,
    update,
  };
};

export default useOpportunitiesMutations;
