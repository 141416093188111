import _keyBy from 'lodash/keyBy';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

// Action Creators
export const PROCTOR_FETCH = 'Students.Fetch';
export const PROCTOR_CREATE = 'Students.Create';
export const STUDENTS_IMPORT = 'Students.Import';
export const STUDENT_RESEND_INVITE = 'Students.ResendInvite';

export const fetchStudents = createRoutine(PROCTOR_FETCH);
export const createStudent = createRoutine(PROCTOR_CREATE);
export const importStudents = createRoutine(STUDENTS_IMPORT);
export const resendInvite = createRoutine(STUDENT_RESEND_INVITE);

// Initial State
const INITIAL_REQUEST_STATE = {
  fetch: { loading: false, error: null },
  create: { loading: false, error: null },
  import: { loading: false, error: null },
  resendInvite: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: {},
  offset: 0,
  limit: 25,
  requests: INITIAL_REQUEST_STATE,
};

// Reducer
const studentsReducer = handleActions(
  {
    [fetchStudents.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetch.loading = true;
        draft.requests.fetch.error = null;
      }),

    [fetchStudents.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.list = _keyBy(payload.results, 'id');
      }),

    [fetchStudents.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.requests.fetch.error = payload;
      }),

    [createStudent.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.create.loading = true;
        draft.requests.create.error = null;
      }),

    [createStudent.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.create.loading = false;
        draft.list[payload.id] = payload;
      }),

    [createStudent.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.create.loading = false;
        draft.requests.create.error = payload;
      }),

    [importStudents.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.import.loading = true;
        draft.requests.import.error = null;
      }),

    [importStudents.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.import.loading = false;
      }),

    [importStudents.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.import.loading = false;
        draft.requests.import.error = payload;
      }),

    [resendInvite.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.resendInvite.loading = true;
        draft.requests.resendInvite.error = null;
      }),

    [resendInvite.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.resendInvite.loading = false;
      }),

    [resendInvite.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.resendInvite.loading = false;
        draft.requests.resendInvite.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default studentsReducer;
