import _keyBy from 'lodash/keyBy';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

// Action Creators
export const TEACHER_FETCH = 'Teachers.Fetch';
export const TEACHER_CREATE = 'Teachers.Create';

export const fetchTeachers = createRoutine(TEACHER_FETCH);
export const createTeacher = createRoutine(TEACHER_CREATE);

// Initial State
const INITIAL_REQUEST_STATE = {
  fetch: { loading: false, error: null },
  create: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: {},
  offset: 0,
  limit: 25,
  requests: INITIAL_REQUEST_STATE,
};

// Reducer
const teachersReducer = handleActions(
  {
    [fetchTeachers.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetch.loading = true;
        draft.requests.fetch.error = null;
      }),

    [fetchTeachers.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.list = _keyBy(payload.results, 'id');
      }),

    [fetchTeachers.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.requests.fetch.error = payload;
      }),

    [createTeacher.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.create.loading = true;
        draft.requests.create.error = null;
      }),

    [createTeacher.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.create.loading = false;
        draft.list[payload.id] = payload;
      }),

    [createTeacher.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.create.loading = false;
        draft.requests.create.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default teachersReducer;
