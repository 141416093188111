import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, getUser } from '../../store/selectors/auth';
import { update, updatePublic } from '../../store/reducers/AuthReducer';

import { makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Snackbar from '../../components/Common/Snackbar';
import Layout from '../../components/App/Layout';
import Panel from '../../components/Common/Panel';
import TabActions from '../../components/App/TabActions';
import ProfileForm from '../../components/Profile/ProfileForm';
import PublicProfileForm from '../../components/Profile/PublicProfileForm';

const useStyles = makeStyles(theme => ({
  table: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
}));

const TabPanel = ({ value, current, children }) => {
  return value === current && children;
};

const ProfileSinglePage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [isPartnerAdmin, setPartnerAdmin] = useState(false);
  const dispatch = useDispatch();
  const pages = [t('Private'), t('Public')];

  const user = useSelector(getUser);
  const profile = useSelector(getProfile);
  const request = useSelector(state => state.auth.requests.update);
  const requestPublic = useSelector(state => state.auth.requests.updatePublic);

  const handleSubmit = useCallback(values => dispatch(update(values)), [dispatch]);

  const handlePublicSubmit = useCallback(values => dispatch(updatePublic(values)), [dispatch]);

  const handlePageChange = useCallback((e, value) => setPage(value), [setPage]);

  useEffect(() => {
    const currentPartner = user.partner_role.find(partner => partner.lpi_partner_id === profile.id);
    setPartnerAdmin(currentPartner?.partner_role === 'admin');
  }, [user, profile]);

  return (
    <Layout title={profile.name}>
      <TabActions value={page} options={pages} onChange={handlePageChange} />

      <TabPanel className={classes.table} value={0} p current={page}>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} sm={8}>
            <Box>
              <Panel
                title={t('Administrative Profile')}
                subtitle={t('The following data are private, each variation will be submitted for LPI approval.')}
              >
                <Box mb={4}>
                  {!isPartnerAdmin && (
                    <Typography color="error" style={{ fontWeight: 600 }}>
                      {t('You need admin privileges to edit this form')}
                    </Typography>
                  )}
                </Box>

                <ProfileForm
                  data={profile}
                  loading={request.loading}
                  error={request.error}
                  onSubmit={handleSubmit}
                  readonly={!isPartnerAdmin}
                />
              </Panel>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel className={classes.table} value={1} current={page}>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} sm={8}>
            <Box>
              <Panel
                title={t('Public Profile')}
                subtitle={t('The following data is public, people can use it to contact you.')}
              >
                <Box mb={4}>
                  {!isPartnerAdmin && (
                    <Typography color="error" style={{ fontWeight: 600 }}>
                      {t('You need admin privileges to edit this form')}
                    </Typography>
                  )}
                </Box>

                <PublicProfileForm
                  data={profile}
                  loading={requestPublic.loading}
                  error={requestPublic.error}
                  onSubmit={handlePublicSubmit}
                  readonly={!isPartnerAdmin}
                />
              </Panel>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>

      <Snackbar />
    </Layout>
  );
};

export default React.memo(ProfileSinglePage);
