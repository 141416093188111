import React from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../Generic/Table';
import Column from '../Generic/Column';
import StatusChip from '../Common/StatusChip';

const AgreementsTable = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Table {...props}>
      <Column
        label={t('Status')}
        field="stage_id"
        render={value => {
          return <StatusChip status={value} />;
        }}
      />
      <Column label={t('Code')} field="code" />
      <Column label={t('Agreement Type')} field="agreement_type_id" />
      <Column label={t('Contractor')} field="contractor" />
      <Column label={t('Level')} field="tag_ids" render={tags => tags && tags.join(', ')} />
      <Column label={t('Start')} field="start" />
      <Column label={t('End')} field="end" />
    </Table>
  );
};

export default React.memo(AgreementsTable);
