import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import i18n from '../../locales';
import { getEndpoint } from '../selectors/generic';
import { setMessage } from '../reducers/SnackReducer';
import { route, fetchItems, createItem, updateItem, deleteItem } from '../reducers/GenericReducer';
import api from '../../data/network';

function* routeSaga({ payload }) {
  try {
    yield put(route.success(payload));
    yield put(fetchItems());
  } catch (e) {
    console.error(e);
  }
}

function* fetchSaga({ payload, meta }) {
  const ep = meta.endpoint || (yield select(getEndpoint));
  try {
    const params = { offset: 0, limit: 9999 };
    const items = yield call([api[ep], api[ep].search], params);
    yield put(fetchItems.success(items, meta.endpoint));
  } catch (e) {
    console.error(e);
  }
}

function* createSaga({ payload, meta }) {
  const ep = meta.endpoint || (yield select(getEndpoint));
  try {
    const item = yield call([api[ep], api[ep].create], payload);
    yield put(createItem.success(item, meta.endpoint));
    yield put(setMessage(i18n.t('New item created')));
  } catch (e) {
    console.error(e);
  }
}

function* updateSaga({ payload, meta }) {
  const ep = meta.endpoint || (yield select(getEndpoint));
  try {
    const item = yield call([api[ep], api[ep].update], payload.id, payload);
    yield put(updateItem.success(item, meta.endpoint));
    yield put(setMessage(i18n.t('Item details updated')));
  } catch (e) {
    console.error(e);
  }
}

function* deleteSaga({ payload, meta }) {
  const ep = meta.endpoint || (yield select(getEndpoint));
  try {
    const item = yield call([api[ep], api[ep].remove], payload.id);
    yield put(deleteItem.success(item, meta.endpoint));
    yield put(push('/' + ep));
    yield put(setMessage(i18n.t('Item has been deleted')));
  } catch (e) {
    console.error(e);
  }
}

export default function* root() {
  yield all([yield takeLatest([route.TRIGGER], routeSaga)]);
  yield all([yield takeLatest([fetchItems.TRIGGER], fetchSaga)]);
  yield all([yield takeLatest([createItem.TRIGGER], createSaga)]);
  yield all([yield takeLatest([updateItem.TRIGGER], updateSaga)]);
  yield all([yield takeLatest([deleteItem.TRIGGER], deleteSaga)]);
}
