import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useVoucherProducts from '../../../hooks/Shop/useVoucherProducts';
import { VOUCHER_PRODUCT_LIST } from '../../../navigation/routes';

import Layout from '../../../components/App/Layout';
import ProductsGrid from '../../../components/Shop/ProductsGrid';
import Grid from '@material-ui/core/Grid';

const VoucherProductListPage = () => {
  const { t } = useTranslation();
  let history = useHistory();

  const { status, vouchers } = useVoucherProducts();

  const handleClick = React.useCallback(
    product => {
      history.push(`${VOUCHER_PRODUCT_LIST}/${product.id}`);
    },
    [history],
  );

  return (
    <Layout title={t('Voucher Products')}>
      <Grid container justify="center">
        <Grid item xs={12} sm={8}>
          <ProductsGrid products={vouchers} loading={status === 'loading'} handleClick={handleClick} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default React.memo(VoucherProductListPage);
