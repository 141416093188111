import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../data/network';

const QUERY_KEY = 'shop.stripeCheckoutURL';

/**
 * Get the checkout URL for the given orderId
 * @param { string } orderId Required. The order ID of the cart
 */
const useStripeCheckoutURL = orderId => {
  const req = useQuery([QUERY_KEY, orderId], () => api.payments.stripeCheckoutURL({ cart_id: orderId }));

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    checkoutURL: req.data?.checkout_url,
    ...req,
  };
};

export default useStripeCheckoutURL;
