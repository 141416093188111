import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const Column = ({ ...props }) => {
  const classes = useStyles();

  return (
    <TableCell
      align={props.numeric ? 'right' : 'left'}
      padding={props.disablePadding ? 'none' : 'default'}
      sortDirection={props.activeOrder ? props.order : false}
    >
      <TableSortLabel
        active={props.activeOrder}
        direction={props.activeOrder ? props.order : 'asc'}
        onClick={props.sortHandler(props.field)}
      >
        {props.label}
        {props.activeOrder ? (
          <span className={classes.visuallyHidden}>
            {props.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
};

export default React.memo(Column);
