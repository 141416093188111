import React from 'react';

import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Typography } from '@material-ui/core';

const SelectInput = ({ error, options, ...props }) => {
  return (
    <Box mb={2}>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
        <Select
          native
          {...props}
          inputProps={{
            name: props.name,
            id: props.name,
          }}
        >
          {options.map(function(option, i) {
            return (
              <option key={i} value={option.id}>
                {option.name}
              </option>
            );
          })}
        </Select>
        {error && <Typography color="error">{error}</Typography>}
      </FormControl>
    </Box>
  );
};

export default React.memo(SelectInput);
