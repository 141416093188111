import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../data/network';

const QUERY_KEY = 'helpdesk_tickets';

/** Get a single location */
const useHelpdeskTickets = () => {
  const req = useQuery([QUERY_KEY], () => api.tickets.search());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    tickets: req.data?.results || [],
    ...req,
  };
};

export default useHelpdeskTickets;
