import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogTitle, Chip, DialogActions, DialogContent, CircularProgress } from '@material-ui/core';

import { useFilters } from '../../store/hooks/filters';

import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { searchText } from '../../components/Common/Filters/TextFilter';
import * as Field from '../../components/Common/Fields';
import EmailTitle from '../../components/Common/EmailTitle';
import DataTable from '../../components/Common/DataTable/Page';
import TextFilter from '../../components/Common/Filters/TextFilter';
import StatusFilter from '../../components/Students/Filters/StatusFilter';
import AddHiringAction from '../../components/Students/AddHiringAction';
import InvitationStatusChip from '../../components/Students/InvitationStatusChip';
import PartnerTagsAutocomplete from '../../components/Common/Filters/PartnerTagsAutocomplete';
import EditTeacherForm from '../../components/Teachers/EditTeacherForm';
import { useDispatch } from 'react-redux';
import useTeachers from '../../hooks/Teachers/useTeachers';
import useTeachersMutations from '../../hooks/Teachers/useTeachersMutations';
import { setMessage } from '../../store/reducers/SnackReducer';

const TeachersListPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const [dialog, setDialog] = React.useState({ open: false, title: null, content: null });
  const { teachers } = useTeachers();
  const { createTeacher, deleteTeacher } = useTeachersMutations();

  const filters = useFilters({
    name: {
      chip: value => `${t('Name')}: ${value}`,
      component: (currentFilters, setFilter) => (
        <TextFilter label={t('Name')} field="name" currentFilters={currentFilters} setFilter={setFilter} />
      ),
    },
    email: {
      chip: value => `${t('Email')}: ${value}`,
      component: (currentFilters, setFilter) => (
        <TextFilter label={t('Email')} field="email" currentFilters={currentFilters} setFilter={setFilter} />
      ),
    },
    lpi_id: {
      chip: value => `${t('LPI ID')}: ${value}`,
      component: (currentFilters, setFilter) => (
        <TextFilter label={t('LPI ID')} field="lpi_id" currentFilters={currentFilters} setFilter={setFilter} />
      ),
    },
    tags: {
      chip: values => `${t('Tags')}: ${values.map(value => value.name).join(', ')}`,
      component: (currentFilters, setFilter) => (
        <PartnerTagsAutocomplete
          value={currentFilters['tags'] || []}
          onChange={(_, newValue) => setFilter('tags', newValue?.length > 0 ? newValue : '')}
        />
      ),
    },
    status: {
      chip: value => `${t('Status')}: ${value}`,
      component: (currentFilters, setFilter) => <StatusFilter currentFilters={currentFilters} setFilter={setFilter} />,
    },
  });

  const executeFilters = items => {
    let filtered = [...items];
    if ('name' in filters.current) {
      filtered = filtered.filter(item => searchText(item.partner_name, filters.current.name));
    }
    if ('email' in filters.current) {
      filtered = filtered.filter(item => searchText(item.partner_email, filters.current.email));
    }
    if ('lpi_id' in filters.current) {
      filtered = filtered.filter(item => searchText(item.lpi_id, filters.current.lpi_id));
    }
    if ('status' in filters.current) {
      filtered = filtered.filter(item => item.state === filters.current.status);
    }
    if ('tags' in filters.current) {
      filtered = filtered.filter(item => {
        const tag_ids = item.tags.map(tag => tag.id);
        return filters.current.tags.every(tag => tag_ids.includes(tag.id));
      });
    }
    return filtered;
  };

  const getDetailHeaderProps = item => ({
    title: t('teacher'),
    subheader: <EmailTitle item={item} titleField="partner_name" emailField="partner_email" />,
  });

  const getDetailContent = item => (
    <React.Fragment>
      <Field.Paragraph label={t('Bio')} value={item.bio} />
      {!!item.membership_start && <Field.Text label={t('Authorization Data')} value={item.membership_start} />}
      {!!item.membership_stop && <Field.Text label={t('Expiring Data')} value={item.membership_stop} />}
      {!!item.certifications?.length && (
        <Field.Chip label={t('Certifications')} value={item.certifications} many color="default" />
      )}
      {!!item.tags?.length && (
        <Field.Chip label={t('Tags')} value={item.tags.map(tag => tag.name)} many color="primary" />
      )}
    </React.Fragment>
  );

  const getActions = () => (
    <AddHiringAction
      label={t('Add Teacher')}
      onSubmit={payload => {
        if (
          teachers.find(teacher => teacher.partner_email === payload.partner_email || teacher.lpi_id === payload.lpi_id)
        ) {
          dispatch(setMessage(t('You already added this teacher')));
          return;
        }

        createTeacher
          .mutateAsync(payload)
          .then(() => dispatch(setMessage(t('Teacher created'))))
          .catch(() => dispatch(setMessage(t('An error occurred during the request'))));
      }}
      isLoading={createTeacher.status === 'loading'}
    />
  );

  const getDetailActions = item => (
    <>
      <Button
        onClick={() =>
          setDialog({
            open: true,
            title: t('Edit teacher'),
            content: (
              <EditTeacherForm partnerId={item.partner_id} onClose={() => setDialog({ ...dialog, open: false })} />
            ),
          })
        }
      >
        {t('Edit')}
      </Button>

      <Button
        onClick={() =>
          setDialog({
            open: true,
            title: t('Delete teacher'),
            content: (
              <>
                <DialogContent>{t('Are you sure you want to delete this teacher?')}</DialogContent>
                <DialogActions>
                  <Button onClick={() => setDialog({ ...dialog, open: false })}>{t('Cancel')}</Button>
                  <Button
                    onClick={() =>
                      deleteTeacher
                        .mutateAsync(item.partner_id)
                        .then(() => dispatch(setMessage(t('Teacher deleted successfully'))))
                        .catch(() => dispatch(setMessage(t('An error occurred during the request'))))
                        .finally(() => setDialog({ ...dialog, open: false }))
                    }
                    color="secondary"
                    disabled={deleteTeacher.status === 'loading'}
                  >
                    {deleteTeacher.status === 'loading' ? <CircularProgress size={20} /> : t('Confirm')}
                  </Button>
                </DialogActions>
              </>
            ),
          })
        }
      >
        {t('Delete')}
      </Button>
    </>
  );

  return (
    <>
      <DataTable
        page={{ title: t('Teachers'), subtitle: t('Register your teachers'), actions: getActions }}
        items={teachers}
        columns={{
          partner_name: { label: t('Name') },
          lpi_id: { label: t('LPI ID') },
          state: {
            label: t('Status'),
            render: value => value && <InvitationStatusChip status={value} />,
          },
          certifications: {
            label: t('Certifications'),
            render: value => value && value.join(', '),
          },
          lang: {
            label: t('Languages'),
            render: value => value && value.join(', '),
          },
          membership_stop: { label: t('Expiring Date') },
          tags: {
            label: t('Tags'),
            render: values =>
              !!values &&
              values.map(value => (
                <Chip
                  key={value.id}
                  label={value.name}
                  size="small"
                  color="primary"
                  style={{ marginRight: 3, marginBottom: 3 }}
                />
              )),
          },
        }}
        filters={{ ...filters, execute: executeFilters }}
        detail={{
          headerProps: getDetailHeaderProps,
          content: getDetailContent,
          actions: getDetailActions,
        }}
        detailLink={value => path + '/' + value.id}
      />

      <Dialog open={dialog.open} onClose={() => setDialog({ ...dialog, open: false })}>
        <DialogTitle>{dialog.title}</DialogTitle>
        {dialog.content}
      </Dialog>
    </>
  );
};

export default React.memo(TeachersListPage);
