import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const FETCH_COUPONS = 'Coupons.Fetch';
export const FETCH_COUPON_PROGRAMS = 'Coupon.Programs.Fetch';
export const CREATE_COUPON = 'Coupons.Create';
export const ASSIGN_COUPON = 'Coupons.Assign';
export const FETCH_COUPON_ASSIGNEES = 'Coupons.FetchAssignees';

export const fetchCoupons = createRoutine(FETCH_COUPONS);
export const fetchCouponPrograms = createRoutine(FETCH_COUPON_PROGRAMS);
export const createCoupon = createRoutine(CREATE_COUPON);
export const assignCoupon = createRoutine(ASSIGN_COUPON);
export const fetchAssignees = createRoutine(FETCH_COUPON_ASSIGNEES);

const INITIAL_REQUEST_STATE = {
  fetchCoupons: { loading: false, error: null },
  fetchCouponPrograms: { loading: false, error: null },
  createCoupon: { loading: false, error: null },
  assignCoupon: { loading: false, error: null },
  fetchAssignees: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: [],
  assignees: [],
  programs: [],
  requests: INITIAL_REQUEST_STATE,
};

const couponsReducer = handleActions(
  {
    // fetchCoupons
    [fetchCoupons.TRIGGER]: (state, action) =>
      produce(state, draft => {
        draft.requests.fetchCoupons.loading = true;
      }),
    [fetchCoupons.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchCoupons.loading = false;
        draft.list = payload.results;
      }),
    [fetchCoupons.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchCoupons.loading = false;
      }),
    // createCoupon
    [createCoupon.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.createCoupon.loading = true;
        draft.requests.createCoupon.error = null;
      }),
    [createCoupon.SUCCESS]: state =>
      produce(state, draft => {
        draft.requests.createCoupon.loading = false;
      }),
    [createCoupon.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.createCoupon.loading = false;
        draft.requests.createCoupon.error = payload;
      }),
    // assignCoupon
    [assignCoupon.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.assignCoupon.loading = true;
        draft.requests.assignCoupon.error = null;
      }),
    [assignCoupon.SUCCESS]: state =>
      produce(state, draft => {
        draft.requests.assignCoupon.loading = false;
      }),
    [assignCoupon.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.assignCoupon.loading = false;
        draft.requests.assignCoupon.error = payload;
      }),
    // fetchCouponPrograms
    [fetchCouponPrograms.TRIGGER]: (state, action) =>
      produce(state, draft => {
        draft.requests.fetchCouponPrograms.loading = true;
      }),
    [fetchCouponPrograms.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchCouponPrograms.loading = false;
        draft.programs = payload.results;
      }),
    [fetchCouponPrograms.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchCouponPrograms.loading = false;
      }),
    // fetchAssignees
    [fetchAssignees.TRIGGER]: (state, action) =>
      produce(state, draft => {
        draft.requests.fetchAssignees.loading = true;
      }),
    [fetchAssignees.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchAssignees.loading = false;
        draft.assignees = payload.results;
      }),
    [fetchAssignees.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchAssignees.loading = false;
      }),
  },
  INITIAL_STATE,
);

export default couponsReducer;
