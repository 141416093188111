import { takeLatest, put, all, call } from 'redux-saga/effects';
import {
  fetchTrainingCenters,
  createTrainingCenter,
  updateTrainingCenter,
  removeTrainingCenter,
} from '../reducers/TrainingCentersReducer';
import i18n from '../../locales';
import api from '../../data/network';
import { setMessage } from '../reducers/SnackReducer';

function* fetchTrainingCentersSaga({ payload }) {
  try {
    const params = { offset: 0, limit: 9999 };
    const trainingCenters = yield call([api.trainingCenter, api.trainingCenter.search], params);
    const courses = yield call([api.trainingCenter, api.trainingCenter.courses]);
    const topics = yield call([api.trainingCenter, api.trainingCenter.topics]);
    const languages = yield call([api.trainingCenter, api.trainingCenter.languages]);
    const countries = yield call([api.trainingCenter, api.trainingCenter.countries]);
    yield put(
      fetchTrainingCenters.success({
        centers: trainingCenters.results,
        courses: courses.results,
        topics: topics.results,
        languages: languages.results,
        countries: countries.results,
      }),
    );
  } catch (e) {
    yield put(fetchTrainingCenters.failure(i18n.t('Unable to fetch training centers')));
  }
}

function* createTrainingCenterSaga({ payload }) {
  try {
    const trainingCenter = yield call([api.trainingCenter, api.trainingCenter.create], payload);

    yield put(createTrainingCenter.success(trainingCenter));
    yield put(setMessage(i18n.t('Training center created')));
  } catch (e) {
    yield put(setMessage(i18n.t('Unable to create training center')));
    yield put(createTrainingCenter.failure(i18n.t('Unable to create training center')));
  }
}

function* updateTrainingCenterSaga({ payload }) {
  try {
    const trainingCenter = yield call([api.trainingCenter, api.trainingCenter.update], payload.id, payload);

    yield put(updateTrainingCenter.success(trainingCenter));
    yield put(setMessage(i18n.t('Training center updated')));
  } catch (e) {
    yield put(setMessage(i18n.t('Unable to update training center')));
    yield put(updateTrainingCenter.failure(i18n.t('Unable to update training center')));
  }
}

function* removeTrainingCenterSaga({ payload }) {
  try {
    const trainingCenter = yield call([api.trainingCenter, api.trainingCenter.remove], payload.id);

    yield put(removeTrainingCenter.success(trainingCenter));
    yield put(setMessage(i18n.t('Training center removed')));
  } catch (e) {
    yield put(setMessage(i18n.t('Unable to remove training center')));
    yield put(removeTrainingCenter.failure(i18n.t('Unable to remove training center')));
  }
}

export default function* root() {
  yield all([
    yield takeLatest([fetchTrainingCenters.TRIGGER], fetchTrainingCentersSaga),
    yield takeLatest([createTrainingCenter.TRIGGER], createTrainingCenterSaga),
    yield takeLatest([updateTrainingCenter.TRIGGER], updateTrainingCenterSaga),
    yield takeLatest([removeTrainingCenter.TRIGGER], removeTrainingCenterSaga),
  ]);
}
