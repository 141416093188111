import _isEmpty from 'lodash/isEmpty';
import _values from 'lodash/values';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import EmptyState from '../Common/EmptyState';
import LoadingState from '../Common/LoadingState';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  notAvailable: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#263238',
    padding: '.5rem',
  },
  image: {
    height: 150,
    width: 250,
    objectFit: 'cover',
  },
  gridItem: {
    position: 'relative',
  },
  unavailableOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(55,55,55,.5)',
    pointerEvents: 'none',
    zIndex: '100',
  },
  table: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
}));

const ProductsGrid = ({ products, loading, handleClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return !_isEmpty(products) ? (
    <Box width="100%">
      {_values(products).map(product => (
        <Box marginY={2} key={product.id}>
          <Paper>
            <Box padding={4} display="flex" width="100%" className={classes.gridItem}>
              {product.is_available === false && (
                <Box display="flex" justifyContent="center" alignItems="center" className={classes.unavailableOverlay}>
                  <Typography className={classes.notAvailable} variant="h5" gutterBottom color="primary">
                    {t('Product Unavailable')}
                  </Typography>
                </Box>
              )}
              <img src={product.image} alt={product.name} className={classes.image} />
              <Box p={3}>
                <Typography className={classes.title} variant="caption" gutterBottom>
                  {t('Exam Voucher')}
                </Typography>
                <Typography variant="h5">{product.name}</Typography>

                <Box marginTop={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      product.is_available === true && handleClick(product);
                    }}
                  >
                    {t('Buy')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      ))}
    </Box>
  ) : loading ? (
    <LoadingState />
  ) : (
    <EmptyState />
  );
};

export default React.memo(ProductsGrid);
