import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { FaFile } from 'react-icons/fa';

const FileUpload = ({ value, onChange, error }) => {
  const inputFile = React.useRef();
  const { t } = useTranslation();

  const valueArr = useMemo(() => (value ? Array.from(value) : []), [value]);

  const handleChooseFilesClick = () => {
    inputFile.current.click();
  };

  const handleFilesChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      onChange(e.target.files);
    }
  };

  return (
    <React.Fragment>
      <input type="file" multiple ref={inputFile} style={{ display: 'none' }} onChange={handleFilesChange} />

      {valueArr && valueArr.length > 0 && (
        <List dense>
          {valueArr.map((file, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <FaFile />
              </ListItemIcon>
              <ListItemText
                primary={file.name}
                secondary={`${
                  file.size / 1024 / 1024 > 1
                    ? `${Math.round(file.size / 1024 / 1024)} MB`
                    : `${Math.round(file.size / 1024)} KB`
                }`}
              />
            </ListItem>
          ))}
        </List>
      )}

      <Button onClick={handleChooseFilesClick} variant="outlined" fullWidth>
        {t('Upload documents')}
      </Button>

      {error && <div style={{ color: 'red' }}>{error}</div>}
    </React.Fragment>
  );
};

export default FileUpload;
