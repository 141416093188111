import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, getUser } from '../selectors/auth';
import { fetchUsers, createUser, updateUser, toggleUserState, changeUserRole } from '../reducers/UsersReducer';

const useUsers = () => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.users.list);
  const requests = useSelector(state => state.users.requests);

  React.useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return {
    users,
    inviteUser: id => dispatch(createUser(id)),
    toggleUser: id => dispatch(toggleUserState(id)),
    requests,
  };
};

const useUser = id => {
  const dispatch = useDispatch();

  const loggedUser = useSelector(getUser);
  const profile = useSelector(getProfile);
  const users = useSelector(state => state.users.list);
  const requests = useSelector(state => state.users.requests);

  const [userId, setUserId] = React.useState(parseInt(id));
  const [user, setUser] = React.useState();
  const [isAdmin, setAdmin] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  React.useEffect(() => {
    if (userId) {
      setUser(users[userId]);
    }
  }, [users, userId]);

  React.useEffect(() => {
    if (loggedUser?.partner_role) {
      if (id === undefined) {
        setUserId(loggedUser.partner_role?.[0]?.id);
      }
      const current = loggedUser.partner_role.find(partner => partner.lpi_partner_id === profile.id);
      setAdmin(current?.partner_role === 'admin');
    }
  }, [loggedUser, id, profile]);

  React.useEffect(() => {
    if (id !== undefined) {
      setUserId(id);
    }
  }, [id]);

  return {
    user,
    changeRole: role => dispatch(changeUserRole({ id: parseInt(userId), partner_role: role })),
    toggleUser: () => dispatch(toggleUserState(parseInt(userId))),
    updateUser: values => dispatch(updateUser({ id: userId, ...values })),
    requests,
    isAdmin,
    loggedUser,
  };
};

export { useUsers, useUser };
