import _keyBy from 'lodash/keyBy';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

// Action Creators
export const USER_FETCH = 'Users.Fetch';
export const USER_CREATE = 'Users.Create';
export const USER_UPDATE = 'Users.Update';
export const USER_TOGGLE_STATE = 'Users.ToggleState';
export const USER_CHANGE_ROLE = 'Users.ChangeRole';

export const fetchUsers = createRoutine(USER_FETCH);
export const createUser = createRoutine(USER_CREATE);
export const updateUser = createRoutine(USER_UPDATE);
export const toggleUserState = createRoutine(USER_TOGGLE_STATE);
export const changeUserRole = createRoutine(USER_CHANGE_ROLE);

// Initial State
const INITIAL_REQUEST_STATE = {
  fetch: { loading: false, error: null },
  create: { loading: false, error: null },
  updateUser: { loading: false, error: null },
  toggleUserState: { loading: false, error: null },
  changeUserRole: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: {},
  offset: 0,
  limit: 25,
  requests: INITIAL_REQUEST_STATE,
};

// Reducer
const usersReducer = handleActions(
  {
    [fetchUsers.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetch.loading = true;
        draft.requests.fetch.error = null;
      }),

    [fetchUsers.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.list = _keyBy(payload.results, 'id');
      }),

    [fetchUsers.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.requests.fetch.error = payload;
      }),

    [createUser.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.create.loading = true;
        draft.requests.create.error = null;
      }),

    [createUser.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.create.loading = false;
      }),

    [createUser.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.create.loading = false;
        draft.requests.create.error = payload;
      }),

    [updateUser.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.updateUser.loading = true;
        draft.requests.updateUser.error = null;
      }),

    [updateUser.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.updateUser.loading = false;
        draft.list[payload.id] = payload;
      }),

    [updateUser.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.updateUser.loading = false;
        draft.requests.updateUser.error = payload;
      }),

    [toggleUserState.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.toggleUserState.loading = true;
        draft.requests.toggleUserState.error = null;
      }),

    [toggleUserState.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.list = _keyBy(payload.results, 'id');
        draft.requests.toggleUserState.loading = false;
      }),

    [toggleUserState.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.toggleUserState.loading = false;
        draft.requests.toggleUserState.error = payload;
      }),

    [changeUserRole.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.changeUserRole.loading = true;
        draft.requests.changeUserRole.error = null;
      }),

    [changeUserRole.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.list[payload.id] = payload;
        draft.requests.changeUserRole.loading = false;
      }),

    [changeUserRole.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.changeUserRole.loading = false;
        draft.requests.changeUserRole.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default usersReducer;
