import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVouchers, fetchVoucherOrders } from '../reducers/VouchersReducer';
import { getVouchers, getVoucherOrders } from '../selectors/vouchers';

const useVouchers = () => {
  const dispatch = useDispatch();
  const vouchers = useSelector(getVouchers);
  const [wrapper, setWrapper] = React.useState({
    all: [],
    new: [],
    old: [],
  });

  React.useEffect(() => {
    dispatch(fetchVouchers());
  }, [dispatch]);

  const filterIsNew = React.useCallback(
    isNew =>
      vouchers.filter(item =>
        isNew ? ['new', 'assigned'].includes(item.state) : !['new', 'assigned'].includes(item.state),
      ),
    [vouchers],
  );

  React.useEffect(() => {
    setWrapper({
      all: vouchers,
      new: filterIsNew(true),
      old: filterIsNew(false),
    });
  }, [vouchers, filterIsNew]);

  return wrapper;
};

const useVoucherOrders = () => {
  const dispatch = useDispatch();
  const orders = useSelector(getVoucherOrders);

  React.useEffect(() => {
    dispatch(fetchVoucherOrders());
  }, [dispatch]);

  return { orders };
};

export { useVouchers, useVoucherOrders };
