import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getByPartner } from '../reducers/AgreementsReducer';
import { getPartnerAgreements } from '../selectors/agreements';

const usePartnerAgreements = partner => {
  // channel_agreements
  const dispatch = useDispatch();

  const agreements = useSelector(getPartnerAgreements);
  const [partnerAgreements, setPartnerAgreements] = React.useState([]);

  React.useEffect(() => {
    dispatch(getByPartner());
  }, [dispatch]);

  React.useEffect(() => {
    if (partner) {
      setPartnerAgreements(agreements.filter(item => item.partner_id === partner.id));
    }
  }, [partner, agreements]);

  return { agreements: partnerAgreements };
};

export { usePartnerAgreements };
