import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchContacts } from '../reducers/ContactsReducer';
import i18n from '../../locales';
import api from '../../data/network';

function* fetchContactsSaga({ payload }) {
  try {
    const params = { offset: 0, limit: 9999 };
    const contacts = yield call([api.contacts, api.contacts.search], params);

    yield put(fetchContacts.success(contacts));
  } catch (e) {
    console.error(e);
    yield put(fetchContacts.failure(i18n.t('Unable to fetch contacts')));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchContacts.TRIGGER], fetchContactsSaga)]);
}
