import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const FETCH_TAGS = 'Tags.Fetch';
export const fetchTags = createRoutine(FETCH_TAGS);

const INITIAL_REQUEST_STATE = {
  fetchTags: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: [],
  requests: INITIAL_REQUEST_STATE,
};

const tagsReducer = handleActions(
  {
    [fetchTags.TRIGGER]: (state, action) =>
      produce(state, draft => {
        draft.requests.fetchTags.loading = true;
      }),
    [fetchTags.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchTags.loading = false;
        draft.list = payload.results.map(item => item.name);
      }),
    [fetchTags.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetchTags.loading = false;
      }),
  },
  INITIAL_STATE,
);

export default tagsReducer;
