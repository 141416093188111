import { useMutation, useQueryClient } from 'react-query';
import api from '../../data/network';

const useHelpdeskMutations = () => {
  const client = useQueryClient();

  /* Define mutation hooks */
  const createTicket = useMutation(
    ({ queue, title, message }) =>
      api.tickets.create({
        queue_alias: queue,
        title,
        message,
      }),
    {
      onSuccess: () => {
        client.invalidateQueries('helpdesk_tickets');
      },
    },
  );

  return {
    createTicket,
  };
};

export default useHelpdeskMutations;
