import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchStages } from '../../store/reducers/StagesReducer';

import Page from '../../components/Generic/Page';

import OpportunitiesActions from '../../components/Opportunities/OpportunitiesActions';
import OpportunitiesDetail from '../../components/Opportunities/OpportunitiesDetail';
import OpportunitiesFilters from '../../components/Opportunities/OpportunitiesFilters';
import OpportunitiesForm from '../../components/Opportunities/OpportunitiesForm';
import OpportunitiesTable from '../../components/Opportunities/OpportunitiesTable';

const OpportunitiesListPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(fetchStages({}));
  }, [dispatch]);

  const labels = {
    title: t('Opportunities'),
    createLabel: t('Create opportunity'),
    updateLabel: t('Update opportunity'),
    confirmDeleteLabel: t('Delete this opportunity?'),
  };

  return (
    <Page
      ep={'opportunities'}
      subtitle={t('Create and manage your opportunities')}
      labels={labels}
      Actions={OpportunitiesActions}
      Detail={OpportunitiesDetail}
      Filters={OpportunitiesFilters}
      Form={OpportunitiesForm}
      Table={OpportunitiesTable}
    />
  );
};

export default React.memo(OpportunitiesListPage);
