import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import * as routes from '../navigation/routes';
import PrivateRoute from '../components/Common/PrivateRoute';
import ScrollToTop from '../components/Common/ScrollToTop';
// Pages
// import DashboardPage from "../pages/DashboardPage"
import LoginPage from '../pages/LoginPage';
import NotFoundPage from '../pages/NotFoundPage';
import UserProfilePage from '../pages/UserProfilePage';
// Company Pages
import AgreementsListPage from '../pages/Company/AgreementsListPage';
import ProfileSinglePage from '../pages/Company/ProfileSinglePage';
import UsersListPage from '../pages/Company/UsersListPage';
import VouchersPage from '../pages/Company/VouchersPage';
import VoucherOrdersPage from '../pages/Company/VoucherOrdersPage';
import CouponsPage from '../pages/Company/CouponsPage';
// Portfolio Pages
import PartnerDetailsPage from '../pages/Portfolio/PartnerDetailPage';
import PartnersListPage from '../pages/Portfolio/PartnersListPage';
// Sales Pages
import LeadsListPage from '../pages/Sales/LeadsListPage';
import OpportunitiesListPage from '../pages/Sales/OpportunitiesListPage';
// Training Pages
import StudentsListPage from '../pages/Training/StudentsListPage';
import TeachersListPage from '../pages/Training/TeachersListPage';
// Test centers
import ProctorsListPage from '../pages/TestCenters/ProctorsListPage';
import SiteAdminsListPage from '../pages/TestCenters/SiteAdminsListPage';
// Hiring Pages
import OffersListPage from '../pages/Hiring/OffersListPage';
import CandidatesListPage from '../pages/Hiring/CandidatesListPage';
// Shop Pages
import CartPage from '../pages/Shop/CartPage';
import CheckoutPage from '../pages/Shop/CheckoutPage';
import JobProductListPage from '../pages/Shop/Job/JobProductListPage';
import JobProductPage from '../pages/Shop/Job/JobProductPage';
import JobOfferPage from '../pages/Shop/Job/JobOfferPage';
import VoucherProductListPage from '../pages/Shop/Voucher/VoucherProductListPage';
import VoucherProductPage from '../pages/Shop/Voucher/VoucherProductPage';
import SaleOrdersPage from '../pages/Shop/SaleOrdersPage';
// Password change/reset + Welcome Pages
import EmailPage from '../pages/Password/EmailPage';
import ResetPage from '../pages/Password/ResetPage';
import WelcomePage from '../pages/Password/WelcomePage';
import DocumentsPage from '../pages/Documents/DocumentsPage';
import LearningMaterialsPage from '../pages/Training/LearningMaterialsPage';
import ServerUnderMaintenancePage from '../pages/ServerUnderMaintenancePage';
import LocationsPage from '../pages/Company/LocationsPage';
import LocationFormPage from '../pages/Company/LocationFormPage';
import ManualOrder from '../pages/Shop/ManualOrder';
import TicketsPage from '../pages/Support/TicketsPage';
import TicketCreatePage from '../pages/Support/TicketCreatePage';
import TicketDetailPage from '../pages/Support/TicketDetailPage';

const AppRouter = () => {
  return (
    <ScrollToTop>
      <Switch>
        <Route path={routes.LOGIN} component={LoginPage} />
        <Route exact path={routes.HOME}>
          <Redirect to={routes.PROFILE_SINGLE} />
        </Route>
        <PrivateRoute path={routes.PROFILE} component={UserProfilePage} />
        <PrivateRoute path={routes.PROFILE_DETAILS} component={UserProfilePage} />
        <PrivateRoute path={routes.PROFILE_SINGLE} component={ProfileSinglePage} />
        <PrivateRoute path={routes.USERS_LIST} component={UsersListPage} />
        <PrivateRoute path={routes.VOUCHERS_LIST} component={VouchersPage} />
        <PrivateRoute path={routes.COUPONS_LIST} component={CouponsPage} />
        <PrivateRoute path={routes.VOUCHER_ORDERS} component={VoucherOrdersPage} />
        <PrivateRoute path={routes.AGREEMENTS_LIST} component={AgreementsListPage} />
        <PrivateRoute path={routes.LOCATIONS} component={LocationsPage} />
        <PrivateRoute path={routes.LOCATION_CREATE} component={LocationFormPage} />
        <PrivateRoute path={routes.LOCATION_UPDATE} component={LocationFormPage} />

        {/* Training */}
        <PrivateRoute path={routes.TEACHERS_LIST} component={TeachersListPage} />
        <PrivateRoute path={routes.STUDENTS_LIST} component={StudentsListPage} />
        <PrivateRoute path={routes.MATERIALS_LIST} component={LearningMaterialsPage} />

        <PrivateRoute exact path={routes.PARTNERS_LIST} component={PartnersListPage} />

        <PrivateRoute path={routes.PARTNERS_DETAILS} component={PartnerDetailsPage} />
        <PrivateRoute path={routes.LEADS_LIST} component={LeadsListPage} />
        <PrivateRoute path={routes.OPPORTUNITIES_LIST} component={OpportunitiesListPage} />
        <PrivateRoute path={routes.JOBS_LIST} component={OffersListPage} />
        <PrivateRoute path={routes.CANDIDATES_LIST} component={CandidatesListPage} />
        <PrivateRoute path={routes.ALL_DOCUMENTS} component={DocumentsPage} />

        <PrivateRoute path={routes.CART} component={CartPage} />
        <PrivateRoute path={routes.MANUAL_ORDER} component={ManualOrder} />

        <PrivateRoute path={routes.CHECKOUT} component={CheckoutPage} />
        <PrivateRoute path={routes.CHECKOUT_ORDER} component={CheckoutPage} />

        {/* Test Center */}
        <PrivateRoute path={routes.PROCTORS_LIST} component={ProctorsListPage} />
        <PrivateRoute path={routes.SITE_ADMINS_LIST} component={SiteAdminsListPage} />

        {/* Jobs */}
        <PrivateRoute path={routes.JOB_PRODUCT_DETAILS} component={JobProductPage} />
        <PrivateRoute exact path={routes.JOB_PRODUCT_LIST} component={JobProductListPage} />
        <PrivateRoute path={routes.JOB_CREATE_OFFER} component={JobOfferPage} />
        <PrivateRoute path={routes.JOB_UPDATE_OFFER} component={JobOfferPage} />

        <PrivateRoute path={routes.VOUCHER_PRODUCT_DETAILS} component={VoucherProductPage} />
        <PrivateRoute exact path={routes.VOUCHER_PRODUCT_LIST} component={VoucherProductListPage} />
        <PrivateRoute path={routes.SALEORDERS_DETAILS} component={SaleOrdersPage} />
        <PrivateRoute exact path={routes.SALEORDERS_LIST} component={SaleOrdersPage} />

        <Route exact path={routes.RESET_FORM} component={ResetPage} />
        <Route exact path={routes.EMAIL_FORM} component={EmailPage} />
        <Route exact path={routes.WELCOME} component={WelcomePage} />

        {/* helpdesk */}
        <Route exact path={routes.HELPDESK_TICKETS} component={TicketsPage} />
        <Route exact path={routes.HELPDESK_TICKET_CREATE} component={TicketCreatePage} />
        <Route exact path={routes.HELPDESK_TICKET_DETAILS} component={TicketDetailPage} />

        <Route component={NotFoundPage} />
      </Switch>
    </ScrollToTop>
  );
};

export default React.memo(AppRouter);
