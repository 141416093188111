import { takeLatest, put, all, call } from 'redux-saga/effects';
import { sendEmail, checkToken, resetPassword } from '../reducers/PasswordReducer';
import api from '../../data/network';

function* sendEmailSaga({ payload }) {
  try {
    yield call([api.password, api.password.sendEmail], payload);
    yield put(sendEmail.success());
  } catch (e) {
    yield put(sendEmail.failure("We couldn't find that email. Please try again."));
  }
}

function* checkTokenSaga({ payload }) {
  try {
    const response = yield call([api.password, api.password.checkToken], payload);
    yield put(checkToken.success(response));
  } catch (e) {
    yield put(checkToken.failure('Wrong or expired link'));
  }
}

function* resetPasswordSaga({ payload }) {
  try {
    const response = yield call([api.password, api.password.resetPassword], payload);
    yield put(resetPassword.success(response));
  } catch (e) {
    yield put(resetPassword.failure(e));
  }
}

export default function* root() {
  yield all([yield takeLatest([sendEmail.TRIGGER], sendEmailSaga)]);
  yield all([yield takeLatest([checkToken.TRIGGER], checkTokenSaga)]);
  yield all([yield takeLatest([resetPassword.TRIGGER], resetPasswordSaga)]);
}
