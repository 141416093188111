import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Field from '../Common/Fields';
import EmailTitle from '../Common/EmailTitle';
import Detail from '../Generic/Detail';

const LeadsDetail = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Detail
      typeName={t('lead')}
      titleComponent={item => <EmailTitle item={item} titleField="contact_name" emailField="email_from" />}
      {...props}
    >
      <Field.Text label={t('Role')} field="role" />
      <Field.PhoneLink label={t('Mobile')} field="mobile" />
      <Field.Text label={t('Company/Account')} field="partner_name" />
      <Field.PhoneLink label={t('Phone Number')} field="phone" />
      <Field.Link label={t('Website')} field="website" />
    </Detail>
  );
};

export default React.memo(LeadsDetail);
