import { useMutation, useQueryClient } from 'react-query';
import api from '../../data/network';

import { QUERY_KEY } from './usePartnerTags';

// Dependent queries
import { QUERY_KEY as STUDENTS_QUERY_KEY } from '../Students/useStudents';
import { QUERY_KEY as PROCTORS_QUERY_KEY } from '../Proctors/useProctors';
import { QUERY_KEY as TEACHERS_QUERY_KEY } from '../Teachers/useTeachers';

const usePartnerTagsMutations = id => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => {
    queryClient.invalidateQueries([QUERY_KEY]);
    queryClient.invalidateQueries([QUERY_KEY, id]);

    // Invalidate all dependent queries
    queryClient.invalidateQueries([STUDENTS_QUERY_KEY]);
    queryClient.invalidateQueries([PROCTORS_QUERY_KEY]);
    queryClient.invalidateQueries([TEACHERS_QUERY_KEY]);
  };

  const createTag = useMutation(payload => api.partnerTags.create(payload), {
    onSuccess: () => {
      invalidateQuery();
    },
  });

  const updateTag = useMutation(payload => api.partnerTags.update(payload.id, payload), {
    onSuccess: data => {
      invalidateQuery();
      queryClient.invalidateQueries([QUERY_KEY, data.id]);
    },
  });

  const deleteTag = useMutation(payload => api.partnerTags.remove(payload.id), {
    onSuccess: () => {
      invalidateQuery();
    },
  });

  return {
    createTag,
    updateTag,
    deleteTag,
  };
};

export default usePartnerTagsMutations;
