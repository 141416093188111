import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Page from '../../components/Generic/Page';

import PartnersTable from '../../components/Portfolio/PartnersTable';

const PartnersListPage = () => {
  const { t } = useTranslation();
  let history = useHistory();

  const labels = {
    title: t('Partners'),
  };

  const handleRowClick = useCallback(
    (id, partner) => {
      history.push('/partners/' + id);
    },
    [history],
  );

  return (
    <Page
      ep={'partners'}
      subtitle={t('Your partners')}
      labels={labels}
      onRowClick={handleRowClick}
      Table={PartnersTable}
    />
  );
};

export default PartnersListPage;
