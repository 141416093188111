import React from 'react';
import { Formik, Form as FormikForm } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Form = ({ init, item, onSubmit, validate, onClose, isLoading, children, submitLabel }) => {
  const classes = useStyles();
  return (
    <Formik initialValues={init(item)} onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit, handleChange, errors, touched, values }) => (
        <FormikForm>
          <Grid container spacing={3}>
            {React.Children.map(children, (child, index) => (
              <Grid item key={index} xs={child.props.xs} sm={child.props.sm}>
                {React.cloneElement(child, {
                  name: child.props.field,
                  label: child.props.label,
                  error: touched[child.props.field] && errors[child.props.field],
                  onChange: handleChange(child.props.field),
                  value: values[child.props.field],
                })}
              </Grid>
            ))}
          </Grid>
          <DialogActions>
            <Button onClick={onClose} color="default">
              {'Cancel'}
            </Button>
            <div className={classes.wrapper}>
              <Button onClick={handleSubmit} color="secondary" disabled={isLoading}>
                {submitLabel ? submitLabel : item ? 'Update' : 'Create'}
              </Button>
              {isLoading && <CircularProgress size={24} color="secondary" className={classes.buttonProgress} />}
            </div>
          </DialogActions>
        </FormikForm>
      )}
    </Formik>
  );
};

export default React.memo(Form);
