import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  rowHover: {
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const DataTable = ({ rows, columns, onRowClick, noPagination, selectionActions }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(Object.keys(columns)[0]);

  const isSorted = field => orderBy === field;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const [selected, setSelected] = React.useState([]);

  return (
    <React.Fragment>
      <TableContainer>
        <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
          {!!selected.length && !!selectionActions && (
            <caption>
              <span style={{ marginRight: 10 }}>
                {t('Selected')} {selected.length} {t('items')}
              </span>
              {selectionActions.map(({ icon, label, onClick }) => (
                <Button
                  key={label}
                  variant="contained"
                  color="primary"
                  startIcon={icon}
                  onClick={() => onClick(selected)}
                  style={{ marginRight: 10 }}
                >
                  {label}
                </Button>
              ))}
            </caption>
          )}
          <TableHead>
            <TableRow>
              {!!selectionActions && (
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selected.length > 0 && selected.length < rows.length}
                    checked={selected.length === rows.length}
                    onChange={event => {
                      event.stopPropagation();
                      setSelected(selected.length === rows.length ? [] : rows.map(row => row.partner_id));
                    }}
                    inputProps={{ 'aria-label': 'select all' }}
                  />
                </TableCell>
              )}

              {Object.entries(columns).map(([field, { label, ...opts }]) => (
                <TableCell
                  key={field}
                  align={opts.numeric ? 'right' : 'left'}
                  padding="default"
                  sortDirection={isSorted(field) ? order : false}
                >
                  <TableSortLabel
                    active={isSorted(field)}
                    direction={isSorted(field) ? order : 'asc'}
                    onClick={event => handleRequestSort(event, field)}
                  >
                    {label}
                    {isSorted(field) ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow
                  hover
                  key={rowIndex}
                  onClick={event => onRowClick && onRowClick(row.partner_id, row)}
                  role="checkbox"
                  tabIndex={-1}
                  classes={onRowClick && { hover: classes.rowHover }}
                  selected={selected.includes(row.partner_id)}
                >
                  {!!selectionActions && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selected.includes(row.partner_id)}
                        onClick={event => {
                          event.stopPropagation();
                          setSelected(
                            selected.includes(row.partner_id)
                              ? selected.filter(id => id !== row.partner_id)
                              : [...selected, row.partner_id],
                          );
                        }}
                      />
                    </TableCell>
                  )}
                  {Object.entries(columns).map(([field, { render }], index) => (
                    <TableCell
                      key={field}
                      component={index === 0 ? 'th' : undefined}
                      scope={index === 0 ? 'row' : undefined}
                    >
                      {render ? render(row[field]) : row[field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(event, newPage) => {
          setPage(newPage);
        }}
        onChangeRowsPerPage={event => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </React.Fragment>
  );
};

export default React.memo(DataTable);
