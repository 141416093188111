import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useJobOffersProducts } from '../../../store/hooks/shop';
import { JOB_PRODUCT_LIST } from '../../../navigation/routes';

import Layout from '../../../components/App/Layout';
import ProductsGrid from '../../../components/Shop/ProductsGrid';

const JobProductListPage = () => {
  const { t } = useTranslation();
  let history = useHistory();

  const products = useJobOffersProducts();

  const handleClick = React.useCallback(
    product => {
      history.push(`${JOB_PRODUCT_LIST}/${product.id}`);
    },
    [history],
  );

  return (
    <Layout title={t('Job Offers Products')}>
      <ProductsGrid products={products} loading={false} handleClick={handleClick} />
    </Layout>
  );
};

export default React.memo(JobProductListPage);
