import React from 'react';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

const DateInput = ({ error, ...props }) => {
  return (
    <Box mb={2}>
      <TextField
        variant="outlined"
        fullWidth
        error={!!error}
        helperText={error}
        {...props}
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
};

export default React.memo(DateInput);
