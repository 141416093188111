import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchProctors, createProctor } from '../reducers/ProctorsReducer';
import i18n from '../../locales';
import api from '../../data/network';

function* fetchProctorsSaga({ payload }) {
  try {
    const params = { offset: 0, limit: 9999 };
    const proctors = yield call([api.proctors, api.proctors.search], params);
    yield put(fetchProctors.success(proctors));
  } catch (e) {
    console.error(e);
    yield put(fetchProctors.failure(i18n.t('Unable to fetch proctors')));
  }
}

function* createProctorSaga({ payload }) {
  try {
    const proctor = yield call([api.proctors, api.proctors.create], payload);
    yield put(createProctor.success(proctor));
  } catch (e) {
    console.error(e);
    yield put(createProctor.failure(i18n.t('Unable to create proctor')));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchProctors.TRIGGER], fetchProctorsSaga)]);
  yield all([yield takeLatest([createProctor.TRIGGER], createProctorSaga)]);
}
