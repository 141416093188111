import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import TextInput from '../../components/Form/TextInput';

const LoginForm = ({ loading, error, onSubmit }) => {
  const { t } = useTranslation();

  const init = {
    username: '',
    password: '',
  };

  return (
    <Formik initialValues={init} onSubmit={onSubmit}>
      {({ handleSubmit, handleChange }) => (
        <Form>
          <Box display="flex" flexDirection="column" pb={2}>
            <TextInput
              name="username"
              label={t('Username')}
              placeholder={t('LPI ID or Email')}
              onChange={handleChange('username')}
            />

            <TextInput
              name="password"
              type="password"
              label={t('Password')}
              placeholder={t('Your Password')}
              onChange={handleChange('password')}
            />

            <Button disabled={loading} onClick={handleSubmit} variant="contained" color="primary">
              {t('Login')}
            </Button>

            {error && (
              <Typography color="error" align="center">
                {error}
              </Typography>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default React.memo(LoginForm);
