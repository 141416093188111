import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getStagesList } from '../../store/selectors/stages';

import Select from '@material-ui/core/Select';
import Filters from '../Generic/Filters';

const OpportunitiesFilters = ({ ...rest }) => {
  const stages = useSelector(getStagesList);
  const { t } = useTranslation();

  const options = {
    stage_id: {
      label: t('Stage'),
      value: value => parseInt(value) || '',
      chip: value => stages.find(s => s.id === value).name,
    },
  };

  return (
    <Filters options={options} {...rest}>
      <Select native name="stage_id" onChange={event => event.target.value}>
        <option value="" />
        {stages.map(stage => (
          <option key={stage.id} value={stage.id}>
            {stage.name}
          </option>
        ))}
      </Select>
    </Filters>
  );
};

export default React.memo(OpportunitiesFilters);
