import React from 'react';
import { useFormikContext } from 'formik';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';

const MultiSelectInput = ({ error, options, ...props }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Box mb={2}>
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          multiple
          value={Array.isArray(props.value) ? props.value : []}
          name={props.name}
          options={options}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={params => (
            <TextField
              {...params}
              label={props.label}
              error={props.error}
              fullWidth
              variant="outlined"
              required={props.required}
            />
          )}
          onChange={(event, value) => setFieldValue(props.name, value ? value : [])}
        />
        {error && <Typography color="error">{error}</Typography>}
      </FormControl>
    </Box>
  );
};

export default React.memo(MultiSelectInput);
