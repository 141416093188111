import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const PASSWORD_EMAIL = 'Password.Email';
export const PASSWORD_TOKEN = 'Password.Token';
export const PASSWORD_RESET = 'Password.Reset';

export const sendEmail = createRoutine(PASSWORD_EMAIL);
export const checkToken = createRoutine(PASSWORD_TOKEN);
export const resetPassword = createRoutine(PASSWORD_RESET);

const INITIAL_STATE = {
  user: null,
  services: [],
  validToken: null,
  requests: {
    email: { loading: false, success: false, error: null },
    token: { loading: true, success: false, error: null },
    reset: { loading: false, success: false, error: null },
  },
};

const passwordReducer = handleActions(
  {
    [sendEmail.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.email.success = false;
        draft.requests.email.loading = true;
        draft.requests.email.error = null;
      }),
    [sendEmail.SUCCESS]: state =>
      produce(state, draft => {
        draft.requests.email.success = true;
        draft.requests.email.loading = false;
      }),
    [sendEmail.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.email.loading = false;
        draft.requests.email.success = false;
        draft.requests.email.error = { email: payload };
      }),

    [checkToken.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.token.success = false;
        draft.requests.token.loading = true;
        draft.requests.token.error = null;
      }),
    [checkToken.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.user = payload;
        draft.validToken = true;
        draft.requests.token.success = true;
        draft.requests.token.loading = false;
      }),
    [checkToken.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.validToken = false;
        draft.requests.token.loading = false;
        draft.requests.token.success = false;
        draft.requests.token.error = payload;
      }),

    [resetPassword.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.reset.success = false;
        draft.requests.reset.loading = true;
        draft.requests.reset.error = null;
      }),
    [resetPassword.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.services = payload.services;
        draft.requests.reset.success = true;
        draft.requests.reset.loading = false;
      }),
    [resetPassword.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.reset.success = false;
        draft.requests.reset.loading = false;
        draft.requests.reset.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default passwordReducer;
