import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSnackMessage } from '../../store/selectors/snack';
import { delMessage } from '../../store/reducers/SnackReducer';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MaterialSnackbar from '@material-ui/core/Snackbar';

const Snackbar = ({ ...props }) => {
  const dispatch = useDispatch();
  const message = useSelector(getSnackMessage);
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    setOpen(message !== null);
  }, [dispatch, message]);

  const handleClose = React.useCallback(
    (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      dispatch(delMessage());
      setOpen(false);
    },
    [dispatch],
  );

  return (
    <div>
      <MaterialSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default React.memo(Snackbar);
