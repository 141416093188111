import React from 'react';
import { Box, Select, FormControl, InputLabel, MenuItem, FormHelperText } from '@material-ui/core';

import usePartnerUsers from '../../hooks/Profile/usePartnerUsers';

const PrimaryContactSelect = ({ error, valueID, onChange, label, ...props }) => {
  const { users, status } = usePartnerUsers();

  switch (status) {
    case 'idle':
    case 'loading':
      return <Box>Loading...</Box>;
    case 'error':
      return <Box>Error: {error}</Box>;
    case 'success':
      return (
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel id="select-label" style={{ left: 14, top: -7 }}>
              {label}
            </InputLabel>
            <Select
              value={valueID || ''}
              onChange={event => onChange(event.target.value)}
              labelId="select-label"
              label={label}
              variant="outlined"
              {...props}
            >
              {users.map(user => (
                <MenuItem key={user.partner_id} value={user.partner_id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        </Box>
      );
  }
};

export default React.memo(PrimaryContactSelect);
