import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

const Spinner = ({ label, to }) => {
  return (
    <Grid container justify="center">
      <Grid item>
        <CircularProgress color="secondary" size={30} />
      </Grid>
    </Grid>
  );
};

export default React.memo(Spinner);
