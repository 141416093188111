import { takeLatest, put, all, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { convertLead } from '../reducers/LeadsReducer';
import i18n from '../../locales';
import api from '../../data/network';
import { OPPORTUNITIES_LIST } from '../../navigation/routes';

function* convertLeadSaga({ payload }) {
  try {
    const response = yield call([api, api.convertLead], [payload.id]);
    const opportunity = response.opportunity[0];

    yield put(convertLead.success(opportunity));
    yield put(push(OPPORTUNITIES_LIST + '/' + opportunity.id));
  } catch (e) {
    console.error(e);
    // manda la action failure
    yield put(convertLead.failure(i18n.t('Unable to convert opportunity')));
  }
}

export default function* root() {
  yield all([yield takeLatest([convertLead.TRIGGER], convertLeadSaga)]);
}
