import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { cartAddItem } from '../../store/reducers/ShopReducer';
// import { getAddItemRequests } from '../../store/selectors/shop';
import useCart from '../../hooks/Shop/useCart';

import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { setMessage } from '../../store/reducers/SnackReducer';

import ProductQuantity from './ProductQuantity';
import ProductVariant from './ProductVariant';
import { openCart } from '../../store/reducers/ShopReducer';
import useCartMutations from '../../hooks/Shop/useCartMutations';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  imageCard: {
    width: '100%',
  },
  image: {
    width: '100%',
    height: '30vh',
    maxHeight: '30vh',
    objectFit: 'cover',
  },
  qtyInput: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: 18,
  },
  qtyButton: {
    backgroundColor: '#ededed',
  },
  table: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
}));

const LabelGridItem = ({ label, children, ...props }) => {
  const classes = useStyles();

  return (
    <Grid item {...props}>
      <Typography className={classes.title} variant="caption" gutterBottom color="textSecondary">
        {label}
      </Typography>
      {children}
    </Grid>
  );
};

const ProductDetail = ({ product }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addItem } = useCartMutations();
  const classes = useStyles();

  const [available, setAvailable] = React.useState(true);
  const [variant, setVariant] = React.useState(false);
  const [quantity, setQuantity] = React.useState(1);
  const [price, setPrice] = React.useState();
  const [currency, setCurrency] = React.useState();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const handleAddToCart = () => {
    addItem
      .mutateAsync({ productId: variant ? variant.id : product.id, quantity })
      .then(() => dispatch(openCart()))
      .catch(() => dispatch(setMessage(t('An error occurred during the request'))));
  };

  React.useEffect(() => {
    if (product || variant) {
      setAvailable((variant ? variant : product).is_available);
    }
  }, [variant, product]);

  return (
    product && (
      <Container>
        <Grid container spacing={matches ? 4 : 0} className={classes.table}>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <Card className={classes.imageCard}>
              <CardMedia className={classes.image} image={product.image} title={product.name} />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6}>
            <Paper elevation={0}>
              <Box p={2}>
                <Grid container spacing={3} p={2}>
                  <LabelGridItem label={t('Product')} xs={12}>
                    <Typography variant="h5">{product.name}</Typography>
                  </LabelGridItem>

                  {product.description && (
                    <LabelGridItem label={t('Description')} xs={12}>
                      <Typography variant="body1" color="textSecondary">
                        {product.description}
                      </Typography>
                    </LabelGridItem>
                  )}

                  {product.variants.length > 0 && (
                    <LabelGridItem label={t('Variant')} xs={12}>
                      <ProductVariant
                        options={product.variants}
                        onChange={value => {
                          setVariant(value);
                          setCurrency(value.currency);
                          setPrice(value ? value.price : null);
                        }}
                        value={variant}
                      />
                    </LabelGridItem>
                  )}

                  <LabelGridItem label={t('Price')} xs={12} sm={6}>
                    <Box py={2} display="flex" justifyContent={matches ? 'flex-start' : 'center'}>
                      <Typography
                        variant="h1"
                        color="textPrimary"
                        style={{ fontSize: '1.5rem', lineHeight: '2.25rem' }}
                      >
                        {price && currency ? `${currency.symbol}${price}` : '-'}
                      </Typography>
                    </Box>
                  </LabelGridItem>

                  <LabelGridItem label={t('Quantity')} xs={12} sm={6}>
                    <ProductQuantity
                      quantity={quantity}
                      setQuantity={setQuantity}
                      py={2}
                      display="flex"
                      justifyContent={matches ? 'flex-start' : 'center'}
                    />
                  </LabelGridItem>

                  <Grid item xs={12} lg={12}>
                    <Box>{t('* LPI Partner Discount Included')}</Box>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Box pt={4} pb={2}>
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={handleAddToCart}
                        startIcon={addItem.status !== 'loading' && <ShoppingCartIcon />}
                        disabled={!available || addItem.status === 'loading'}
                      >
                        {addItem.status === 'loading' ? (
                          <CircularProgress size={30} />
                        ) : available ? (
                          t('ADD TO CART')
                        ) : (
                          t('Unavailable')
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            {product.is_voucher && (
              <Box marginTop={2}>
                {t(
                  '* Vouchers purchased are only for use online with OnVUE or at a Pearson VUE test center in the country or region selected. Vouchers expire twelve months from date of purchase. Exams must be scheduled and taken on or before the expiration date of the vouchers. The specific expiration date will be sent with each voucher number via email.',
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    )
  );
};

export default React.memo(ProductDetail);
