import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCartButton } from '../../store/hooks/shop';
import useCart from '../../hooks/Shop/useCart';

import { withStyles } from '@material-ui/styles';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import Cart from '../../components/Shop/Cart';
import EmptyCart from '../../components/Shop/EmptyCart';
import { Tooltip } from '@material-ui/core';

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -4,
    top: -4,
    border: `2px solid ${theme.palette.primary}`,
    padding: '0',
  },
}))(Badge);

const CartButton = ({ size, inverse, white, ...rest }) => {
  const { t } = useTranslation();
  const { cart } = useCart();
  const { isOpen, openCart, closeCart } = useCartButton();
  const anchor = React.useRef(null);

  return (
    <React.Fragment>
      <Tooltip title={t('Cart')}>
        <IconButton ref={anchor} aria-label={t('Shopping Cart')} onClick={openCart}>
          <StyledBadge badgeContent={cart.lines?.count} color="secondary" data-testid="cart-btn">
            <ShoppingCartIcon />
          </StyledBadge>
        </IconButton>
      </Tooltip>
      <Popover
        open={isOpen}
        anchorEl={anchor.current}
        onClose={closeCart}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper>
          {cart.lines ? (
            cart.lines.count > 0 ? (
              <Cart editable={false} toCheckout toCart />
            ) : (
              <Box p={4}>
                <EmptyCart />
              </Box>
            )
          ) : null}
        </Paper>
      </Popover>
    </React.Fragment>
  );
};

export default React.memo(CartButton);
