import React from 'react';
import Column from '../../components/Generic/Column';
import Table from '../../components/Generic/Table';

import { useTranslation } from 'react-i18next';

const PartnersTable = props => {
  const { t } = useTranslation();
  return (
    <Table {...props}>
      <Column label={t('Name')} field="name" />
      <Column label={t('Partner Type')} field="partner_type" render={v => (v ? v.join(',') : '')} />
      <Column label={t('Trainers')} field="total_trainers" />
      <Column label={t('Students')} field="total_students" />
      <Column label={t('Orders')} field="total_orders" />
      <Column label={t('Contracts')} field="total_contracts" />
    </Table>
  );
};

export default React.memo(PartnersTable);
