import React, { memo } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { useTranslation } from 'react-i18next';
import { green } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';

const CheckoutSuccess = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" marginTop={3}>
      <Box textAlign="center">
        <CheckCircle style={{ color: green[500], fontSize: 40 }} />
        <Typography variant="h6" style={{ color: green[500] }}>
          {t('Your order has been placed!')}
        </Typography>
        <Typography>{t('You will shortly receive your invoice')}</Typography>
        <Box marginTop={2}>
          <Button to="/" component={Link}>
            {t('Back to Partner portal')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(CheckoutSuccess);
