import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import TextInput from '../../components/Form/TextInput';
import LinkInput from '../../components/Form/LinkInput';
import ImageInput from '../../components/Form/ImageInput';

const validate = values => {
  const errors = {};

  return errors;
};

function handleReset() {
  return true;
}

const getInitialValues = user => ({
  image: user?.image || '',
  name: user?.name || '',
  // socials
  facebook: user?.facebook || '',
  instagram: user?.instagram || '',
  linkedin: user?.linkedin || '',
  twitter: user?.twitter || '',
  gitlab: user?.gitlab || '',
  github: user?.github || '',
});

const UserProfileForm = ({ user, loading, onSubmit, readonly }) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = React.useState(getInitialValues(user));

  React.useEffect(() => {
    setInitialValues(getInitialValues(user));
  }, [user]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate} enableReinitialize>
      {({ handleSubmit, handleChange, errors, touched, values }) => (
        <Form>
          <Box display="flex" flexDirection="column">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <ImageInput value={values.image} onChange={handleChange('image')} readonly={readonly} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput
                  name="name"
                  label={t('Name')}
                  error={touched.name && errors.name}
                  onChange={handleChange('name')}
                  value={values.name}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <LinkInput
                  name="facebook"
                  label={t('Facebook')}
                  error={touched.facebook && errors.facebook}
                  onChange={handleChange('facebook')}
                  value={values.facebook}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <LinkInput
                  name="instagram"
                  label={t('Instagram')}
                  error={touched.instagram && errors.instagram}
                  onChange={handleChange('instagram')}
                  value={values.instagram}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <LinkInput
                  name="linkedin"
                  label={t('Linkedin')}
                  error={touched.linkedin && errors.linkedin}
                  onChange={handleChange('linkedin')}
                  value={values.linkedin}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <LinkInput
                  name="twitter"
                  label={t('Twitter')}
                  error={touched.twitter && errors.twitter}
                  onChange={handleChange('twitter')}
                  value={values.twitter}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <LinkInput
                  name="gitlab"
                  label={t('Gitlab')}
                  error={touched.gitlab && errors.gitlab}
                  onChange={handleChange('gitlab')}
                  value={values.gitlab}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <LinkInput
                  name="github"
                  label={t('Github')}
                  error={touched.github && errors.github}
                  onChange={handleChange('github')}
                  value={values.github}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <DialogActions>
            {!readonly && (
              <React.Fragment>
                <Button onClick={handleReset} color="default">
                  {t('Reset')}
                </Button>
                <Button disabled={loading} onClick={handleSubmit} color="secondary">
                  {t('Submit')}
                </Button>
              </React.Fragment>
            )}
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default UserProfileForm;
