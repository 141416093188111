import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../data/network';

import { QUERY_KEY } from './useTeachers';

/** Get a single teacher */
const useTeacher = id => {
  const req = useQuery([QUERY_KEY, id], () => api.teachers.get(id));

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    teacher: req.data || {},
    ...req,
  };
};

export default useTeacher;
