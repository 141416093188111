import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCoupons, fetchCouponPrograms, fetchAssignees } from '../reducers/CouponsReducer';
import { getCoupons, getCouponPrograms, getAssignees } from '../selectors/coupons';

const useCoupons = () => {
  const dispatch = useDispatch();
  const coupons = useSelector(getCoupons);
  const [wrapper, setWrapper] = React.useState({
    all: [],
    new: [],
    old: [],
  });

  React.useEffect(() => {
    dispatch(fetchCoupons());
  }, [dispatch]);

  const filterIsNew = React.useCallback(
    isNew => coupons.filter(item => (isNew ? item.state === 'new' : item.state !== 'new')),
    [coupons],
  );

  React.useEffect(() => {
    setWrapper({
      all: coupons,
      new: filterIsNew(true),
      old: filterIsNew(false),
    });
  }, [coupons, filterIsNew]);

  return wrapper;
};

const useCouponPrograms = () => {
  const dispatch = useDispatch();
  const programs = useSelector(getCouponPrograms);

  React.useEffect(() => {
    dispatch(fetchCouponPrograms());
  }, [dispatch]);

  return { programs };
};

const useCouponAssignees = () => {
  const dispatch = useDispatch();
  const assignees = useSelector(getAssignees);

  React.useEffect(() => {
    dispatch(fetchAssignees());
  }, [dispatch]);

  return { assignees };
};

export { useCoupons, useCouponPrograms, useCouponAssignees };
