import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import Form from '../Generic/Form';
import TextInput from '../Form/TextInput';

const getInitForm = item => {
  return {
    id: item ? item.id : null,
    name: item ? item.name : '',
    partner_name: item ? item.partner_name : '',
    contact_name: item ? item.contact_name : '',
    role: item ? item.role : '',
    email_from: item ? item.email_from : '',
    mobile: item ? item.mobile : '',
    phone: item ? item.phone : '',
    website: item ? item.website : '',
    type: 'lead',
  };
};

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors['name'] = i18n.t('Lead name is required');
  }
  if (!values.partner_name) {
    errors['partner_name'] = i18n.t('Company name is required');
  }
  if (!values.contact_name) {
    errors['contact_name'] = i18n.t('Contact name is required');
  }
  if (!values.email_from) {
    errors['email_from'] = i18n.t('Contact email is required');
  }
  return errors;
};

const LeadsForm = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Form init={getInitForm} validate={validate} {...props}>
      <TextInput label={t('Lead name')} field="name" xs={12} sm={12} required />
      <TextInput label={t('Company name')} field="partner_name" xs={12} sm={12} required />
      <TextInput label={t('Contact name')} field="contact_name" xs={12} sm={6} required />
      <TextInput label={t('Contact role')} field="role" xs={12} sm={6} />
      <TextInput label={t('Email')} field="email_from" xs={12} sm={6} required />
      <TextInput label={t('Mobile')} field="mobile" xs={12} sm={6} />
      <TextInput label={t('Phone number')} field="phone" xs={12} sm={6} />
      <TextInput label={t('Website')} field="website" xs={12} sm={6} />
    </Form>
  );
};

export default React.memo(LeadsForm);
