import _keyBy from 'lodash/keyBy';
import _cloneDeep from 'lodash/cloneDeep';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const TRAINING_CENTER_FETCH = 'TrainingCenter.Fetch';
export const TRAINING_CENTER_CREATE = 'TrainingCenter.Create';
export const TRAINING_CENTER_UPDATE = 'TrainingCenter.Update';
export const TRAINING_CENTER_REMOVE = 'TrainingCenter.Remove';

export const fetchTrainingCenters = createRoutine(TRAINING_CENTER_FETCH);
export const createTrainingCenter = createRoutine(TRAINING_CENTER_CREATE);
export const updateTrainingCenter = createRoutine(TRAINING_CENTER_UPDATE);
export const removeTrainingCenter = createRoutine(TRAINING_CENTER_REMOVE);

const INITIAL_REQUEST_STATE = {
  fetch: { loading: false, error: null },
  create: { loading: false, error: null },
  update: { loading: false, error: null },
  remove: { loading: false, error: null },
};

const INITIAL_STATE = {
  list: {},
  courses: [],
  topics: [],
  languages: [],
  countries: [],
  requests: INITIAL_REQUEST_STATE,
};

// Reducer
const trainingCentersReducer = handleActions(
  {
    // fetch
    [fetchTrainingCenters.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.fetch.loading = true;
        draft.requests.fetch.error = null;
      }),
    [fetchTrainingCenters.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.list = _keyBy(payload.centers, 'id');
        draft.courses = payload.courses;
        draft.topics = payload.topics;
        draft.languages = payload.languages;
        draft.countries = payload.countries;
      }),
    [fetchTrainingCenters.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.fetch.loading = false;
        draft.requests.fetch.error = payload;
      }),

    // create
    [createTrainingCenter.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.create.loading = true;
        draft.requests.create.error = null;
      }),
    [createTrainingCenter.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.create.loading = true;
        draft.requests.create.error = null;
        draft.list[payload.id] = payload;
      }),
    [createTrainingCenter.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.create.loading = true;
        draft.requests.create.error = null;
      }),

    // update
    [updateTrainingCenter.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.update.loading = true;
        draft.requests.update.error = null;
      }),

    [updateTrainingCenter.SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.update.loading = false;
        draft.list[payload.id] = payload;
      }),
    [updateTrainingCenter.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.update.loading = false;
        draft.requests.update.error = payload;
      }),

    // remove
    [removeTrainingCenter.TRIGGER]: state =>
      produce(state, draft => {
        draft.requests.remove.loading = true;
        draft.requests.remove.error = null;
      }),
    [removeTrainingCenter.SUCCESS]: (state, { payload, meta }) =>
      produce(state, draft => {
        draft.requests.remove.loading = false;
        draft.requests.remove.error = null;
        let items = _cloneDeep(draft.list);
        delete items[payload.id];
        draft.list = items;
      }),
    [removeTrainingCenter.FAILURE]: (state, { payload }) =>
      produce(state, draft => {
        draft.requests.remove.loading = false;
        draft.requests.remove.error = payload;
      }),
  },
  INITIAL_STATE,
);

export default trainingCentersReducer;
