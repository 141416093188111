import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Field from '../Common/Fields';
import Detail from '../Generic/Detail';
import EmailTitle from '../Common/EmailTitle';

const AgreementsDetail = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Detail
      typeName={t('agreement')}
      titleComponent={item => <EmailTitle item={item} titleField="agreement_type_id" emailField="code" />}
      readonly
      {...props}
    >
      <Field.Chip label={t('Status')} field="stage_id" color="secondary" />
      <Field.Text label={t('Organization')} field="organization_contact" />
      <Field.Text label={t('Account Executive')} field="account_executive" />
      <Field.Text label={t('Contractor')} field="contractor" />
      <Field.Text label={t('Start')} field="start" />
      <Field.Text label={t('End')} field="end" />
    </Detail>
  );
};

export default React.memo(AgreementsDetail);
