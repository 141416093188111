import React from 'react';
import { useTranslation } from 'react-i18next';

import StatusChip from '../Common/StatusChip';

import Table from '../Generic/Table';
import Column from '../Generic/Column';

const VoucherOrdersTable = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Table {...props}>
      <Column
        label={t('Status')}
        field="state"
        render={value => {
          return value && <StatusChip status={value.toUpperCase()} />;
        }}
      />
      <Column label={t('Name')} field="name" />
      <Column label={t('Order ID')} field="sale_order_id_name" />
      <Column label={t('Purchase Date')} field="date" />
    </Table>
  );
};

export default React.memo(VoucherOrdersTable);
