import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import useTopics from '../../../hooks/useTopics';
import { CircularProgress, Typography } from '@material-ui/core';
import useMaterialConfig from '../../../hooks/Materials/useMaterialConfig';
import Attachment from '../RequestMaterialForm/Attachment';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

const RequestMaterialsForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [topic, setTopic] = useState(null);
  const [image, setImage] = useState(null);
  const [language, setLanguage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [availableTopics, setAvailableTopics] = useState([]);
  const { config, isLoading: isLoadingConfig, error } = useMaterialConfig();
  const { topics, isLoading: isLoadingTopic } = useTopics();

  useEffect(() => {
    setAvailableTopics(topics.filter(t => t.material_languages.length > 0));
  }, [topics]);

  useEffect(() => {
    if (config) {
      if (config.name) setName(config.name);
      if (config.url) setUrl(config.url);
      if (config.image) setImage(dataURLtoFile(transformOdooBinary(config.image), 'filename'));
    }
  }, [config]);

  const handleSubmit = async () => {
    setLoading(true);
    let _img;
    if (image) _img = await toBase64(image);
    onSubmit({ topic: topic?.name, language, name, url, image: _img || null }, { resetForm: handleReset });
  };

  const handleReset = () => {
    setLanguage(null);
    setTopic(null);
    setName(config.name || '');
    setUrl(config.url || '');
    setImage(config.image ? dataURLtoFile(transformOdooBinary(config.image), 'filename') : null);

    setLoading(false);
  };

  if (isLoadingConfig || isLoadingTopic) {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form>
      <Box display="flex" flexDirection="column">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box mb={2}>
              <TextField
                label={t('Name')}
                fullWidth
                required
                variant="outlined"
                value={name}
                onChange={e => setName(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mb={2}>
              <TextField
                label={t('Url')}
                fullWidth
                required
                variant="outlined"
                value={url}
                onChange={e => setUrl(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mb={2}>
              <Autocomplete
                value={topic?.name}
                options={availableTopics}
                getOptionLabel={option => option?.name}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('Exam')}
                    fullWidth
                    variant="outlined"
                    inputProps={{ ...params.inputProps, 'data-testid': 'topic' }}
                    required={true}
                  />
                )}
                onChange={(_e, value) => {
                  setTopic(value);
                  setLanguage(null);
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mb={2}>
              <Autocomplete
                value={language}
                options={topic?.material_languages || []}
                getOptionLabel={option => option}
                disabled={!topic}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('Language')}
                    fullWidth
                    variant="outlined"
                    inputProps={{ ...params.inputProps, 'data-testid': 'language' }}
                    required={true}
                  />
                )}
                onChange={(_e, value) => setLanguage(value)}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Attachment
              value={image}
              onChange={data => {
                setImage(data);
              }}
            />
          </Grid>

          <Grid item container xs={12} spacing={2} justify="flex-end">
            <Grid item>
              <Button onClick={handleReset}>{t('RESET')}</Button>
              <Button color="secondary" onClick={handleSubmit} data-testid="submit">
                {t('SUBMIT')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function transformOdooBinary(data) {
  return 'data:image/png;base64,' + data;
}

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // bypass odoo binary check
      let res = reader.result.replace(/^data:image\/[a-z]+;base64,/, '');
      resolve(res);
    };
    reader.onerror = error => reject(error);
  });

RequestMaterialsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default React.memo(RequestMaterialsForm);
