import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import {
  Button,
  Dialog,
  DialogTitle,
  Chip,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { useFilters } from '../../store/hooks/filters';
import { searchText } from '../../components/Common/Filters/TextFilter';
import * as Field from '../../components/Common/Fields';
import EmailTitle from '../../components/Common/EmailTitle';
import DataTable from '../../components/Common/DataTable/Page';
import TextFilter from '../../components/Common/Filters/TextFilter';
// import StatusFilter from '../../components/Locations/Filters/StatusFilter';
// import AddStudentAction from '../../components/Locations/AddStudentAction';
// import InvitationStatusChip from '../../components/Locations/InvitationStatusChip';
import useLocations from '../../hooks/Locations/useLocations';
import useLocationsMutations from '../../hooks/Locations/useLocationsMutations';
import { getTranslatedLocationType, getTranslatedLocationStatus } from '../../utils/getTranslatedEnum';
import { setMessage } from '../../store/reducers/SnackReducer';
import PartnerTagsAutocomplete from '../../components/Common/Filters/PartnerTagsAutocomplete';
import EditLocationForm from '../../components/Locations/EditLocationForm';
import { useHistory } from 'react-router-dom';

const LocationsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [dialog, setDialog] = React.useState({ open: false, title: null, content: null });
  const { locations } = useLocations();
  const { draftLocation, publishLocation, deleteLocation } = useLocationsMutations();

  const filters = useFilters({
    // name: {
    //   chip: value => `${t('Name')}: ${value}`,
    //   component: (currentFilters, setFilter) => (
    //     <TextFilter label={t('Name')} field="name" currentFilters={currentFilters} setFilter={setFilter} />
    //   ),
    // },
    // email: {
    //   chip: value => `${t('Email')}: ${value}`,
    //   component: (currentFilters, setFilter) => (
    //     <TextFilter label={t('Email')} field="email" currentFilters={currentFilters} setFilter={setFilter} />
    //   ),
    // },
    // lpi_id: {
    //   chip: value => `${t('LPI ID')}: ${value}`,
    //   component: (currentFilters, setFilter) => (
    //     <TextFilter label={t('LPI ID')} field="lpi_id" currentFilters={currentFilters} setFilter={setFilter} />
    //   ),
    // },
    // tags: {
    //   chip: values => `${t('Tags')}: ${values.map(value => value.name).join(', ')}`,
    //   component: (currentFilters, setFilter) => (
    //     <PartnerTagsAutocomplete
    //       value={currentFilters['tags'] || []}
    //       onChange={(_, newValue) => setFilter('tags', newValue?.length > 0 ? newValue : '')}
    //     />
    //   ),
    // },
    // status: {
    //   chip: value => `${t('Status')}: ${value}`,
    //   component: (currentFilters, setFilter) => <StatusFilter currentFilters={currentFilters} setFilter={setFilter} />,
    // },
  });

  const executeFilters = items => {
    let filtered = [...items];
    // if ('name' in filters.current) {
    //   filtered = filtered.filter(item => searchText(item.partner_name, filters.current.name));
    // }
    // if ('email' in filters.current) {
    //   filtered = filtered.filter(item => searchText(item.partner_email, filters.current.email));
    // }
    // if ('lpi_id' in filters.current) {
    //   filtered = filtered.filter(item => searchText(item.lpi_id, filters.current.lpi_id));
    // }
    // if ('status' in filters.current) {
    //   filtered = filtered.filter(item => item.state === filters.current.status);
    // }
    // if ('tags' in filters.current) {
    //   filtered = filtered.filter(item => {
    //     const tag_ids = item.tags.map(tag => tag.id);
    //     return filters.current.tags.every(tag => tag_ids.includes(tag.id));
    //   });
    // }
    return filtered;
  };

  const getDetailHeaderProps = item => ({
    title: t('Location'),
    subheader: item.name,
  });

  const getDetailContent = item => (
    <React.Fragment>
      {!!item.description && <Field.Paragraph label={t('Description')} value={item.description} />}

      {!!item.type && <Field.Text label={t('Location type')} value={getTranslatedLocationType(item.type)} />}
      {!!item.status && (
        <Field.Text label={t('Verification status')} value={getTranslatedLocationStatus(item.status)} />
      )}
      <Field.Text label={t('Visible in the Partner Find portal')} value={item.visible ? 'Yes' : 'No'} />

      {!!item.country && <Field.Text label={t('Country')} value={item.country.name} />}
      {!!item.state && <Field.Text label={t('State')} value={item.state.name} />}
      {!!item.city && <Field.Text label={t('City')} value={item.city} />}
      {!!item.address && <Field.Text label={t('Address')} value={item.address} />}
      {!!item.zip && <Field.Text label={t('Zip code')} value={item.zip} />}

      {!!item.website && <Field.Text label={t('Website')} value={item.website} />}

      {!!item.languages?.length && (
        <Field.Chip label={t('Languages')} value={item.languages.map(lang => lang.name)} many color="default" />
      )}

      {item.type.includes('training') && (
        <>
          {!!item.courses?.length && (
            <Field.Chip
              label={t('Course types')}
              value={item.courses.map(course => course.name)}
              many
              color="default"
            />
          )}

          {!!item.topics?.length && (
            <Field.Chip label={t('Topics')} value={item.topics.map(topic => topic.name)} many color="default" />
          )}
        </>
      )}

      {item.type.includes('test') && (
        <>
          <Field.Text
            label={t('Site Admin')}
            value={item.site_admin ? item.site_admin.partner_name : t('Not yet defined')}
          />
        </>
      )}
    </React.Fragment>
  );

  const getDetailActions = item => (
    <>
      {item.status !== 'draft' && (
        <Button
          variant="outlined"
          onClick={() =>
            setDialog({
              open: true,
              title: t('Back to draft'),
              content: (
                <>
                  <DialogContent>{t('Are you sure you want to revoke this location and back to draft?')}</DialogContent>
                  <DialogActions>
                    <Button onClick={() => setDialog({ ...dialog, open: false })}>{t('Cancel')}</Button>
                    <Button
                      onClick={() =>
                        draftLocation
                          .mutateAsync(item.id)
                          .then(() => dispatch(setMessage(t('Location is now in draft'))))
                          .catch(() => dispatch(setMessage(t('An error occurred during the request'))))
                          .finally(() => setDialog({ ...dialog, open: false }))
                      }
                      color="secondary"
                      disabled={draftLocation.status === 'loading'}
                    >
                      {draftLocation.status === 'loading' ? <CircularProgress size={20} /> : t('Confirm')}
                    </Button>
                  </DialogActions>
                </>
              ),
            })
          }
        >
          {t('Back to draft')}
        </Button>
      )}

      {item.status === 'draft' && (
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            setDialog({
              open: true,
              title: t('Publish'),
              content: (
                <>
                  <DialogContent>{t('Are you sure you want to publish this location?')}</DialogContent>
                  <DialogActions>
                    <Button onClick={() => setDialog({ ...dialog, open: false })}>{t('Cancel')}</Button>
                    <Button
                      onClick={() =>
                        publishLocation
                          .mutateAsync(item.id)
                          .then(() => dispatch(setMessage(t('Location has been submitted for approval'))))
                          .catch(() => dispatch(setMessage(t('An error occurred during the request'))))
                          .finally(() => setDialog({ ...dialog, open: false }))
                      }
                      color="secondary"
                      disabled={publishLocation.status === 'loading'}
                    >
                      {publishLocation.status === 'loading' ? <CircularProgress size={20} /> : t('Confirm')}
                    </Button>
                  </DialogActions>
                </>
              ),
            })
          }
        >
          {t('Publish')}
        </Button>
      )}

      <Button onClick={() => history.push(`/location/${item.id}`)}>{t('Edit')}</Button>

      <Button
        onClick={() =>
          setDialog({
            open: true,
            title: t('Delete location'),
            content: (
              <>
                <DialogContent>{t('Are you sure you want to delete this location?')}</DialogContent>
                <DialogActions>
                  <Button onClick={() => setDialog({ ...dialog, open: false })}>{t('Cancel')}</Button>
                  <Button
                    onClick={() =>
                      deleteLocation
                        .mutateAsync(item.id)
                        .then(() => dispatch(setMessage(t('Location deleted successfully'))))
                        .catch(() => dispatch(setMessage(t('An error occurred during the request'))))
                        .finally(() => setDialog({ ...dialog, open: false }))
                    }
                    color="secondary"
                    disabled={deleteLocation.status === 'loading'}
                  >
                    {deleteLocation.status === 'loading' ? <CircularProgress size={20} /> : t('Confirm')}
                  </Button>
                </DialogActions>
              </>
            ),
          })
        }
      >
        {t('Delete')}
      </Button>
    </>
  );

  const getActions = () => (
    <Button variant="contained" color="primary" onClick={() => history.push('/location/')}>
      {t('Add location')}
    </Button>
  );

  return (
    <>
      <DataTable
        page={{ title: t('Locations'), actions: getActions, subtitle: t('Training and test centers of the company') }}
        items={locations}
        columns={{
          name: { label: t('Name') },
          country: {
            label: t('Country'),
            render: value => value?.name,
          },
          type: {
            label: t('Location type'),
            render: getTranslatedLocationType,
          },
          status: {
            label: t('Status'),
            render: getTranslatedLocationStatus,
          },
          visible: {
            label: t('Visible'),
            render: value => (value ? t('Yes') : t('No')),
          },
        }}
        filters={{ ...filters, execute: executeFilters }}
        detail={{
          headerProps: getDetailHeaderProps,
          content: getDetailContent,
          actions: getDetailActions,
        }}
        detailLink={item => `/locations/${item.id}`}
      />

      <Dialog open={dialog.open} onClose={() => setDialog({ ...dialog, open: false })}>
        <DialogTitle>{dialog.title}</DialogTitle>
        {dialog.content}
      </Dialog>
    </>
  );
};

export default React.memo(LocationsPage);
