import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPartners } from '../reducers/PartnersReducer';
import { getPartnersList } from '../selectors/partners';

const usePartners = () => {
  const dispatch = useDispatch();
  const partners = useSelector(getPartnersList);

  React.useEffect(() => {
    dispatch(fetchPartners());
  }, [dispatch]);

  return { partners };
};

export { usePartners };
