import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
  title: {
    textTransform: 'uppercase',
  },
  subheader: {},
  cardActions: {
    borderTop: '1px solid #dedede',
  },
});

const Detail = ({ item, headerProps, content, actions, onRoute, onCloseClick }) => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const classes = useStyles();

  React.useEffect(() => onRoute(match.params.id), [dispatch, onRoute, match.params.id]);

  return (
    item && (
      <Card variant="outlined">
        <CardHeader
          classes={{
            title: classes.title,
            subheader: classes.subheader,
          }}
          titleTypographyProps={{ variant: 'button', gutterBottom: true }}
          subheaderTypographyProps={{
            variant: 'h5',
            color: 'textPrimary',
          }}
          action={
            <IconButton onClick={onCloseClick}>
              <Close />
            </IconButton>
          }
          {...headerProps(item)}
        />
        {content && <CardContent>{content(item)}</CardContent>}
        {actions && (
          <CardActions classes={{ root: classes.cardActions }} disableSpacing>
            {actions(item)}
          </CardActions>
        )}
      </Card>
    )
  );
};

export default React.memo(Detail);
