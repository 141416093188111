import { useEffect } from 'react';
import { useQuery } from 'react-query';
import api from '../../data/network';

export const QUERY_KEY = 'jobs.offers.all';

const usePartnerOffers = ({ page = 0, pageSize = 10 }) => {
  const req = useQuery([QUERY_KEY, page, pageSize], () => api.jobs.search({ page_size: pageSize, page }));

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    offers: req.data?.results || [],

    ...req,
  };
};

export default usePartnerOffers;
