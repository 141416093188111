import { takeLatest, put, all, call } from 'redux-saga/effects';
import { fetchTags } from '../reducers/TagsReducer';
import api from '../../data/network';

function* fetchTagsSaga({ payload }) {
  try {
    const tags = yield call([api.tags, api.tags.search]);
    yield put(fetchTags.success(tags));
  } catch (e) {
    yield put(fetchTags.failure('Unable to get partner agreements'));
  }
}

export default function* root() {
  yield all([yield takeLatest([fetchTags.TRIGGER], fetchTagsSaga)]);
}
