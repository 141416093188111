import { useMutation, useQueryClient } from 'react-query';
import api from '../../data/network';

import { QUERY_KEY } from './useCart';

const useCartMutations = () => {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  /* Define mutation hooks */
  const addItem = useMutation(
    ({ productId, quantity }) => api.cart.addItem({ product_id: productId, item_qty: quantity }),
    {
      onSuccess: invalidateQuery,
    },
  );
  const updateItem = useMutation(
    ({ item, quantity }) => api.cart.updateItem({ item_id: item.id, item_qty: quantity }),
    {
      onSuccess: invalidateQuery,
    },
  );
  const removeItem = useMutation(id => api.cart.removeItem({ item_id: id }), {
    onSuccess: invalidateQuery,
  });

  // TODO #LPIDEV-6560 pass orderId with coupon code
  // WARN coupon will be applied only to default cart
  const applyCoupon = useMutation(({ coupon }) => api.cart.applyCoupon({ coupon_code: coupon }), {
    onSuccess: invalidateQuery,
  });

  return {
    addItem,
    updateItem,
    removeItem,
    applyCoupon,
  };
};

export default useCartMutations;
