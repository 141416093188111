import { all, fork } from 'redux-saga/effects';

import AuthSaga from './sagas/AuthSaga';
import AgreementsSaga from './sagas/AgreementsSaga';
import ContactsSaga from './sagas/ContactsSaga';
import GenericSaga from './sagas/GenericSaga';
import TeachersSaga from './sagas/TeachersSaga';
import SkillsSaga from './sagas/SkillsSaga';
import ProctorsSaga from './sagas/ProctorsSaga';
import StudentsSaga from './sagas/StudentsSaga';
import UsersSaga from './sagas/UsersSaga';
import LeadsSaga from './sagas/LeadsSaga';
import StagesSaga from './sagas/StagesSaga';
import PartnersSaga from './sagas/PartnersSaga';
import JobsSaga from './sagas/JobsSaga';
import TagsSaga from './sagas/TagsSaga';
import VouchersSaga from './sagas/VouchersSaga';
import CouponsSaga from './sagas/CouponsSaga';
import ShopSaga from './sagas/ShopSaga';
import TrainingCentersSaga from './sagas/TrainingCentersSaga';
import PasswordSaga from './sagas/PasswordSaga';
import MaterialSaga from './sagas/MaterialsSaga';

export default function* root() {
  yield all([
    fork(AuthSaga),
    fork(AgreementsSaga),
    fork(ContactsSaga),
    fork(GenericSaga),
    fork(TeachersSaga),
    fork(ProctorsSaga),
    fork(SkillsSaga),
    fork(StudentsSaga),
    fork(UsersSaga),
    fork(LeadsSaga),
    fork(StagesSaga),
    fork(PartnersSaga),
    fork(JobsSaga),
    fork(TagsSaga),
    fork(VouchersSaga),
    fork(CouponsSaga),
    fork(ShopSaga),
    fork(TrainingCentersSaga),
    fork(PasswordSaga),
    fork(MaterialSaga),
  ]);
}
