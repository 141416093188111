import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import { Hidden, Drawer, Box, ListItem, ListItemText } from '@material-ui/core';

import { NavLink } from 'react-router-dom';
import Menu from './Sidebar/Menu';
import Logo from './Logo';

import * as routes from '../../navigation/routes';
import NavButton from './Sidebar/NavButton';
import { useTranslation } from 'react-i18next';

import { HiOutlineSupport } from 'react-icons/hi';

const drawerWidth = 240;

const Container = styled.div`
  margin-top: 30px;
  height: 64px;
  min-height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #263238;
  padding: 0px 8px 0px 13px;
`;

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,

  drawerPaper: {
    width: drawerWidth,
    background: '#37474f',
    color: '#CFD8DC',
  },

  navButtonsContainer: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },

  supportButton: {
    position: 'absolute',
    bottom: 3,
    width: '100%',
  },
}));

const Sidebar = ({ open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <nav className={classes.drawer}>
      <Hidden smUp>
        <Drawer
          open={open}
          onClose={onClose}
          variant="temporary"
          anchor="left"
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Container>
            <Logo white py={1} my={1} />
          </Container>
          <Box className={classes.navButtonsContainer}>
            <Menu />
          </Box>
          <Box className={classes.supportButton}>
            <NavButton
              pt="300px"
              label={t('Support')}
              icon={<HiOutlineSupport fontSize="25" />}
              to={routes.HELPDESK_TICKETS}
            />
          </Box>
        </Drawer>
      </Hidden>

      <Hidden xsDown>
        <Drawer
          open
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          data-testid="desktop-sidebar"
        >
          <Container>
            <Logo white py={1} my={1} />
          </Container>
          <Box className={classes.navButtonsContainer}>
            <Menu />
            <NavButton
              label={t('Support')}
              to={routes.HELPDESK_TICKETS}
              style={{
                paddingLeft: 10,
              }}
            />
          </Box>
        </Drawer>
      </Hidden>
    </nav>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default React.memo(Sidebar);
