import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

export const searchText = (a, b) => a !== undefined && b !== undefined && a.toLowerCase().includes(b.toLowerCase());

const TextFilter = ({ label, field, currentFilters, setFilter }) => (
  <FormControl fullWidth variant="outlined">
    <TextField
      label={label}
      value={currentFilters?.[field] || ''}
      onChange={event => {
        setFilter(field, event.target.value || '');
      }}
      variant="outlined"
    />
  </FormControl>
);

export default React.memo(TextFilter);
