import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/App/Layout';
import { Box, Card, CardContent, Chip, Grid, Snackbar, Typography, makeStyles } from '@material-ui/core';
import ActionButton from '../../components/Common/ActionButton';
import Table from '../../components/Generic/Table';
import { Link as RouteLink } from 'react-router-dom';
import Column from '../../components/Generic/Column';
import useHelpdeskTickets from '../../hooks/Helpdesk/useHelpdeskTickets';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { HELPDESK_TICKET_CREATE } from '../../navigation/routes';

const useStyles = makeStyles(theme => ({
  customCardRoot: {
    padding: 0,
  },
  table: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  tabs: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
}));

const TicketsPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const history = useHistory();
  const { tickets, isLoading } = useHelpdeskTickets();

  return (
    <Layout title={t('LPI Helpdesk')}>
      <Box display="flex" justifyContent="space-between" pl={2} m={2} mt={4} flexDirection="row">
        <Typography variant="subtitle1">{t('Create and manage your support tickets')}</Typography>
        <ActionButton component={RouteLink} to={HELPDESK_TICKET_CREATE}>
          {t('Create ticket')}
        </ActionButton>
      </Box>

      <Grid classes={{ root: classes.table }} container spacing={3} display="flex">
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent classes={{ root: classes.customCardRoot }}>
              <Table
                rows={page === 0 ? tickets.slice(0, 10) : tickets.slice(page * 10, page * 10 + 10)}
                onRowClick={ticketId => history.push('/support/tickets/' + ticketId)}
                tableProps={{
                  rowsPerPage: 10,
                  // rowsPerPageOptions: [],
                  page,
                  onChangePage: (_e, newPage) => {
                    setPage(newPage);
                  },
                  count: tickets.length,
                }}
                loading={isLoading}
              >
                <Column label={t('Title')} field="title" />
                <Column label={t('State')} field="state" /* render={value => STATE_MAP[value]} */ />
                <Column label={t('Support team')} field="support_team" />
                <Column
                  label={t('Submission date')}
                  field="date"
                  render={date =>
                    !!date && new Date(date).toLocaleDateString() + ' ' + new Date(date).toLocaleTimeString()
                  }
                />
                <Column label={t('Messages')} field="messages" render={messages => messages.length} />
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar />
    </Layout>
  );
};

export default TicketsPage;
