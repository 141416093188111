import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import api from '../../data/network';

export const QUERY_KEY = 'lpi.partner.tags';

/**
 * Get all available lpi partner tags
 */
const usePartnerTags = () => {
  const req = useQuery([QUERY_KEY], () => api.partnerTags.search());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);

  return {
    tags: req.data?.results || [],
    ...req,
  };
};

export default usePartnerTags;
