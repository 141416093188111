import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import useTrainingTopics from '../../../hooks/Locations/useTrainingTopics';
import { Box } from '@material-ui/core';

const SelectTrainingTopics = ({ name, label, values, onChange, ...props }) => {
  const { topics, status } = useTrainingTopics();

  return (
    <Box mb={2} {...props}>
      <Autocomplete
        multiple
        name={name}
        value={values || []}
        options={topics}
        renderTags={(values, getTagProps) =>
          values.map((option, index) => (
            <Chip key={index} variant="outlined" label={option.name} {...getTagProps({ index })} />
          ))
        }
        loading={status !== 'success'}
        getOptionLabel={option => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={params => <TextField {...params} label={label} fullWidth variant="outlined" />}
        onChange={(_e, values) => onChange(values)}
      />
    </Box>
  );
};

export default React.memo(SelectTrainingTopics);
