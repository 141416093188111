import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';

import StateInput from '../Form/StateInput';
import CountryInput from '../Form/CountryInput';
import LinkInput from '../../components/Form/LinkInput';
import TextareaInput from '../../components/Form/TextareaInput';
import TextInput from '../../components/Form/TextInput';

const PublicProfileForm = ({ data, loading, onSubmit, readonly }) => {
  const { t } = useTranslation();

  const init = {
    bio: data?.bio || '',
    public_name: data?.public_name || '',
    slug_name: data?.slug_name || '',
    public_phone: data?.public_phone || '',
    public_email: data?.public_email || '',
    website: data?.website || '',
    // Public address fields
    public_street: data?.public_street || '',
    public_zip: data?.public_zip || '',
    public_city: data?.public_city || '',
    public_state_id: data?.public_state_id || false,
    public_country_id: data?.public_country_id || false,
    // Social links
    facebook: data?.facebook || '',
    instagram: data?.instagram || '',
    linkedin: data?.linkedin || '',
    twitter: data?.twitter || '',
    gitlab: data?.gitlab || '',
    github: data?.github || '',
  };

  return (
    <Formik initialValues={init} onSubmit={onSubmit} validate={values => ({})}>
      {({ handleSubmit, handleChange, setFieldValue, errors, touched, values }) => (
        <Form>
          <Box display="flex" flexDirection="column">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextInput
                  name="public_name"
                  label={t('Commercial Name')}
                  error={touched.public_name && errors.public_name}
                  onChange={handleChange('public_name')}
                  value={values.public_name}
                  disabled={readonly}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput
                  name="slug_name"
                  label={t('Public Partner portal slug')}
                  error={touched.slug_name && errors.slug_name}
                  onChange={handleChange('slug_name')}
                  value={values.slug_name}
                  disabled={readonly}
                  InputProps={{
                    readOnly: readonly,
                    startAdornment: <InputAdornment position="start">https://find.lpi.org/partner/</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextareaInput
                  name="bio"
                  label={t('Bio')}
                  error={touched.bio && errors.bio}
                  onChange={handleChange('bio')}
                  value={values.bio}
                  disabled={readonly}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInput
                  name="public_phone"
                  label={t('Phone number')}
                  error={touched.public_phone && errors.public_phone}
                  onChange={handleChange('public_phone')}
                  value={values.public_phone}
                  disabled={readonly}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInput
                  name="public_email"
                  label={t('Email address')}
                  error={touched.public_email && errors.public_email}
                  onChange={handleChange('public_email')}
                  value={values.public_email}
                  disabled={readonly}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInput
                  name="website"
                  label={t('Website')}
                  error={touched.website && errors.website}
                  onChange={handleChange('website')}
                  value={values.website}
                  disabled={readonly}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h2">{t('Public Address')}</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextInput
                  name="public_street"
                  label={t('Address')}
                  error={touched.public_street && errors.public_street}
                  onChange={handleChange('public_street')}
                  value={values.public_street}
                  disabled={readonly}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInput
                  name="public_zip"
                  label={t('Postal code')}
                  error={touched.public_zip && errors.public_zip}
                  onChange={handleChange('public_zip')}
                  value={values.public_zip}
                  disabled={readonly}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CountryInput
                  name="public_country_id"
                  label={t('Country')}
                  error={touched.public_country_id && errors.public_country_id}
                  onChange={(_e, value) => setFieldValue('public_country_id', value?.id || null)}
                  valueID={values.public_country_id}
                  disabled={readonly}
                  inputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <StateInput
                  name="public_state_id"
                  label={t('State')}
                  error={touched.public_state_id && errors.public_state_id}
                  onChange={(_e, value) => setFieldValue('public_state_id', value?.id || null)}
                  valueID={values.public_state_id}
                  disabled={readonly}
                  inputProps={{
                    readOnly: readonly,
                  }}
                  countryField="public_country_id"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInput
                  name="public_city"
                  label={t('City')}
                  error={touched.public_city && errors.public_city}
                  onChange={handleChange('public_city')}
                  value={values.public_city}
                  disabled={readonly}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h2">{t('Social Links')}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <LinkInput
                  name="facebook"
                  label={t('Facebook')}
                  error={touched.facebook && errors.facebook}
                  onChange={handleChange('facebook')}
                  value={values.facebook}
                  disabled={readonly}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <LinkInput
                  name="instagram"
                  label={t('Instagram')}
                  error={touched.instagram && errors.instagram}
                  onChange={handleChange('instagram')}
                  value={values.instagram}
                  disabled={readonly}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <LinkInput
                  name="linkedin"
                  label={t('Linkedin')}
                  error={touched.linkedin && errors.linkedin}
                  onChange={handleChange('linkedin')}
                  value={values.linkedin}
                  disabled={readonly}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <LinkInput
                  name="twitter"
                  label={t('Twitter')}
                  error={touched.twitter && errors.twitter}
                  onChange={handleChange('twitter')}
                  value={values.twitter}
                  disabled={readonly}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <LinkInput
                  name="gitlab"
                  label={t('Gitlab')}
                  error={touched.gitlab && errors.gitlab}
                  onChange={handleChange('gitlab')}
                  value={values.gitlab}
                  disabled={readonly}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <LinkInput
                  name="github"
                  label={t('Github')}
                  error={touched.github && errors.github}
                  onChange={handleChange('github')}
                  value={values.github}
                  disabled={readonly}
                  InputProps={{
                    readOnly: readonly,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <DialogActions>
            {!readonly && (
              <Button disabled={loading} onClick={handleSubmit} color="secondary">
                {t('Submit')}
              </Button>
            )}
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(PublicProfileForm);
