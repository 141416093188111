import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import api from '../data/network';

export const QUERY_KEY = 'location.all';

/**
 * Get all available locations
 */
const usePlaces = () => {
  const req = useQuery([QUERY_KEY], () => api.places.search());

  /* Log errors */
  useEffect(() => {
    if (req.error) {
      console.error(req.error);
    }
  }, [req.error]);
  
  const states = useMemo(() => {
    if (req.data && Array.isArray(req.data)) {
      return (
        req.data.reduce((acc, place) => {
          if (!!place.states?.length) {
            acc = acc.concat(place.states);
          }
          return acc;
        }, []) || []
      );
    }
  }, [req.data]);

  return {
    places: req.data || [],
    states: states || [],
    ...req,
  };
};

export default usePlaces;
