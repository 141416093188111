import _keyBy from 'lodash/keyBy';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { useCoupons } from '../../store/hooks/coupons';
import { useFilters } from '../../components/Generic/UseFilters';
import { createCoupon } from '../../store/reducers/CouponsReducer';
import { isCouponCreationLoading } from '../../store/selectors/coupons';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import ActionButton from '../../components/Common/ActionButton';
import Layout from '../../components/App/Layout';
import CouponsTable from '../../components/Coupons/CouponsTable';
import CouponsDetail from '../../components/Coupons/CouponsDetail';
import CouponsForm from '../../components/Coupons/CouponsForm';
import CouponsFilters from '../../components/Coupons/CouponsFilters';
import CouponsActions from '../../components/Coupons/CouponsActions';
import Snackbar from '../../components/Common/Snackbar';

const TABS = { NEW: 0, USED: 1 };

const useStyles = makeStyles(theme => ({
  customCardRoot: {
    padding: 0,
  },
  tabs: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  table: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
}));

const CouponsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const { path } = useRouteMatch();
  let history = useHistory();

  const detail_route_match = useRouteMatch({
    path: path + '/:id/',
  });

  const [activeTab, setActiveTab] = React.useState(0);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [waitingCreate, setWaitingCreate] = React.useState(false);
  const [id, setId] = React.useState();
  const isCreationLoading = useSelector(isCouponCreationLoading);

  const coupons = useCoupons();
  const filters = useFilters();

  const [shownItems, setShownItems] = React.useState([]);

  const item = _keyBy(coupons.all, 'id')[id];

  const handleRowClick = React.useCallback(
    (id, row) => {
      history.push(path + '/' + id);
    },
    [history, path],
  );

  const handleCloseDetail = React.useCallback(() => {
    setId(null);
    history.push(path);
  }, [history, path]);

  React.useEffect(() => {
    let shown = activeTab === TABS.NEW ? coupons.new : coupons.old;
    if ('program_id' in filters.current) {
      shown = shown.filter(item => item.program_id === parseInt(filters.current.program_id));
    }
    if ('partner_id' in filters.current) {
      shown = shown.filter(item => item.partner_id === parseInt(filters.current.partner_id));
    }
    if (filters.current.showAssigned && !filters.current.showUnassigned) {
      shown = shown.filter(item => item.partner_id !== null);
    }
    if (filters.current.showUnassigned && !filters.current.showAssigned) {
      shown = shown.filter(item => item.partner_id === null);
    }
    setShownItems(shown);
  }, [activeTab, coupons, filters]);

  const handleSubmit = React.useCallback(
    values => {
      dispatch(createCoupon(values));
      setWaitingCreate(true);
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (waitingCreate && !isCreationLoading) {
      setWaitingCreate(false);
      setModalOpen(false);
    }
  }, [isCreationLoading, waitingCreate]);

  return (
    <React.Fragment>
      <Layout title={t('Coupons')} back={!!detail_route_match} backPath={path}>
        <Tabs
          className={classes.tabs}
          centered
          value={activeTab}
          onChange={(event, newTabIndex) => {
            filters.clear();
            setActiveTab(newTabIndex);
          }}
        >
          <Tab label={t('New')} index={TABS.NEW} />
          <Tab label={t('Used')} index={TABS.USED} />
        </Tabs>

        <Box display="flex" justifyContent="space-between" pl={2} m={2} flexDirection="row">
          <Typography variant="subtitle1">{t('Generate and Assign Discount Coupons to your customers')}</Typography>
          <ActionButton onClick={() => setModalOpen(true)}>{t('Generate coupon')}</ActionButton>
        </Box>

        <CouponsFilters controller={filters} />

        <Grid container spacing={3} className={classes.table}>
          <Hidden mdDown={!!detail_route_match}>
            <Grid item xs={12} lg>
              <Card variant="outlined">
                <CardContent classes={{ root: classes.customCardRoot }}>
                  <CouponsTable rows={shownItems} onRowClick={handleRowClick} />
                </CardContent>
              </Card>
            </Grid>
          </Hidden>
          <Switch>
            <Route path={path + '/:id'}>
              <Grid item xs={12} lg={4}>
                <CouponsDetail item={item} onRoute={setId} Actions={CouponsActions} onCloseClick={handleCloseDetail} />
              </Grid>
            </Route>
          </Switch>
        </Grid>
      </Layout>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>{t('Generate coupon')}</DialogTitle>
        <DialogContent>
          <CouponsForm onSubmit={handleSubmit} onClose={() => setModalOpen(false)} />
        </DialogContent>
      </Dialog>
      <Snackbar />
    </React.Fragment>
  );
};

export default React.memo(CouponsPage);
