import _values from 'lodash/values';
import { createSelector } from 'reselect';

export const getStagesList = createSelector(
  state => state.stages.list,
  stages => {
    let s = _values(stages);
    s.sort((a, b) => a.sequence - b.sequence);
    return s;
  },
);

export const getStageDetail = createSelector(
  state => state.stages.detail,
  stage => stage,
);
