import { useMutation, useQueryClient } from 'react-query';
import api from '../../data/network';

export const QUERY_KEY = 'vouchers.coupons';

export default function useCouponMutations() {
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries([QUERY_KEY]);

  const create = useMutation(api.coupons.create, {
    onSuccess: invalidateQuery,
  });
  const assign = useMutation(api.coupons.assign, {
    onSuccess: invalidateQuery,
  });

  return {
    create,
    assign,
  };
}
