import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Anchor from '@material-ui/core/Link';

const useStyles = makeStyles({
  anchorRoot: {
    fontSize: '1rem',
  },
});

const EmailTitle = ({ item, titleField, emailField, ...props }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h5" color="textPrimary">
        {item[titleField]}
      </Typography>
      <Anchor
        color="secondary"
        classes={{
          root: classes.anchorRoot,
        }}
        href={'mailto:' + item[emailField]}
      >
        {item[emailField]}
      </Anchor>
    </Box>
  );
};

export default React.memo(EmailTitle);
