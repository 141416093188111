import React from 'react';
import { useTranslation } from 'react-i18next';

import CommonChip from '../../Common/CommonChip';

const InvoiceStatusChip = ({ status, mapping }) => {
  const { t } = useTranslation();
  const COLORS = {
    upselled: { backgroundColor: '#0288D1' },
    invoiced: { backgroundColor: '#388E3C' },
    'to invoice': { backgroundColor: '#F57C00' },
    no: { backgroundColor: '#0288D1' },
  };
  return <CommonChip squared label={t(status)} size="small" mapping={COLORS} />;
};

export default React.memo(InvoiceStatusChip);
