import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';

import Table from '../Generic/Table';
import Column from '../Generic/Column';

import dayjs from 'dayjs';

function formatDate(dateString) {
  return dayjs(dateString).format('MMM DD ,YYYY');
}

const CandidatesTable = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Table defaultOrderDir="desc" {...props}>
      <Column label={t('Code')} field="code" />
      <Column label={t('Created')} field="create_date" render={value => formatDate(value)} />
      <Column label={t('Name')} field="name" />
      <Column label={t('Job Offer')} field="job_name" />
      <Column
        label={t('Certificates')}
        field="certificates"
        render={values => {
          return (
            values && (
              <Grid container spacing={1}>
                {values.map(function(item) {
                  return (
                    <Grid key={item.id} item>
                      <Chip size="small" label={item.name} />
                    </Grid>
                  );
                })}
              </Grid>
            )
          );
        }}
      />
    </Table>
  );
};

export default React.memo(CandidatesTable);
