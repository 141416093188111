import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import useLanguages from '../../../hooks/useLanguages';

const SelectLanguages = ({ name, label, value, onChange }) => {
  const { languages, status } = useLanguages();

  return (
    <Autocomplete
      multiple
      name={name}
      value={value}
      options={languages}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip key={index} variant="outlined" label={option.name} {...getTagProps({ index })} />
        ))
      }
      loading={status !== 'success'}
      getOptionLabel={option => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      renderInput={params => <TextField {...params} label={label} fullWidth variant="outlined" />}
      onChange={onChange}
    />
  );
};

export default React.memo(SelectLanguages);
