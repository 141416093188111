import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';

import Table from '../Generic/Table';
import Column from '../Generic/Column';

const STATE_MAP = {
  draft: 'Draft',
  review: 'Under Review',
  publish: 'Published',
  refused: 'Refused',
};

const OffersTable = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Table {...props}>
      <Column label={t('State')} field="status" render={value => STATE_MAP[value]} />
      <Column label={t('Title')} field="name" />
      <Column
        label={t('Certifications')}
        field="certificates"
        render={values => {
          return (
            values && (
              <Grid container spacing={1}>
                {values.map(function(item) {
                  return (
                    <Grid key={item.id} item>
                      <Chip size="small" label={item.name} />
                    </Grid>
                  );
                })}
              </Grid>
            )
          );
        }}
      />
      <Column
        label={t('Location')}
        field="location"
        renderDepends={item => [item.city, item.state?.name, item.country?.name].filter(chunk => chunk).join(', ')}
      />
      <Column
        label={t('Expiring Date')}
        field="job_end_date"
        render={date => !!date && new Date(date).toLocaleDateString()}
      />
      {/* <Column label={t('Potential Candidates')} field="potential_candidates" />
      <Column label={t('Contactable Candidates')} field="contactable_candidates" /> */}
    </Table>
  );
};

export default React.memo(OffersTable);
