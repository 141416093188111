import React from 'react';
import { useTranslation } from 'react-i18next';
import { EMAIL_FORM } from '../../navigation/routes';

const ForgotPasswordLink = () => {
  const { t } = useTranslation();
  return <a href={EMAIL_FORM}>{t('Forgot password?')}</a>;
};

export default ForgotPasswordLink;
