import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DialogContent, DialogActions } from '@material-ui/core';

import PartnerTagsAutocomplete from './Filters/PartnerTagsAutocomplete';

const MultiTagDialog = ({ onClose, onConfirm }) => {
  const [tags, setTags] = useState([]);
  const { t } = useTranslation();

  return (
    <DialogContent>
      <PartnerTagsAutocomplete allowDelete={false} value={tags} onChange={(_, tags) => setTags(tags)} />
      <DialogActions>
        <Button onClick={onClose}>{t('Cancel')}</Button>
        <Button onClick={() => onConfirm(tags)}>{t('Confirm')}</Button>
      </DialogActions>
    </DialogContent>
  );
};

export default MultiTagDialog;
