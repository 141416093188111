import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTags } from '../reducers/TagsReducer';
import { getTags } from '../selectors/tags';

const useTags = () => {
  const dispatch = useDispatch();
  const tags = useSelector(getTags);

  React.useEffect(() => {
    dispatch(fetchTags());
  }, [dispatch]);

  return { tags };
};

export { useTags };
