import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import usePlaces from '../../hooks/usePlaces';

import * as Field from '../Common/Fields';
import Detail from '../Generic/Detail';

const OpportunitiesDetail = ({ ...props }) => {
  const { places } = usePlaces();

  const noEdit = useMemo(() => {
    return props?.item?.stage_id === 4; // WON
  }, [props.item]);

  const { t } = useTranslation();
  return (
    <Detail typeName={t('opportunity')} titleField="name" {...props} noEdit={noEdit}>
      <Field.Paragraph label={t('Notes')} field="description" />
      <Field.Text label={t('Company/Account')} field="partner_name" />
      <Field.Text label={t('Expected Close date')} field="date_deadline" />
      <Field.Text label={t('Expected Revenue')} field="planned_revenue" />
      <Field.Text
        label={t('Country')}
        field="country_id"
        render={countryID => places.find(country => country.id == countryID)?.name || 'Loading...'}
      />
      <Field.Text label={t('Stage')} field="stage_name" />
    </Detail>
  );
};

export default React.memo(OpportunitiesDetail);
